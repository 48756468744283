import React from 'react';

/* external */
import { Controller, useFormContext } from 'react-hook-form';

/* Material UI */
import { MenuItem, TextField } from '@mui/material';

/**
 * @typedef {object} SelectControlOption
 * @property {string} name - The value to display in the UI (e.g. John Doe)
 * @property {string} value - The value to use internally (e.g. jdoe)
 */

/**
 * @callback SelectControlOnChangeCB
 * @param (any) value - The value of the selected option
 */

/**
 * Component that shows a select (drop-down) box. For use with react-hook-form forms.
 * Will use control and errors from formContext if available and not otherwise provided.
 *
 * NOTE: MUST BE USED WITH FORMS USING RHF VERSION 7 AND UP!!!!!
 *
 * @component
 * @param {object} props
 * @param {Control} props.control - Control prop from `useForm()`
 * @param {string} props.defaultValue - Default value to select
 * @param {any} props.errors
 * @param {object} [props.inputProps={}] - Extra props to pass to the `input` element
 * @param {object} [props.MenuItemProps={}] - Extra props to pass to the `MenuItem` component
 * @param {string} props.name - Form field name
 * @param {boolean} [props.noNull=false] - Whether or not to show the value of `nullDisplay` when nothing has been selected
 * @param {string} [props.nullDisplay='Unknown'] - Label to use when nothing has been selected
 * @param {SelectControlOption[]} props.options - All selectable options to render
 * @param {string} [props.optionValueKey='value'] - Key in `options` item that will be used to grab the non-UI value
 * @param {string} [props.optionNameKey='name'] - Key in `options` item that will be used to grab the UI value
 * @param {any} props.rules - Rules to be passed to the `rules` prop of the `Controller` component
 * @param {SelectControlOnChangeCB} [props.onChange] - Callback to run on change. Defaults to doing nothing.
 */
const SelectControl = ({
  children,
  control,
  defaultValue,
  errors,
  inputProps = {},
  MenuItemProps = {},
  name,
  noNull = false,
  nullDisplay = 'Unknown',
  options,
  optionValueKey = 'value',
  optionNameKey = 'name',
  rules,
  onChange: origOnChange = () => {},
  ...rest
}) => {
  const formContext = useFormContext();
  const { formState } = formContext ?? {};
  const _errors = errors ?? formState?.errors;

  const { disabled: menuItemDisabled, ...MenuItemPropsRest } = MenuItemProps;

  return (
    <Controller
      control={control ?? formContext.control}
      defaultValue={defaultValue ?? ''}
      name={name}
      render={({
        field: { ref, value, onChange, ...field },
        fieldState: { error },
      }) => (
        <TextField
          error={Boolean(_errors?.[name]) || Boolean(error)}
          helperText={_errors?.[name]?.message || error?.message}
          inputProps={inputProps}
          InputLabelProps={{ shrink: noNull ? undefined : true }}
          select
          // this causes the "input label" area to be an empty space when enabled
          // SelectProps={{ displayEmpty: true }}
          value={value ?? ''}
          onChange={e => {
            const fval =
              e.target.value === '' ? onChange(null) : onChange(e.target.value);
            origOnChange(e.target.value);
            return fval;
          }}
          {...field}
          {...rest}
        >
          {children}
          {!children && !noNull && <MenuItem value="">{nullDisplay}</MenuItem>}
          {!children &&
            options.map(option => (
              <MenuItem
                value={option[optionValueKey]}
                key={option[optionValueKey]}
                disabled={menuItemDisabled ? menuItemDisabled(option) : false}
                {...MenuItemPropsRest}
              >
                {option[optionNameKey]}
              </MenuItem>
            ))}
        </TextField>
      )}
      rules={rules}
    />
  );
};

export default SelectControl;
