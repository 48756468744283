/* external */
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { NetworkStatus } from 'apollo-boost';
import { Waypoint } from 'react-waypoint';

/* Material UI */
import {
  Box,
  Fab,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  Add as AddIcon,
  Clear as ClearIcon,
  FilterList as FilterListIcon,
  ImportExport as SortIcon,
  MoreVert as MoreVertIcon,
  Search as SearchIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import { blue, green } from '@mui/material/colors';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { usePersistedState } from 'utils';
import {
  DealerPicker,
  FacetChips,
  FilterDrawer,
  Loading,
  LoadingBackdrop,
} from 'components/MaterialUI';
import { filtersFromFacets } from 'modules/npv_inventory/utils';
import { ID_SORT } from 'modules/npv_inventory/const';
import BulkActions from './bulk_actions/BulkActions';
import InventoryListMobileView from './InventoryListMobileView';
import InventoryTableCardView from './InventoryTableCardView';
import InventoryTableListView from './InventoryTableListView';
import SortDrawer from 'modules/npv_inventory/components/inventory/SortDrawer';
import InventoryToolbar from 'modules/inventory/components/tables/InventoryToolbar';

const GET_VEHICLES = gql`
  query getVehicles(
    $page: Int
    $pageSize: Int
    $sort: [QuerySortElement]
    $filters: [QueryFilter]
    $facets: [QueryFacet]!
  ) {
    npv {
      getVehicles(
        page: $page
        pageSize: $pageSize
        filters: $filters
        sort: $sort
      ) {
        pagination {
          nextPage
          page
          pageSize
          total
        }
        results {
          id
          cost
          daysInStock
          description
          displayName
          exteriorColourName
          interiorColourName
          isFeatured
          isOnSpecial
          leadsCount
          modifiedAt
          msrp
          odometer
          opportunitiesCount
          photoCount
          photos {
            cloudinaryPublicId
          }
          regularPrice
          specialPrice
          stockNumber
          stockType
          stockStatus {
            name
          }
          trim
          vin
          model {
            name
            make {
              name
              year
              manufacturer {
                name
              }
            }
            npvType {
              name
            }
          }
        }
      }
      getVehiclesFacets(facets: $facets, filters: $filters) {
        model
        field
        data {
          value
          count
        }
      }
    }
  }
`;

const FACET_OPTIONS = [
  {
    field: 'makeName',
    model: 'Vehicle',
    title: 'Make',
    options: { nullLabel: 'Unknown Make' },
  },
  {
    field: 'modelName',
    model: 'Vehicle',
    title: 'Model',
    options: { nullLabel: 'Unknown Model' },
  },
  { field: 'stockType', model: 'Vehicle', title: 'Stock Type' },
  { field: 'stockStatusName', model: 'Vehicle', title: 'Stock Status' },
  {
    field: 'year',
    model: 'Vehicle',
    title: 'Year',
    options: { nullLabel: 'No Year Set' },
  },
  {
    field: 'manufacturerName',
    model: 'Vehicle',
    title: 'Manufacturer',
    options: { nullLabel: 'Unknown Manufacturer' },
  },
  {
    field: 'npvTypeName',
    model: 'Vehicle',
    title: 'Type',
    options: { nullLabel: 'Unknown Type' },
  },
  { field: 'daysInStockCategory', model: 'Vehicle', title: 'Days In Stock' },
  {
    field: 'missingPhotos',
    model: 'Vehicle',
    title: 'Missing Photos',
    options: { checkbox: true, countValue: 'true', label: 'No photos' },
  },
  {
    field: 'missingDescription',
    model: 'Vehicle',
    title: 'Missing Description',
    options: { checkbox: true, countValue: 'true', label: 'No description' },
  },
  {
    field: 'missingPrice',
    model: 'Vehicle',
    title: 'Missing Price',
    options: { checkbox: true, countValue: 'true', label: 'No price' },
  },
  {
    field: 'isOnSpecial',
    model: 'Vehicle',
    title: 'On Special',
    options: { checkbox: true, countValue: 'true', label: 'Special' },
  },
  {
    field: 'noPriceChangesAtSevenDays',
    model: 'Vehicle',
    title: 'No Price Changes at 7 Days',
    options: {
      checkbox: true,
      countValue: 'true',
      label: 'No Price Changes at 7 Days',
    },
  },
  {
    field: 'noPriceChangesAtFourteenDays',
    model: 'Vehicle',
    title: 'No Price Changes at 14 Days',
    options: {
      checkbox: true,
      countValue: 'true',
      label: 'No Price Changes at 14 Days',
    },
  },
  {
    field: 'noPriceChangesAtTwentyOneDays',
    model: 'Vehicle',
    title: 'No Price Changes at 21 Days',
    options: {
      checkbox: true,
      countValue: 'true',
      label: 'No Price Changes at 21 Days',
    },
  },
  {
    field: 'noPriceChangesAtTwentyEightDays',
    model: 'Vehicle',
    title: 'No Price Changes at 28 Days',
    options: {
      checkbox: true,
      countValue: 'true',
      label: 'No Price Changes at 28 Days',
    },
  },
];

function LoadingIndicator({ networkStatus }) {
  if (networkStatus === NetworkStatus.fetchMore) {
    return <Loading text="Loading more vehicles&hellip;" />;
  }

  return <LoadingBackdrop open>Loading&hellip;</LoadingBackdrop>;
}

const Inventory = () => {
  // Default filters
  const DEFAULT_FILTERS = [
    {
      model: 'StockStatus',
      field: 'name',
      op: '==',
      value: 'in stock',
    },
    {
      model: 'Vehicle',
      field: 'stockType',
      op: '==',
      value: 'new',
    },
  ];

  const { dealerId } = useDealerContext();
  const history = useHistory();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const [keywords, setKeywords] = useState('');
  const [searchFilters, setSearchFilters] = useState([]);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [showSortDrawer, setShowSortDrawer] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectByFilter, setSelectByFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Persisted States
  const [alignment, setAlignment] = usePersistedState(
    'npv_inventory.alignment',
    'list',
  );
  const [filterFacets, setFilterFacets] = usePersistedState(
    'npv_inventory.filters',
    DEFAULT_FILTERS,
  );
  const [sortSelection, setSortSelection] = usePersistedState(
    'npv_inventory.sort',
    null, // may need to change this around a bit if we want to support an array in sortSelection (ie. multiple sort options)
  );

  const facets = FACET_OPTIONS.map(({ field, model }) => ({ field, model }));

  const dealerFilter = {
    model: 'Vehicle',
    field: 'dealerId',
    op: '==',
    value: dealerId,
  };

  const filters = [
    ...filtersFromFacets(filterFacets),
    ...searchFilters,
    dealerFilter,
  ];
  const sort = [sortSelection, { ...ID_SORT, direction: 'asc' }].filter(x => x);

  // Vehicle Query
  const { data, fetchMore, loading, networkStatus, refetch } = useQuery(
    GET_VEHICLES,
    {
      variables: {
        page: 1,
        pageSize: 20,
        sort,
        filters,
        facets,
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const vehicles = data?.npv?.getVehicles?.results || [];
  const { nextPage, pageSize, page, total } =
    data?.npv?.getVehicles?.pagination ?? {};

  const fetchMoreHandler = () => {
    if (nextPage)
      fetchMore({
        variables: { page: nextPage },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.npv.getVehicles.results) return prev;

          return Object.assign({}, prev, {
            npv: {
              __typename: prev.npv.__typename,
              getVehicles: {
                __typename: prev.npv.getVehicles.__typename,
                results: [
                  ...prev.npv.getVehicles.results,
                  ...more.npv.getVehicles.results,
                ],
                pagination: more.npv.getVehicles.pagination,
              },
              getVehiclesFacets: more.npv.getVehiclesFacets, // just replace facets
            },
          });
        },
      });
  };

  const handleAlignment = (_, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleDeleteFacet = (_model, _field, _value) =>
    setFilterFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  /*
    year: exact match,
    make: starts with keyword,
    model: starts with keyword,
    manufacturerName: starts with keyword,
    serialNumber: contains keyword
    stockNumber: contains keyword
  */
  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      keywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Vehicle',
            field: 'makeName',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'modelName',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'serialNumber',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'stockNumber',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'manufacturerName',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
        ],
      })),
    );

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const handleSelectAllClick = e => {
    setSelected(e.target.checked ? vehicles.map(n => n.id) : []);
    setSelectByFilter(false);
  };

  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  if (loading && networkStatus !== NetworkStatus.fetchMore)
    return <LoadingBackdrop open={loading} />;

  return (
    <Box margin={1}>
      <Box component="div" marginBottom={4}>
        <DealerPicker />
      </Box>
      <Box
        component="div"
        marginBottom={2}
        style={
          !mobile
            ? {
                zIndex: '5',
                position: 'sticky',
                top: '64px',
                paddingTop: '1em',
              }
            : {}
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <TextField
              label="Search Inventory"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!keywords}
                      onClick={() => {
                        setKeywords('');
                        setSearchFilters([]);
                      }}
                      size="large"
                    >
                      <ClearIcon color="disabled" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={keywords}
              onChange={e => setKeywords(e.target.value)}
              onKeyDown={handleSearch}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display="flex">
              <Hidden lgDown>
                <Tooltip title="Bulk Actions">
                  <Fab
                    color="primary"
                    onClick={handleAnchorClick}
                    style={{ marginRight: '10px' }}
                  >
                    <MoreVertIcon fontSize="large" />
                  </Fab>
                </Tooltip>
                <BulkActions
                  anchorEl={anchorEl}
                  filters={filters}
                  handleAnchorClose={handleAnchorClose}
                  refetch={refetch}
                  selectByFilter={selectByFilter}
                  selected={selected}
                  total={total}
                  vehicles={vehicles}
                />
              </Hidden>
              <Hidden mdDown>
                <Tooltip title="Filter Inventory">
                  <Fab
                    color="primary"
                    onClick={() => setShowFilterDrawer(true)}
                    style={{ marginRight: '10px' }}
                  >
                    <FilterListIcon fontSize="large" />
                  </Fab>
                </Tooltip>
                <Tooltip title="Create a Unit">
                  <Fab
                    color="primary"
                    onClick={() => history.push('/npv-inventory/create-unit')}
                    style={{ backgroundColor: green[500] }}
                  >
                    <AddIcon fontSize="large" />
                  </Fab>
                </Tooltip>
              </Hidden>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={9} style={{ alignSelf: 'center' }}>
            <FacetChips
              facets={filterFacets}
              onDeleteFacet={handleDeleteFacet}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sm={6}
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2" style={{ paddingRight: '10px' }}>
              Showing {vehicles.length} of {total}
            </Typography>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
              style={{
                float: 'right',
              }}
            >
              <ToggleButton value="list">
                <Tooltip title="List View">
                  <ViewListIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="module">
                <Tooltip title="Card View">
                  <ViewModuleIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
              }}
            >
              {selected.length > 0 && (
                <InventoryToolbar
                  numSelected={selectByFilter ? total : selected.length}
                  total={total}
                  onSelectAllFiltered={() => setSelectByFilter(true)}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box component="div" marginBottom={2}>
        {alignment === 'list' ? (
          <>
            <Hidden lgDown>
              <InventoryTableListView
                vehicles={vehicles}
                sortSelection={sortSelection}
                setSortSelection={setSortSelection}
                onSelectAllClick={handleSelectAllClick}
                selected={selected}
                setSelected={setSelected}
                selectByFilter={selectByFilter}
                setSelectByFilter={setSelectByFilter}
              />
            </Hidden>
            <Hidden lgUp>
              <InventoryListMobileView
                vehicles={vehicles}
                sortSelection={sortSelection}
                setSortSelection={setSortSelection}
              />
            </Hidden>
          </>
        ) : (
          <InventoryTableCardView
            vehicles={vehicles}
            sortSelection={sortSelection}
            setSortSelection={setSortSelection}
            onSelectAllClick={handleSelectAllClick}
            selected={selected}
            setSelected={setSelected}
            selectByFilter={selectByFilter}
            setSelectByFilter={setSelectByFilter}
          />
        )}
      </Box>
      {loading ? (
        <LoadingIndicator networkStatus={networkStatus} />
      ) : (
        nextPage && (
          <Box height="1px">
            <Waypoint onEnter={fetchMoreHandler} />
          </Box>
        )
      )}
      <FilterDrawer
        open={showFilterDrawer}
        onClose={() => setShowFilterDrawer(false)}
        facetResults={data?.npv?.getVehiclesFacets || []}
        selectedFacets={filterFacets}
        setSelectedFacets={setFilterFacets}
        facetOptions={FACET_OPTIONS}
      />

      <SortDrawer
        open={showSortDrawer}
        onClose={() => setShowSortDrawer(false)}
        PaperProps={mobile ? { style: { width: '100%' } } : {}}
        sortSelection={sortSelection}
        setSortSelection={setSortSelection}
      />

      <Hidden mdUp>
        <Fab
          color="primary"
          onClick={() => setShowFilterDrawer(true)}
          style={{
            position: 'fixed',
            bottom: '10px',
            right: '10px',
          }}
        >
          <FilterListIcon fontSize="large" />
        </Fab>
        <Fab
          onClick={() => setShowSortDrawer(true)}
          style={{
            position: 'fixed',
            bottom: '150px',
            right: '10px',
            backgroundColor: blue[500],
            color: 'white',
          }}
        >
          <SortIcon fontSize="large" />
        </Fab>
        <Fab
          color="primary"
          onClick={() => history.push('/npv-inventory/create-unit')}
          style={{
            position: 'fixed',
            bottom: '80px',
            right: '10px',
            backgroundColor: green[500],
          }}
        >
          <AddIcon fontSize="large" />
        </Fab>
        <Box height="200px" /> {/* Room for FABs */}
      </Hidden>
    </Box>
  );
};

export default Inventory;
