import React from 'react';
import { useQuery } from '@apollo/react-hooks';

// Material UI
import { Box, Divider, Paper, Typography } from '@mui/material';

import DealerPicker from 'components/MaterialUI/DealerPicker';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

// Internal
import VehicleShowroomForm from '../../shared_components/VehicleShowroomForm';

import { getShowroomIdsForDealer } from '../../utils';
import { GET_ALL_SHOWROOMS_NAMES_BULK } from '../../graphql/queries';

const AddShowroomVehicle = () => {
  const { dealerId, dealers } = useDealerContext();
  const dealerName = dealers.find(x => x.dealer_id === dealerId)?.dealer_name;

  const { data: showroomsNamesData, loading: showroomsNamesDataLoading } =
    useQuery(GET_ALL_SHOWROOMS_NAMES_BULK, {
      fetchPolicy: 'network-only',
      variables: {
        ids: getShowroomIdsForDealer(dealerId),
      },
    });

  return (
    <>
      <Box component="section" p={2}>
        <DealerPicker />
      </Box>
      <Box component="section" p={2} mb={2}>
        <Typography mt={2} sx={{ fontSize: '24px', fontWeight: '700' }}>
          {dealerName} - Showroom Vehicles
        </Typography>
      </Box>
      {showroomsNamesData?.showroom?.showrooms?.length > 0 ? (
        <VehicleShowroomForm
          showroomsNamesData={showroomsNamesData}
          showroomsNamesDataLoading={showroomsNamesDataLoading}
        />
      ) : (
        <Box component="section" p={2} sx={{ maxWidth: 1200 }}>
          <Paper elevation={4} p={2}>
            <Typography variant="h6" component="h2" p={2}>
              <strong>Add Vehicle</strong>
            </Typography>
            <Divider
              variant="middle"
              sx={{
                margin: '0 1rem',
                border: '1px solid black',
              }}
            />
            <Typography variant="body1" p={2}>
              No showrooms available for this dealer.
            </Typography>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default AddShowroomVehicle;
