import gql from 'graphql-tag';
import { vssVehicleFragment } from './fragments';

export const GET_ALL_SHOWROOMS_VEHICLES_BULK_QUERY = gql`
  query GetShowroomVehicles($ids: [Int!]!) {
    showroom {
      showrooms(ids: $ids) {
        name
        vehicles {
          ...VehicleFields
        }
      }
    }
  }
  ${vssVehicleFragment}
`;

export const GET_ALL_SHOWROOMS_NAMES_BULK = gql`
  query GetShowroomVehicles($ids: [Int!]!) {
    showroom {
      showrooms(ids: $ids) {
        id
        name
      }
    }
  }
`;

export const GET_MAKES = gql`
  query GetMakes($year: Int!) {
    inventory {
      getMakesDrilldown(year: $year) {
        id
        name
      }
    }
  }
`;

export const GET_MODELS = gql`
  query GetModels($makeId: Int!, $year: Int!) {
    inventory {
      getModelsDrilldown(make_id: $makeId, year: $year) {
        id
        name
      }
    }
  }
`;

export const GET_TRIMS = gql`
  query GetTrims($makeName: String!, $modelName: String!, $year: Int!) {
    inventory {
      getTrimsDrilldown(
        make_name: $makeName
        model_name: $modelName
        year: $year
      )
    }
  }
`;

export const GET_STYLE_ID_AND_NAME = gql`
  query GetStyleIdAndName(
    $makeName: String!
    $modelName: String!
    $year: Int!
    $trim: String!
  ) {
    inventory {
      getStyleIdAndName(
        make_name: $makeName
        model_name: $modelName
        year: $year
        trim: $trim
      ) {
        style_id
      }
    }
  }
`;

export const GET_VEHICLE = gql`
  query GetVehicle($vehicleId: Int!) {
    showroom {
      vehicle(id: $vehicleId) {
        ...VehicleFields
      }
    }
  }
  ${vssVehicleFragment}
`;
