/* external */
import React from 'react';
import { useFormContext } from 'react-hook-form';

/* Material UI */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import { pitchStyles } from '../utils';
import { NO_DISCOUNTS_DEALERS } from '../const';
import AccessoryGroupBox from './common/AccessoryGroupBox';

const useStyles = makeStyles(theme => ({
  sectionTitle: pitchStyles.sectionTitle(theme),
  discountsBox: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: '95%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3),
      width: '100%',
    },
  },
}));

const PitchAccessoriesDiscounts = ({ accessory, discount, dealerId }) => {
  const classes = useStyles();

  const { watch } = useFormContext();

  const noDiscounts = NO_DISCOUNTS_DEALERS.includes(dealerId);

  const accessoryFields = watch('vehicles.0.accessories', []);
  const discountFields = watch('vehicles.0.discounts', []);

  const sectionTitle = !noDiscounts
    ? 'Accessories and Discounts'
    : 'Accessories';

  return (
    <Box>
      <Box display="flex" alignItems="center" className={classes.sectionTitle}>
        <Box>{sectionTitle}</Box>
      </Box>

      <Box className={classes.discountsBox}>
        <AccessoryGroupBox
          itemGroup={accessory}
          itemGroupFields={accessoryFields}
          type="accessories"
        />

        {!noDiscounts && (
          <AccessoryGroupBox
            itemGroup={discount}
            itemGroupFields={discountFields}
            type="discounts"
          />
        )}
      </Box>
    </Box>
  );
};

export default PitchAccessoriesDiscounts;
