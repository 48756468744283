import { DEALER_ID_TO_SHOWROOM_ID_MAP } from './constants';

/** Creates a single showroom vehicle row data object.
 * @param {number} id - Vehicle ID.
 * @param {string} category - Vehicle category.
 * @param {number} year - Vehicle year.
 * @param {string} make - Vehicle make.
 * @param {string} model - Vehicle model.
 * @param {string} trim - Vehicle trim.
 * @param {number} style_id - Vehicle style ID.
 * @param {number} freight_pdi - Vehicle freight and PDI cost.
 * @param {string} showroom_name - Showroom name.
 * @returns {Object} - Showroom vehicle row data.
 */
export function createShowroomVehicleRowData(
  id,
  category,
  year,
  make,
  model,
  trim,
  style_id,
  freight_pdi,
  showroom_name,
) {
  return {
    id,
    category,
    year,
    make,
    model,
    trim,
    style_id,
    freight_pdi,
    showroom_name,
  };
}

/**
 * @typedef Vehicle
 * @type {Object}
 * @property {number} id - Vehicle ID.
 * @property {number} showroom_id - Showroom ID.
 * @property {string} showroom_name - Showroom name.
 * @property {Object} category - Vehicle category.
 * @property {number} category.id - Vehicle category ID.
 * @property {string} category.name - Vehicle category name.
 * @property {number} year - Vehicle year.
 * @property {string} make - Vehicle make.
 * @property {string} model - Vehicle model.
 * @property {Object} style - Vehicle style.
 * @property {string} style.trim - Vehicle trim.
 * @property {number} freight_pdi - Vehicle freight and PDI cost.
 * @property {number} style_id - Vehicle style ID.
 */

/**
 * Creates initial values for the edit vehicle form.
 * @param {Vehicle} vehicle - Vehicle data.
 * @returns {?Object} - Initial values for the edit vehicle form if vehicle is provided.
 */
export function createEditVehicleFormInitialValues(vehicle) {
  if (!vehicle) return null;

  const { id: vehicleId, showroom_id: showroomId } = vehicle;

  if (!vehicleId || !showroomId) return null;

  return {
    id: vehicleId,
    showroomId,
    showroomName: vehicle?.showroom_name || '',
    category: {
      id: vehicle?.category?.id || '',
      name: vehicle?.category?.name || '',
    },
    year: vehicle?.year || '',
    make: vehicle?.make || '',
    model: vehicle?.model || '',
    trim: vehicle?.style?.trim || '',
    freightPdi: vehicle?.freight_pdi || '',
    styleId: vehicle?.style_id || '',
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map(el => el[0]);
}

export function getShowroomIdsForDealer(dealerId) {
  return DEALER_ID_TO_SHOWROOM_ID_MAP[dealerId] || [];
}

export function getModelLineupYears() {
  const currentYear = new Date().getFullYear();
  return [currentYear - 1, currentYear, currentYear + 1];
}

export function resetDependentFields(fieldNames, setValue) {
  fieldNames.forEach(field => setValue(field, ''));
}

export function cleanUpdateVehiclePayload(payload) {
  const { showroomId, categoryId, year, make, model, freightPdi, styleId } =
    payload;

  return {
    showroom_id: showroomId,
    category_id: categoryId,
    year,
    make,
    model,
    freight_pdi: freightPdi,
    style_id: styleId,
  };
}
