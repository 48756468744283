export const ROWS_PER_PAGE_OPTIONS = {
  5: 5,
  10: 10,
  25: 25,
  50: 50,
};

export const DEFAULT_ROWS_PER_PAGE = ROWS_PER_PAGE_OPTIONS[50];

// TODO: Hardcoded for now, will need to be dynamic
// Showroom IDs for the selected dealer
/**
 * 1: Go Honda (a.k.a Test Showroom) - (Dealer ID: 18)
 * 5: Airdrie Honda - (Dealer ID: 5957)
 * 6: T&T Honda - (Dealer ID: 5956)
 * 7: Honda Red Deer - (Dealer ID: 270)
 * 8: St. Albert Honda - (Dealer ID: 253)
 * 9: Jaguar Calgary - (Dealer ID: 133)
 * 10: Jaguar Edmonton - (Dealer ID: 331)
 * 11: Jaguar Richmond - (Dealer ID: 2250)
 * 12: Land Rover Calgary - (Dealer ID: 133)
 * 13: Land Rover Edmonton - (Dealer ID: 331)
 * 14: Land Rover Richmond - (Dealer ID: 2250)
 */
export const DEALER_ID_TO_SHOWROOM_ID_MAP = {
  18: [1],
  5957: [5],
  5956: [6],
  270: [7],
  253: [8],
  133: [9, 12],
  331: [10, 13],
  2250: [11, 14],
};

export const categoryOrder = [
  {
    id: 1,
    label: 'Cars',
  },
  {
    id: 2,
    label: 'SUVs',
  },
  {
    id: 3,
    label: 'Trucks/Minivans',
  },
  {
    id: 4,
    label: 'Hybrid/Electric',
  },
  {
    id: 5,
    label: 'SUV',
  },
  {
    id: 6,
    label: 'Sports Car',
  },

  {
    id: 7,
    label: 'Sedan',
  },
  {
    id: 8,
    label: 'Range Rover',
  },
  {
    id: 9,
    label: 'Discovery',
  },
  {
    id: 10,
    label: 'Defender',
  },
];
