import React, { useEffect, useState } from 'react';

/* material ui */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';

/* external */
import { isEqual, isEmpty } from 'lodash';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* internal */
import { titleize } from 'utils';
import { CBB_CONDITIONS } from './index';
import ValuationResults from './ValuationResults';
import { parseValuation } from './utils';
import Loading from 'components/MaterialUI/Loading';

const VALUATION_TRIM_QUERY = gql`
  query ValuationTrimQuery($make: String!, $model: String!, $year: Int!) {
    valuation {
      getTrims(make: $make, model: $model, year: $year)
    }
  }
`;

const VALUATION_STYLE_QUERY = gql`
  query ValuationStyleQuery(
    $make: String!
    $model: String!
    $trim: String!
    $year: Int!
  ) {
    valuation {
      getStyles(make: $make, model: $model, trim: $trim, year: $year)
    }
  }
`;

// needed to get the vid and available options
const VALUATION_VEHICLES_QUERY = gql`
  query ValuationVehiclesQuery(
    $year: Int
    $make: String
    $model: String
    $trim: String
    $style: String
    $vin: String
  ) {
    valuation {
      getVehicles(
        vin: $vin
        year: $year
        make: $make
        model: $model
        trim: $trim
        style: $style
      ) {
        options {
          option_code
          description
        }
        make_name
        model_name
        style_name
        trim_name
        vid
        year
      }
    }
  }
`;

const VALUATION_VALUE_QUERY = gql`
  query ValuationValueQuery($vid: Int!, $odometer: Int) {
    valuation {
      getValue(vid: $vid, kilometers: $odometer) {
        condition
        options {
          average
          clean
          description
          extra_clean
          option_code
          rough
        }
        wholesale_prices {
          average
          clean
          extra_clean
          rough
        }
        retail_prices {
          average
          clean
          extra_clean
          rough
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      marginBottom: theme.spacing(2),
    },
    fontWeight: 'bold',
  },
}));

const Section = ({ children }) =>
  useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
    <Accordion style={{ width: '100%', marginBottom: '2px' }}>
      {children}
    </Accordion>
  ) : (
    <>{children}</>
  );

const Label = ({ children }) =>
  useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      style={{ fontWeight: 'bold', fontSize: '16px' }}
    >
      {children}
    </AccordionSummary>
  ) : (
    <FormLabel style={{ fontWeight: 'bold' }} component="legend">
      {children}
    </FormLabel>
  );

const Details = ({ children }) =>
  useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
    <AccordionDetails>{children}</AccordionDetails>
  ) : (
    <>{children}</>
  );

const CBBCalculator = ({
  year,
  make,
  model,
  odometer,
  cbbData = {},
  onChangeCbbData,
  liveUpdate = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [myCbbData, setMyCbbData] = useState(cbbData);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  /* Queries! */
  const trimQuery = useQuery(VALUATION_TRIM_QUERY, {
    variables: { year, make, model },
    skip: !(year && make && model),
    fetchPolicy: 'network-only',
  });

  const [getStyles, styleQuery] = useLazyQuery(VALUATION_STYLE_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [getValuationVehicles, valuationVehiclesQuery] = useLazyQuery(
    VALUATION_VEHICLES_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        const newVid = data.valuation.getVehicles?.[0]?.vid;
        setMyCbbData(prev => {
          if (prev.vid === newVid) return prev; // Avoid unnecessary state updates
          return { ...prev, vid: newVid };
        });
      },
    },
  );

  const [getValuation, valuationQuery] = useLazyQuery(VALUATION_VALUE_QUERY, {
    fetchPolicy: 'network-only',
  });

  // Set variables from queries
  const trimNames = trimQuery.data?.valuation.getTrims ?? [];
  const styleNames = styleQuery.data?.valuation.getStyles ?? [];
  const options =
    valuationVehiclesQuery.data?.valuation.getVehicles[0]?.options.map(
      ({ option_code, description }) => ({ option_code, description }),
    ) ?? [];
  const valuation = valuationQuery.data?.valuation.getValue ?? {};
  const noValuation =
    myCbbData.style &&
    valuationVehiclesQuery.data &&
    !valuationQuery.loading &&
    isEmpty(valuation);

  // useEffect stuff

  useEffect(() => {
    if (!isEqual(myCbbData, cbbData)) {
      if (
        (!isEqual(myCbbData.style, cbbData.style) ||
          !isEqual(myCbbData.trim, cbbData.trim)) &&
        myCbbData.equipment
      ) {
        // clear out equipment if style or trim has changed.
        setMyCbbData(prev => ({ ...prev, equipment: null }));
      } else onChangeCbbData(myCbbData);
    }
  }, [cbbData, liveUpdate, myCbbData, onChangeCbbData, setMyCbbData]);

  useEffect(() => {
    // Got only one trim (even if it's blank)? Set the trim value.
    if (trimNames.length === 1 && myCbbData.trim !== trimNames[0])
      setMyCbbData(prev => ({ ...prev, trim: trimNames[0] }));
  }, [myCbbData.trim, setMyCbbData, trimNames]);

  useEffect(() => {
    // in the off change myCbbData comes through as undefined, it gets set as {}
    // which makes trim undefined, not null, which then passes the if statement.
    if (!isEmpty(myCbbData) && myCbbData.trim !== null && year && make && model)
      getStyles({ variables: { year, make, model, trim: myCbbData.trim } });
  }, [getStyles, make, model, myCbbData.trim, year]);

  useEffect(() => {
    if (year && make && model && myCbbData.style && myCbbData.trim !== null)
      getValuationVehicles({
        variables: {
          year,
          make,
          model,
          trim: myCbbData.trim,
          style: myCbbData.style,
        },
      });
  }, [
    getValuationVehicles,
    make,
    model,
    myCbbData.style,
    myCbbData.trim,
    myCbbData,
    year,
  ]);

  useEffect(() => {
    if (myCbbData.vid)
      getValuation({ variables: { vid: myCbbData.vid, odometer } });
  }, [myCbbData.vid, odometer, getValuation]);

  useEffect(() => {
    if (myCbbData.vid && !isEmpty(valuation)) {
      const valuationData = parseValuation(valuation, myCbbData.equipment);
      setMyCbbData(prev => ({
        ...prev,
        roughPrice: valuationData.wholesaleTotals.rough,
        averagePrice: valuationData.wholesaleTotals.average,
        cleanPrice: valuationData.wholesaleTotals.clean,
        extraCleanPrice: valuationData.wholesaleTotals.extra_clean,
      }));
      if (myCbbData.condition)
        setMyCbbData(prev => ({
          ...prev,
          selectedPrice: valuationData.wholesaleTotals[myCbbData.condition],
        }));
    }
  }, [
    valuation,
    myCbbData.vid,
    myCbbData.condition,
    myCbbData.equipment,
    setMyCbbData,
  ]);

  // Set condition based on returned condition from valuation.
  useEffect(() => {
    if (!myCbbData.condition && valuation?.condition)
      setMyCbbData(prev => ({
        ...prev,
        condition: valuation.condition,
      }));
  }, [myCbbData.condition, valuation, setMyCbbData]);

  // handlers
  const changeHandler = name => e =>
    setMyCbbData(prev => ({ ...prev, [name]: e.target.value }));

  const isOptionSelected = optionCode =>
    JSON.parse(myCbbData.equipment ?? '[]').includes(optionCode);

  const changeOptionHandler =
    optionCode =>
    ({ target: { checked } }) =>
      setMyCbbData(prev => ({
        ...prev,
        equipment: JSON.stringify(
          checked
            ? [...JSON.parse(prev.equipment ?? '[]'), optionCode]
            : JSON.parse(prev.equipment ?? '[]').filter(x => x !== optionCode),
        ),
      }));

  return (
    <Box>
      <Box className={classes.title}>
        {mobile ? 'CBB Pricing' : 'Canadian Black Book'}
      </Box>
      {mobile && (
        <ValuationResults
          summary
          cbbData={myCbbData}
          valuation={valuation}
          mobile
        />
      )}
      {/*  CBB Table */}
      <Grid container>
        <Grid item xs={12} sm>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <Section>
              <Label>Trim</Label>
              {trimQuery.loading && <Loading text="Fetching trims" />}
              <RadioGroup
                value={myCbbData?.trim ?? ''}
                onChange={changeHandler('trim')}
              >
                {trimNames.map(trimName => (
                  <Grid key={trimName} item xs={6} sm={12}>
                    <FormControlLabel
                      value={trimName}
                      control={<Radio color="secondary" />}
                      label={trimName || 'Default'}
                    />
                  </Grid>
                ))}
              </RadioGroup>
            </Section>
          </FormControl>
        </Grid>
        {/* style selection */}
        <Grid item xs={12} sm>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <Section>
              <Label>Style</Label>
              {styleQuery.loading && <Loading text="Fetching styles" />}
              <Details>
                <RadioGroup
                  value={myCbbData?.style ?? ''}
                  onChange={changeHandler('style')}
                >
                  {styleNames.length === 0 && (
                    <Grid item xs={12} style={{ paddingTop: theme.spacing(1) }}>
                      Choose a trim.
                    </Grid>
                  )}
                  {styleNames.map(styleName => (
                    <Grid key={styleName} item xs={6} sm={12}>
                      <FormControlLabel
                        value={styleName}
                        control={<Radio color="secondary" />}
                        label={styleName || 'Default'}
                      />
                    </Grid>
                  ))}
                </RadioGroup>
              </Details>
            </Section>
          </FormControl>
        </Grid>
        {/*********************/}
        {/* options selection (cbbEquipment) */}
        <Grid item xs={12} sm>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <Section>
              <Label>Equipment</Label>
              {valuationVehiclesQuery.loading && (
                <Loading text="Fetching equipment" />
              )}
              <Details>
                <FormGroup style={{ width: '100%' }}>
                  <Grid container style={{ width: '100%' }}>
                    {options.length === 0 && (
                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: theme.spacing(1) }}
                      >
                        No additional equipment available.
                      </Grid>
                    )}
                  </Grid>
                  {/* TODO: might be nice to make this type of control a separate reusable component.
                  Like OptionArrayCheckboxes.  Maybe with a json option?  (ie. either pass in an actual array
                  and get an array back in the handler, or pass in a JSONified array and get back a JSONified array) */}
                  {options.map(({ option_code, description }) => (
                    <Grid item xs={6} sm={12} key={option_code}>
                      <FormControlLabel
                        key={`${option_code}`}
                        label={description}
                        control={
                          <Checkbox
                            checked={isOptionSelected(option_code)}
                            onChange={changeOptionHandler(option_code)}
                          />
                        }
                      />
                    </Grid>
                  ))}
                </FormGroup>
              </Details>
            </Section>
          </FormControl>
        </Grid>
        {/********************/}
        <Grid item xs={12} sm>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <Section>
              <Label> Condition</Label>
              <Details>
                <RadioGroup
                  value={myCbbData.condition ?? ''}
                  style={{ width: '100%' }}
                  onChange={changeHandler('condition')}
                >
                  <Grid container style={{ width: '100%' }}>
                    {CBB_CONDITIONS.map(condition => (
                      <Grid key={condition} item xs={6} sm={12}>
                        <FormControlLabel
                          value={condition}
                          control={<Radio color="secondary" />}
                          label={titleize(condition)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </Details>
            </Section>
          </FormControl>
        </Grid>
      </Grid>
      {/* valuation results */}
      {valuationQuery.loading ? (
        <Loading text="Retrieving Valuation" />
      ) : myCbbData.vid ? (
        <ValuationResults
          cbbData={myCbbData}
          mobile={mobile}
          valuation={valuation}
        />
      ) : noValuation ? (
        <Alert severity="warning">
          No valuation information is available for this vehicle.
        </Alert>
      ) : (
        <>Select trim and style for valuation</>
      )}
    </Box>
  );
};

export default CBBCalculator;
