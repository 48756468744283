import React from 'react';

/* external */
import { isEmpty } from 'lodash';

/* Material UI */
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';

const ascSorter = ({ value: a }, { value: b }) => (a || '').localeCompare(b);

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  section: {
    paddingBottom: '10px',
    outlineColor: 'black',
  },
  subPanel: {
    width: '100%',
  },
}));

const FilterDrawer = ({
  facetResults,
  startDateFromFilter,
  startDateUntilFilter,
  createdFromFilter,
  createdUntilFilter,
  selectedFacets,
  setStartDateFromFilter,
  setStartDateUntilFilter,
  setCreatedFromFilter,
  setCreatedUntilFilter,
  setSelectedFacets,
  loading,
  type,
}) => {
  const classes = useStyles();

  const FACET_OPTIONS = [
    {
      field: 'is_confirmed',
      model: 'Appointment',
      title: 'Confirmation',
      options: {
        sort: ascSorter,
      },
    },
    {
      field: 'type_ids',
      model: 'Appointment',
      title: 'Type',
      options: {
        sort: ascSorter,
      },
    },
    {
      field: 'statuses',
      model: 'Appointment',
      title: 'Status',
      options: {
        sort: ascSorter,
      },
    },
    {
      field: 'attendees',
      model: 'Appointment',
      title: 'Assignee',
      options: {
        sort: ascSorter,
      },
    },
  ];

  const deleteAllFacets = () => {
    setSelectedFacets([]);
    setStartDateFromFilter(null);
    setStartDateUntilFilter(null);
    setCreatedFromFilter(null);
    setCreatedUntilFilter(null);
  };

  const getFacetResults = (model, field, options = {}) =>
    facetResults
      .find(x => x.field === field && (!model || x.model === model))
      ?.data.sort(options.sort || (_ => 0))
      .map(({ value, ...rest }) => ({
        value: (options.format || (x => x))(value),
        ...rest,
      })) || [];

  const isSelected = (_model, _field, _key, _value) =>
    selectedFacets.findIndex(
      ({ model, field, key, value }) =>
        model === _model &&
        field === _field &&
        key === _key &&
        value === _value,
    ) !== -1;

  const deleteFacet = (_model, _field, _key, _value) => {
    setSelectedFacets(prev =>
      prev.filter(
        ({ model, field, key, value }) =>
          model !== _model ||
          field !== _field ||
          key !== _key ||
          value !== _value,
      ),
    );
  };

  const setFacet = (model, field, key, value, options) => {
    setSelectedFacets(prev => [...prev, { model, field, key, value, options }]);
  };

  const toggleFacet = (model, field, key, value, options) => {
    if (isSelected(model, field, key, value))
      deleteFacet(model, field, key, value);
    else setFacet(model, field, key, value, options);
  };

  const showReset =
    !isEmpty(selectedFacets) ||
    startDateFromFilter ||
    startDateUntilFilter ||
    createdFromFilter ||
    createdUntilFilter;
  return (
    <List className={classes.panel}>
      <Box className={classes.title}>Filters</Box>
      {showReset && (
        <Box style={{ paddingBottom: '10px' }}>
          <Button style={{ color: 'red' }} onClick={deleteAllFacets}>
            <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
          </Button>
        </Box>
      )}
      {FACET_OPTIONS.map(({ field, model, title, options = {} }) => (
        <Box style={{ paddingBottom: '10px' }} key={`${field}${model}${title}`}>
          <Accordion key={title}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {title}
            </AccordionSummary>
            <AccordionDetails>
              <List component="div" disablePadding className={classes.subPanel}>
                {getFacetResults(model, field, options).map(
                  ({ count, key, value }) => (
                    <ListItem
                      disabled={loading}
                      dense
                      button
                      onClick={() =>
                        toggleFacet(model, field, key, value, options)
                      }
                      key={`${model}${field}${key}${value}`}
                      selected={isSelected(model, field, key, value)}
                    >
                      {isSelected(model, field, key, value) ? (
                        <DoneIcon
                          style={{
                            fontSize: 'small',
                            paddingRight: '10px',
                            width: '25px',
                          }}
                        />
                      ) : (
                        <div style={{ paddingRight: '10px', width: '25px' }}>
                          {' '}
                        </div>
                      )}
                      <ListItemText
                        primary={`${value || 'Unknown'} (${count})`}
                      />
                    </ListItem>
                  ),
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
      <Box style={{ paddingBottom: '10px', maxWidth: '100%' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Scheduled For
          </AccordionSummary>
          <AccordionDetails>
            <Box flexDirection="column" alignItems="stretch">
              <Box>From</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setStartDateFromFilter(date)}
                  value={startDateFromFilter && moment(startDateFromFilter)}
                  variant="dialog"
                />
              </Box>
              <Box>To</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setStartDateUntilFilter(date)}
                  value={startDateUntilFilter && moment(startDateUntilFilter)}
                  variant="dialog"
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box style={{ paddingBottom: '10px', maxWidth: '100%' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Created
          </AccordionSummary>
          <AccordionDetails>
            <Box flexDirection="column" alignItems="stretch">
              <Box>From</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setCreatedFromFilter(date)}
                  value={createdFromFilter && moment(createdFromFilter)}
                  variant="dialog"
                />
              </Box>
              <Box>To</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setCreatedUntilFilter(date)}
                  value={createdUntilFilter && moment(createdUntilFilter)}
                  variant="dialog"
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </List>
  );
};

export default FilterDrawer;
