import React from 'react';

import { Alert, AlertTitle, Box } from '@mui/material';

import { getFullDateTime, titleize } from 'utils';

import {
  cadfmt,
  intToLogRefType,
  intToLogType,
  LogRefType,
  LogStatus,
  LogType,
  TransactionStatus,
} from './constants';

export function LogEntryAlert({ entry, transactionsById }) {
  let severity;
  let title = titleize(intToLogType(entry.type));
  const refType = titleize(intToLogRefType(entry.ref_type));

  switch (entry.status) {
    case LogStatus.CREATED:
    case LogStatus.PENDING:
      severity = 'info';
      title += ' Pending';
      break;

    case LogStatus.SUCCESS:
      severity = 'success';
      title += ' Succeeded';
      break;

    case LogStatus.FAILED:
    default:
      severity = 'error';
      title += ' Failed';
      const transaction = transactionsById.get(entry.ref_id.toString());
      if (
        entry.ref_type === LogRefType.GLOBAL_PAYMENTS &&
        transaction?.status === TransactionStatus.SUCCESS
      ) {
        title += ' (paid with settling failure)';
      }
  }

  return (
    <Alert severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      <Box>
        <b>Date:</b> {getFullDateTime(entry.created_at)}
      </Box>
      <Box>
        <b>ID:</b> {entry.id}
      </Box>
      {entry.type === LogType.PAYMENT && (
        <Box>
          <b>Type:</b> {refType}
        </Box>
      )}
      {Boolean(entry.cents) && (
        <Box>
          <b>Amount:</b> {cadfmt.format(Math.abs(entry.cents) / 100)}
        </Box>
      )}
      {entry.username && (
        <Box>
          <b>Username:</b> {entry.username}
        </Box>
      )}
    </Alert>
  );
}
