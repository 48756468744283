import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

/**
 * Simple callback with no arguments.
 *
 * @callback ConfirmDialogNoArgCallback
 */

/**
 * Component that shows a confirmation dialog
 *
 * @component
 *
 * @param {object} props
 * @param {object} props.abortButtonProps - Extra `Button` props to pass to the abort button
 * @param {string} [props.abortText='No'] - Text to show inside abort button
 * @param {object} props.confirmButtonProps - Extra `Button` props to pass to the confirm button
 * @param {string} [props.confirmText='Yes'] - Text to show inside confirm button
 * @param {any} props.content - Main content of the dialog, e.g. text or a component
 * @param {boolean} props.isOpen - Whether or not to show the dialog
 * @param {ConfirmDialogNoArgCallback} props.onClose - Gets called when attempting to close dialog no matter what button got clicked.
 * @param {ConfirmDialogNoArgCallback} props.onConfirm - Gets called when the confirm button gets clicked.
 * @param {string} props.text - Text to show above content
 * @param {string} props.titleText - Title of the dialog
 * @return {React.JSX.Dialog}
 */
const ConfirmDialog = ({
  abortButtonProps = {},
  abortText = 'No',
  confirmButtonProps = {},
  confirmText = 'Yes',
  content,
  isOpen,
  onClose,
  onConfirm,
  text = 'Do it?',
  titleText,
}) => {
  // TODO: MPAUC-94: This doesn't seem to be stopping propagation of clicks
  // outside of the modal dialog.  Not sure how to fix that....
  const handleClose = e => {
    e.stopPropagation();
    onClose();
  };

  const handleConfirm = e => {
    e.stopPropagation();
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {titleText && <DialogTitle>{titleText}</DialogTitle>}
      <DialogContent>
        {text && text !== '' && <DialogContentText>{text}</DialogContentText>}
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" {...abortButtonProps}>
          {abortText}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          autoFocus
          {...confirmButtonProps}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
