import React from 'react';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles(theme => ({
  chipBox: {
    paddingRight: '5px',
    paddingBottom: '5px',
  },
}));

const FacetChips = ({
  facets,
  onDeleteFacet,
  others = [],
  handleDeleteAllROsFacet,
  type,
}) => {
  const classes = useStyles();
  const openAndClosedROs = () => {
    // Existence needs to be inferred since inclusion of two facets one for open and one for closed
    // does not produce all ROs, no facet for BOTH open/closed equates to all ROs
    if (
      (facets.length === 0 ||
        !facets.map(facet => facet.field).includes('is_open')) &&
      type !== 'appointment'
    ) {
      return true;
    }
  };

  return (
    <Box display="flex" flexWrap="wrap">
      {facets.map(({ field, model, value, options: { label } }) => (
        <Box
          component="span"
          key={`${model}${field}${value}`}
          className={classes.chipBox}
        >
          <Chip
            label={label || value || 'Unknown'}
            value={value}
            onDelete={() => onDeleteFacet(model, field, value)}
          />
        </Box>
      ))}
      {others.map(({ label, onDelete }, index) => (
        <Box
          component="span"
          key={`${label}${index}`}
          className={classes.chipBox}
        >
          <Chip label={label} value={label} onDelete={onDelete} />
        </Box>
      ))}
      {openAndClosedROs() && (
        <Box component="span" key={`All ROs`} className={classes.chipBox}>
          <Chip
            label={'All ROs'}
            value={'All ROs'}
            onDelete={handleDeleteAllROsFacet}
          />
        </Box>
      )}
    </Box>
  );
};

export default FacetChips;
