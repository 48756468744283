/* Material UI */
import { useQuery } from '@apollo/react-hooks';

/* external */
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import React from 'react';

import {
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import Alert from '@mui/material/Alert';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import TradeInsCard from 'modules/appraisals/components/TradeInsCard';
import { AppraisalType } from 'modules/appraisals/const';
import CreditAppsCard from 'modules/credit_apps/components/CreditAppsCard';

import AppointmentBar from './AppointmentBar';
import AppointmentCard from './AppointmentCard';
import AssignedSalespeopleCard from './AssignedSalespeopleCard';
import Bulletins from './Bulletins';
import CommunicationHistory from './CommunicationHistory';
import ContactInformationCard from './ContactInformationCard';
import CustomerActivity from './CustomerActivity';
import DesktopAppointment from './DesktopAppointment';
import DetachGoCardModal from './GoCard/DetachGoCardModal';
import DoNotContactModal from './DoNotContactModal';
import GoCard from './GoCard';
import Header from './Header';
import Leads from './Leads';
import MobileAppointment from './MobileAppointment';
import Notes from './Notes';
import Opportunities from './Opportunities';
import OpportunitiesBar from './OpportunitiesBar';
import OpportunitiesTab from './OpportunitiesTab';
import OptInPreferencesCard from './OptInPreferencesCard';
import OptInPreferencesModal from './OptInPreferencesModal';
import Profile from './Profile';
import SalesHistory from './SalesHistory';
import SegmentProfile from './SegmentProfile';
import ServiceHistory from './ServiceHistory';
import SummaryCards from './SummaryCards';
import Tasks from './Tasks';
import VehiclesCard from './VehiclesCard';
import { Role } from 'constants.js';
import BulletinAlert from './BulletinAlert';

export const DETAILS_QUERY = gql`
  query customerDetails($customer_id: ID!) {
    customer(_id: $customer_id) {
      _id
      appraisals {
        id
        ...TradeInsCardAppraisal
      }

      ...AppointmentBarFragment
      ...LeadsFragment
      ...TasksFragment
      ...SalesHistoryFragment
      ...ServiceHistoryFragment
      ...OpportunitiesBarFragment
      ...VehiclesCardFragment
      ...OptInPreferencesCardFragment
      ...AssignedSalespeopleCardFragment
      ...CustomerDetailsHeaderFragment
      ...EditCustomerDetailsFragment
      ...AppointmentCardFragment
      ...GoCardFragment
      ...ProfileFragment
      ...OpportunitiesFragment
      ...DetachGoCardModalFragment
      ...ContactInformationCardFragment
      ...DesktopAppointmentFragment
      ...MobileAppointmentFragment
      ...DoNotContactModalFragment
      ...OpportunitiesTabFragment
      ...OptInPreferencesModalFragment

      credit_apps {
        id
        ...CreditAppsCardCreditApp
      }
      ...BulletinsFragment
    }
  }
  ${Leads.fragments.customer}
  ${Tasks.fragments.customer}
  ${SalesHistory.fragments.customer}
  ${ServiceHistory.fragments.customer}
  ${OpportunitiesBar.fragments.customer}
  ${VehiclesCard.fragments.customer}
  ${OptInPreferencesCard.fragments.customer}
  ${AssignedSalespeopleCard.fragments.customer}
  ${Header.fragments.customer}
  ${AppointmentCard.fragments.customer}
  ${GoCard.fragments.customer}
  ${Profile.fragments.customer}
  ${Opportunities.fragments.customer}
  ${TradeInsCard.fragments.appraisal}
  ${CreditAppsCard.fragments.creditApp}
  ${DetachGoCardModal.fragments.customer}
  ${AppointmentBar.fragments.customer}
  ${ContactInformationCard.fragments.customer}
  ${DesktopAppointment.fragments.customer}
  ${DoNotContactModal.fragments.customer}
  ${MobileAppointment.fragments.customer}
  ${OpportunitiesTab.fragments.customer}
  ${OptInPreferencesModal.fragments.customer}
  ${Bulletins.fragments.customer}
`;

const TabPanel = ({ value, index, children }) =>
  value === index && <Box>{children}</Box>;

const CustomerDetails = ({ customer_id, openModal = {} }) => {
  const { currentUser } = useUserContext();
  const [tabIndex, setTabIndex] = React.useState(openModal?.tab || 0);

  const desktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  // Cache loses data when going from opp to customer page
  const { data, loading, refetch } = useQuery(DETAILS_QUERY, {
    variables: { customer_id },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  if (loading) return <Loading />;
  const customer = data?.customer;
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  const hasBulletins = customer?.bulletins?.length > 0;

  if (!isEmpty(customer)) {
    return (
      <Box style={{ overflow: 'hidden', width: '100%' }}>
        {customer?.do_not_contact === true && (
          <Alert severity="warning" style={{ paddingTop: '15px' }}>
            This customer is LOCKED since the customer is marked as do not
            contact
          </Alert>
        )}
        <Header
          customer={customer}
          style={{ paddingBottom: '20px' }}
          refetch={refetch}
        />
        <Box style={{ padding: '20px 20px 10px 20px' }}>
          {hasBulletins && <BulletinAlert bulletins={customer?.bulletins} />}
        </Box>
        <SummaryCards customer={customer} refetch={refetch} />
        <Tabs
          TabIndicatorProps={{ style: { background: '#ff5a00' } }}
          style={{ margin: '12px', backgroundColor: '#fafafa' }}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={tabIndex}
          onChange={(event, tabIndex) => setTabIndex(tabIndex)}
        >
          <Tab label="Overview" />
          <Tab label="Notes" />
          <Tab label="Opportunities" />
          <Tab label="Sales History" />
          <Tab label="Service History" />
          <Tab label="Credit Apps" />
          <Tab label="Activities" />
          <Tab label="Communication" />
          <Tab label="Leads" />
          <Tab label="Tasks" />
          <Tab label="Segment Profile" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <Grid
            container
            spacing={3}
            direction={desktop ? 'row' : 'column'}
            justifyContent={desktop ? 'space-between' : 'center'}
            alignItems={'stretch'}
            style={{ paddingLeft: '12px', paddingRight: '30px', margin: 0 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{ overflow: 'auto', maxHeight: '350px' }}
            >
              <Bulletins customer={customer} refetch={refetch} />
            </Grid>
            <Grid item xs={12}>
              <OpportunitiesBar customer={customer} refetch={refetch} />
            </Grid>
            <Grid item xs={12}>
              <AppointmentBar customer={customer} />
            </Grid>
            <Grid
              item
              xs={6}
              style={desktop ? { minWidth: '350px' } : { minWidth: '100%' }}
              id="vehicles"
            >
              <VehiclesCard customer={customer} refetchCustomer={refetch} />
            </Grid>
            <Grid
              item
              xs={6}
              style={desktop ? { minWidth: '350px' } : { minWidth: '100%' }}
            >
              <TradeInsCard
                appraisals={customer.appraisals}
                allowEdit
                allowNew
                defaultAppraisal={{
                  customerId: customer_id,
                  appraisalType: AppraisalType.TRADE_IN,
                  firstName: customer.first_name,
                  lastName: customer.last_name,
                }}
                emptyMessage="There are no trades for this customer."
                refetch={refetch}
                customer={customer}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={desktop ? { minWidth: '350px' } : { minWidth: '100%' }}
            >
              <AssignedSalespeopleCard customer={customer} />
            </Grid>
            <Grid
              item
              xs={6}
              style={desktop ? { minWidth: '350px' } : { minWidth: '100%' }}
            >
              <OptInPreferencesCard customer={customer} refetch={refetch} />
            </Grid>
            <Grid
              item
              xs={12}
              xl={6}
              style={desktop ? { minWidth: '350px' } : { minWidth: '100%' }}
            >
              <GoCard customer={customer} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Notes
            customerId={customer._id}
            autoOpen={openModal?.modal === 'notes'}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
            style={{ padding: '12px' }}
          >
            <Grid item xs={12}>
              <OpportunitiesTab customer={customer} isHidden={isHidden} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
            style={{ padding: '12px' }}
          >
            <Grid item xs={12}>
              <SalesHistory customer={customer} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={4}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
            style={{ padding: '12px' }}
          >
            <Grid item xs={12}>
              <ServiceHistory customer={customer} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={5}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
            style={{ padding: '12px' }}
          >
            <Grid item xs={12}>
              <CreditAppsCard
                creditApps={customer.credit_apps}
                refetch={refetch}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={6}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
            style={{ padding: '12px' }}
          >
            <Grid item xs={12}>
              <CustomerActivity customerId={customer._id} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={7}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
            style={{ padding: '12px' }}
          >
            <Grid item xs={12}>
              <CommunicationHistory customerId={customer._id} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={8}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
            style={{ padding: '12px' }}
          >
            <Grid item xs={12}>
              <Leads customer={customer} isHidden={isHidden} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={9}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
            style={{ padding: '12px' }}
          >
            <Grid item xs={12}>
              <Tasks
                customer={customer}
                refetch={refetch}
                isHidden={isHidden}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={10}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
            style={{ padding: '12px' }}
          >
            <Grid item xs={12}>
              <SegmentProfile customerId={customer._id} />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    );
  } else if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
        <Typography variant="subtitle1" gutterBottom>
          Loading
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box textAlign="center" mt={4}>
        <MoreVertIcon fontSize="large" />
        <Typography variant="subtitle1" gutterBottom>
          Error
        </Typography>
      </Box>
    );
  }
};

export default CustomerDetails;
