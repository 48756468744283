import { forIn, isEmpty } from 'lodash';

import {
  toFloatNullOrUndefined,
  toIntOrUndefined,
  toIntNullOrUndefined,
} from 'utils';

export const processAccessoryOrDiscountData = ({
  __typename, // strip
  id,
  price,
  ...accessory
}) => ({
  id: toIntOrUndefined(id),
  price: toFloatNullOrUndefined(price),
  ...accessory,
});

export const processPaymentOption = ({
  __typename, // strip
  allowedMileage,
  amount,
  cashAmount,
  cashDown,
  dueAtDelivery,
  id,
  index, // strip
  price,
  rate,
  residualAmount,
  term,
  ...paymentOption
}) => ({
  allowedMileage: toIntNullOrUndefined(allowedMileage),
  amount: toFloatNullOrUndefined(amount, { round: 2 }),
  cashAmount: toFloatNullOrUndefined(cashAmount),
  cashDown: toFloatNullOrUndefined(cashDown),
  dueAtDelivery: toFloatNullOrUndefined(dueAtDelivery),
  id: toIntOrUndefined(id),
  price: toFloatNullOrUndefined(price),
  rate: toFloatNullOrUndefined(rate),
  residualAmount: toFloatNullOrUndefined(residualAmount),
  term: toIntOrUndefined(term),
  ...paymentOption,
});

export const processVehicleData = ({
  __typename, // strip
  accessories,
  dealerId,
  discounts,
  id,
  inventoryVehicleId,
  makeId, // strip
  modelId, // strip
  odometer,
  paymentOptions,
  regularPrice,
  salePrice,
  year,
  ...vehicle
}) => {
  const returnVal = {
    accessories: accessories?.map(processAccessoryOrDiscountData) ?? undefined,
    dealerId: toIntOrUndefined(dealerId),
    discounts: discounts?.map(processAccessoryOrDiscountData) ?? undefined,
    id: toIntOrUndefined(id),
    inventoryVehicleId: toIntOrUndefined(inventoryVehicleId),
    odometer: toIntNullOrUndefined(odometer),
    paymentOptions: paymentOptions?.map(processPaymentOption) ?? undefined,
    regularPrice: toFloatNullOrUndefined(regularPrice),
    year: toIntNullOrUndefined(year),
    ...vehicle,
  };
  if (salePrice && salePrice !== null && salePrice !== undefined)
    returnVal.salePrice = toFloatNullOrUndefined(salePrice);
  return returnVal;
};

export const processPitchData = ({
  __typename, // strip
  id, // strip
  appraisals, // strip
  vehicles,
  ...pitch
}) => ({
  vehicles: vehicles?.map(processVehicleData) ?? undefined,
  ...pitch,
});

// Takes a deeply nested object and flattens the keys to dot and bracket syntax
// in strings
// Note that nested arrays are not supported, since marshmallow validation will
// return nested objects with the array index as the object key
const flattenKeys = (_obj, keyAccum = null) => {
  const returnVal = {};
  forIn(_obj, (v, k) => {
    const newKey = keyAccum ? `${keyAccum}.${k}` : k;
    if (typeof v === 'object' && !Array.isArray(v))
      Object.assign(returnVal, flattenKeys(v, newKey));
    else Object.assign(returnVal, { [newKey]: v });
  });
  return returnVal;
};

const parseErrors = ({ graphQLErrors, networkError, message }) => {
  let returnErrors = {};
  if (!isEmpty(graphQLErrors))
    graphQLErrors.forEach(({ message: errorMessage, extensions }) => {
      if (extensions.response?.body?.errors?.json) {
        returnErrors = Object.assign(
          {},
          returnErrors,
          flattenKeys(extensions.response.body.errors.json),
        );
      } else if (extensions.response?.body) {
        const { code, status, message: bodyMessage } = extensions.response.body;
        returnErrors.error = [
          `${code}(${status}): ${bodyMessage ?? errorMessage}`,
        ];
      } else if (errorMessage) {
        returnErrors.error = [errorMessage];
      }
    });
  else if (!isEmpty(networkError))
    returnErrors.error = networkError.result?.errors.map(x => x.message);
  else if (!isEmpty(message)) returnErrors.error = [message];
  else returnErrors.error = ['An unknown error occured.'];
  return returnErrors;
};

export const errorHandler =
  (enqueueSnackbar, setError, options = {}) =>
  err => {
    const { error, ...rest } = parseErrors(err);
    if (options.snackAll) {
      // send all errors to snackbar, even "form" type errors
      enqueueSnackbar(`${error?.join('\n')} ${JSON.stringify(rest)}`, {
        variant: 'error',
      });
    } else if (error) {
      enqueueSnackbar(error.join('\n'), {
        variant: 'error',
      });
    }
    if (setError && !options.snackAll) {
      Object.entries(rest).forEach(([k, v]) =>
        setError(k, { message: v, type: 'APIValidation' }),
      );
      if (error) setError('error', { message: error.join(',') });
    }
  };

export const pitchStyles = {
  responsiveButton: theme => ({
    [theme.breakpoints.down('sm')]: { fontSize: '11px', whiteSpace: 'nowrap' },
  }),
  responsiveParagraph: theme => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }),
  roundedBlueIconButton: theme => ({
    backgroundColor: theme.actions.add.backgroundColor,
    fill: 'white',
    color: 'white',
    borderRadius: '4px',
    margin: theme.spacing(1),
  }),
  roundedRedIconButton: theme => ({
    backgroundColor: 'red',
    fill: 'white',
    color: 'white',
    borderRadius: '4px',
    margin: theme.spacing(1),
  }),
  stepBox: theme => ({
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(2),
    },
  }),
  sectionTitle: theme => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      marginBottom: theme.spacing(1),
      fontWeight: 'bold',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
      marginBottom: theme.spacing(2),
    },
  }),
  sectionSubTitle: theme => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: theme.spacing(0.75),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    fontWeight: 'bold',
  }),
  stepButtonsBox: theme => ({
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
    display: 'flex',
    margin: theme.spacing(1),
  }),
};

/**
 * For validating and scrubbing values used for currency
 *
 * Converts to a float (if not already a number), or returns 0 if not a number
 * @param {(number|string)} price
 * @returns {number}
 */
export const parsePrice = price =>
  (typeof price === 'number' ? price : parseFloat(price)) || 0;
