/* external */
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LoadingBackdrop } from 'components/MaterialUI';

import { capitalize } from 'utils';
import { errorHandler } from 'modules/pitches/utils';
import AddEditDefaultAccessoryDialog from './AddEditDefaultAccessoryDialog';

const DELETE_DEFAULT_ACCESSORY = gql`
  mutation deleteDefaultAccessory($dealerId: Int!, $accessoryId: Int!) {
    pitches {
      deleteDefaultAccessory(dealerId: $dealerId, accessoryId: $accessoryId) {
        defaultAccessories {
          id
          price
          title
          type
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  addButton: {
    color: 'white',
    backgroundColor: theme.actions.confirm.backgroundColor,
  },
  editButton: {
    color: 'white',
    backgroundColor: theme.colours.add,
    marginRight: '10px',
  },
  deleteButton: {
    color: 'white',
    backgroundColor: 'red',
  },
}));

const DefaultAccessories = ({
  dealerId,
  dealerSettingsData,
  loading,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [selectedAccessory, setSelectedAccessory] = useState({});

  const classes = useStyles();

  const [deleteDefaultAccessory, { loading: deleteLoading }] = useMutation(
    DELETE_DEFAULT_ACCESSORY,
    {
      onCompleted: () => {
        enqueueSnackbar('Default item deleted successfully!', {
          variant: 'success',
        });
        refetch();
      },
      onError: errorHandler(enqueueSnackbar, null),
    },
  );

  const dealerSettings = dealerSettingsData?.pitches?.dealerSettings ?? {};
  const defaultAccessories = dealerSettings?.defaultAccessories ?? [];

  const TABLE_HEADERS = ['Type', 'Title', 'Price', 'Actions'];

  const onDelete = accessoryId => {
    deleteDefaultAccessory({
      variables: {
        dealerId,
        accessoryId,
      },
    });
  };

  return (
    <Box height="100%">
      <LoadingBackdrop open={loading || deleteLoading}>
        Loading...
      </LoadingBackdrop>
      <AddEditDefaultAccessoryDialog
        dealerId={dealerId}
        dealerSettings={dealerSettings}
        selectedAccessory={selectedAccessory}
        open={openAddEditDialog}
        onClose={() => {
          setSelectedAccessory({});
          setOpenAddEditDialog(false);
        }}
        refetch={refetch}
      />
      <IconButton
        onClick={() => setOpenAddEditDialog(true)}
        size="large"
        className={classes.addButton}
      >
        <AddIcon />
      </IconButton>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEADERS.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {defaultAccessories.length === 0 && (
              <TableRow>
                <TableCell colSpan={TABLE_HEADERS.length} align="center">
                  No default accessories found
                </TableCell>
              </TableRow>
            )}
            {defaultAccessories.length > 0 &&
              defaultAccessories.map(accessory => (
                <TableRow key={accessory.id}>
                  <TableCell>{capitalize(accessory.type)}</TableCell>
                  <TableCell>{accessory.title}</TableCell>
                  <TableCell>{accessory.price}</TableCell>
                  <TableCell>
                    <IconButton
                      className={classes.editButton}
                      onClick={() => {
                        setSelectedAccessory(accessory);
                        setOpenAddEditDialog(true);
                      }}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className={classes.deleteButton}
                      size="small"
                      onClick={() => onDelete(accessory.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

DefaultAccessories.fragments = {
  dealerSettings: gql`
    fragment DefaultAccessoriesDealerSettings on PitchDealerSetting {
      id
      defaultAccessories {
        id
        price
        title
        type
        dealerSettingId
      }
    }
  `,
};

export default DefaultAccessories;
