import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

// Material UI
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { green } from '@mui/material/colors';

import {
  getModelLineupYears,
  resetDependentFields,
  cleanUpdateVehiclePayload,
} from '../utils';
import { categoryOrder } from '../constants';

import {
  GET_MAKES,
  GET_MODELS,
  GET_TRIMS,
  GET_STYLE_ID_AND_NAME,
} from '../graphql/queries';
import { ADD_VEHICLE, UPDATE_VEHICLE } from '../graphql/mutations';

const VehicleShowroomForm = ({
  editVehicle = null,
  showroomsNamesData,
  showroomsNamesDataLoading,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [isShowroomSelectDisabled, setIsShowroomSelectDisabled] =
    useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      showroom: '',
      category: '',
      year: '',
      make: '',
      model: '',
      trim: '',
      freightPdi: '',
      styleId: '',
    },
  });

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current && editVehicle) {
      reset({
        showroom: Number(editVehicle.showroomId) || '',
        category: Number(editVehicle.category?.id) || 'placeholder',
        year: editVehicle.year || '',
        make: '',
        model: '',
        trim: '',
        freightPdi: editVehicle.freightPdi || '',
        styleId: editVehicle.styleId || '',
      });
      isFirstRender.current = false; // Prevent subsequent resets
    }
  }, [editVehicle, reset]);

  const watchedYear = watch('year');
  const watchedMake = watch('make');
  const watchedModel = watch('model');
  const watchedTrim = watch('trim');
  const watchedStyleId = watch('styleId');

  const [addVehicleToShowroom] = useMutation(ADD_VEHICLE);
  const [updateVehicleToShowroom] = useMutation(UPDATE_VEHICLE);

  const onSubmit = data => {
    const payload = {
      showroomId: parseInt(data.showroom, 10),
      categoryId: parseInt(data.categoryId, 10),
      year: parseInt(data.year, 10),
      make: data.make,
      model: data.model,
      styleId: parseInt(data.styleId, 10),
    };

    if (data.freightPdi !== '' && !Number.isNaN(data.freightPdi)) {
      payload.freightPdi = parseInt(data.freightPdi);
    }

    if (editVehicle) {
      const cleanUpdatePayload = cleanUpdateVehiclePayload(payload);
      updateVehicleToShowroom({
        variables: {
          updateVehicleToShowroomId: editVehicle.id,
          input: cleanUpdatePayload,
        },
      })
        .then(() => {
          enqueueSnackbar('Vehicle updated successfully', {
            variant: 'success',
          });
          history.push('/vehicle-showroom/view');
        })
        .catch(() => {
          enqueueSnackbar('Failed to update vehicle', {
            variant: 'error',
          });
        });
    } else {
      addVehicleToShowroom({ variables: payload })
        .then(() => {
          enqueueSnackbar('Vehicle added successfully', {
            variant: 'success',
          });
          history.push('/vehicle-showroom/view');
        })
        .catch(() => {
          enqueueSnackbar('Failed to add vehicle', {
            variant: 'error',
          });
        });
    }
  };

  const handleCancel = () => {
    reset(); // Reset the form to its default values
    history.push('/vehicle-showroom/view');
  };

  const [getMakes, { data: makesData, loading: makesDataLoading }] =
    useLazyQuery(GET_MAKES);
  const [getModels, { data: modelsData, loading: modelsDataLoading }] =
    useLazyQuery(GET_MODELS);
  const [getTrims, { data: trimsData, loading: trimsDataLoading }] =
    useLazyQuery(GET_TRIMS);
  const [getStyleId, { data: styleIdData }] = useLazyQuery(
    GET_STYLE_ID_AND_NAME,
  );

  useEffect(() => {
    if (!isDirty && showroomsNamesData?.showroom?.showrooms?.length === 1) {
      setValue('showroom', showroomsNamesData?.showroom?.showrooms[0]?.id);
      setIsShowroomSelectDisabled(true);
    } else if (!isDirty && !editVehicle) {
      setValue('showroom', '');
      setIsShowroomSelectDisabled(false);
    }
  }, [showroomsNamesData, setValue, isDirty, editVehicle]);

  // Fetch makes when year changes
  useEffect(() => {
    if (watchedYear) {
      getMakes({ variables: { year: parseInt(watchedYear, 10) } });
      resetDependentFields(['make', 'model', 'trim', 'styleId'], setValue);
    }
  }, [watchedYear, getMakes, setValue]);

  // Fetch models when make changes
  useEffect(() => {
    if (watchedMake && watchedYear) {
      const makeId = makesData?.inventory?.getMakesDrilldown?.find(
        make => make.name === watchedMake,
      )?.id;
      if (makeId) {
        getModels({ variables: { makeId, year: parseInt(watchedYear, 10) } });
        resetDependentFields(['model', 'trim', 'styleId'], setValue);
      }
    }
  }, [watchedMake, watchedYear, makesData, getModels, setValue]);

  // Fetch trims when model changes
  useEffect(() => {
    if (watchedModel && watchedMake && watchedYear) {
      getTrims({
        variables: {
          makeName: watchedMake,
          modelName: watchedModel,
          year: parseInt(watchedYear, 10),
        },
      });
      resetDependentFields(['trim', 'styleId'], setValue);
    }
  }, [watchedModel, watchedMake, watchedYear, getTrims, setValue]);

  // Fetch style ID when trim changes
  useEffect(() => {
    if (watchedTrim && watchedModel && watchedMake && watchedYear) {
      getStyleId({
        variables: {
          makeName: watchedMake,
          modelName: watchedModel,
          year: parseInt(watchedYear, 10),
          trim: watchedTrim,
        },
      });
      resetDependentFields(['styleId'], setValue);
    }
  }, [
    watchedTrim,
    watchedModel,
    watchedMake,
    watchedYear,
    getStyleId,
    setValue,
  ]);

  // Pre-fill the style ID field with fetched data
  useEffect(() => {
    if (styleIdData?.inventory?.getStyleIdAndName?.style_id) {
      setValue('styleId', styleIdData.inventory.getStyleIdAndName.style_id);
    } else {
      setValue('styleId', '');
    }
  }, [styleIdData, setValue]);

  return (
    <Box component="section" m={4} sx={{ maxWidth: 1200 }}>
      <Paper elevation={4} p={2}>
        <Typography variant="h6" component="h2" p={2}>
          <strong>{editVehicle ? 'Edit Vehicle' : 'Add Vehicle'}</strong>
        </Typography>
        <Divider
          variant="middle"
          sx={{
            margin: '0 1rem',
            border: '1px solid black',
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            sx={{
              padding: '1rem',
            }}
          >
            {/* Showroom */}
            <Grid item xs={12} md={4}>
              <Controller
                name="showroom"
                control={control}
                rules={{ required: 'Showroom is required' }}
                defaultValue={Number(editVehicle?.showroomId) || ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Showroom"
                    variant="outlined"
                    fullWidth
                    error={!!errors.showroom}
                    helperText={errors.showroom ? errors.showroom.message : ''}
                    disabled={
                      showroomsNamesDataLoading || isShowroomSelectDisabled
                    }
                  >
                    <MenuItem value="" disabled>
                      <em>Choose a showroom</em>
                    </MenuItem>
                    {showroomsNamesData?.showroom?.showrooms?.map(showroom => (
                      <MenuItem key={showroom.id} value={showroom.id}>
                        {showroom.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="categoryId"
                control={control}
                rules={{
                  required: 'Category is required',
                  validate: value =>
                    value !== 'placeholder' || 'Please select a valid category',
                }}
                defaultValue={
                  Number(editVehicle?.category?.id) || 'placeholder'
                }
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Category"
                    variant="outlined"
                    fullWidth
                    error={!!errors.categoryId}
                    helperText={
                      errors.categoryId ? errors.categoryId.message : ''
                    }
                  >
                    <MenuItem value="placeholder" disabled>
                      <em>Choose a category</em>
                    </MenuItem>
                    {categoryOrder.map(category => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {/* Year */}
            <Grid item xs={12} md={4}>
              <Controller
                name="year"
                control={control}
                rules={{
                  required: 'Year is required',
                  validate: value =>
                    getModelLineupYears().includes(parseInt(value, 10)) ||
                    'Please select a valid year',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Year"
                    variant="outlined"
                    fullWidth
                    error={!!errors.year}
                    helperText={errors.year ? errors.year.message : ''}
                  >
                    <MenuItem value="" disabled>
                      <em>Choose a year</em>
                    </MenuItem>
                    {getModelLineupYears().map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {/* Make */}
            <Grid item xs={12} md={4}>
              <Controller
                name="make"
                control={control}
                rules={{
                  required: 'Make is required',
                  validate: value =>
                    value !== 'placeholder' || 'Please select a valid make',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Make"
                    variant="outlined"
                    fullWidth
                    error={!!errors.make}
                    helperText={errors.make ? errors.make.message : ''}
                    disabled={!watchedYear || makesDataLoading}
                  >
                    <MenuItem value="placeholder" disabled>
                      <em>Choose a make</em>
                    </MenuItem>
                    {makesData?.inventory?.getMakesDrilldown?.map(make => (
                      <MenuItem key={make.name} value={make.name}>
                        {make.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {makesData?.inventory?.getMakesDrilldown?.length === 0 && (
                <Typography color="error">
                  No makes available for this year.
                </Typography>
              )}
            </Grid>
            {/* Model */}
            <Grid item xs={12} md={4}>
              <Controller
                name="model"
                control={control}
                rules={{
                  required: 'Model is required',
                  validate: value =>
                    value !== 'placeholder' || 'Please select a valid model',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Model"
                    variant="outlined"
                    fullWidth
                    error={!!errors.model}
                    helperText={errors.model ? errors.model.message : ''}
                    disabled={!watchedMake || !watchedYear || modelsDataLoading}
                  >
                    <MenuItem value="placeholder" disabled>
                      <em>Choose a model</em>
                    </MenuItem>
                    {modelsData?.inventory?.getModelsDrilldown?.map(model => (
                      <MenuItem key={model.name} value={model.name}>
                        {model.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {modelsData?.inventory?.getModelsDrilldown?.length === 0 && (
                <Typography color="error">
                  No models available for this make and year.
                </Typography>
              )}
            </Grid>
            {/* Trim */}
            <Grid item xs={12} md={4}>
              <Controller
                name="trim"
                control={control}
                rules={{
                  required: 'Trim is required',
                  validate: value =>
                    value !== 'placeholder' || 'Please select a valid trim',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label={trimsDataLoading ? 'Loading trims...' : 'Trim'}
                    variant="outlined"
                    fullWidth
                    error={!!errors.trim}
                    helperText={errors.trim ? errors.trim.message : ''}
                    disabled={
                      !watchedModel ||
                      !watchedMake ||
                      !watchedYear ||
                      trimsDataLoading
                    }
                  >
                    <MenuItem value="placeholder" disabled>
                      <em>Choose a trim</em>
                    </MenuItem>
                    {(trimsData?.inventory?.getTrimsDrilldown &&
                      Array.from(
                        new Set(trimsData?.inventory?.getTrimsDrilldown),
                      ).map(trim => (
                        <MenuItem key={trim} value={trim}>
                          {trim}
                        </MenuItem>
                      ))) ||
                      []}
                  </TextField>
                )}
              />
              {trimsData?.inventory?.getTrimsDrilldown?.length === 0 && (
                <Typography color="error">
                  No trims available for this make, model, and year.
                </Typography>
              )}
            </Grid>
            {/* Freight PDI */}
            <Grid item xs={12} md={4}>
              <Controller
                name="freightPdi"
                control={control}
                rules={{
                  pattern: {
                    value: /^[^eE]+$/,
                    message: 'Freight PDI cannot contain the letter "e"',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Freight PDI"
                    id="freight-select"
                    type="number"
                    variant="outlined"
                    sx={{ width: 300 }}
                  />
                )}
              />
              {errors.freightPdi && (
                <Typography color="error">
                  {errors.freightPdi.message}
                </Typography>
              )}
            </Grid>
            {/* Style ID */}
            <Grid item xs={12} md={4}>
              <Controller
                name="styleId"
                control={control}
                rules={{ required: 'Style ID is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="style-id-select"
                    label={
                      <>
                        Style ID{' '}
                        <Tooltip title="This field is automatically filled based on the selected year, make, model, and trim.">
                          <InfoIcon
                            color="info"
                            style={{
                              color:
                                watchedStyleId !== ''
                                  ? green[500]
                                  : theme.actions.info,
                              fontSize: '0.75rem',
                            }}
                          />
                        </Tooltip>
                      </>
                    }
                    defaultValue=""
                    variant="outlined"
                    sx={{ width: 300 }}
                    value={field.value || ''}
                    disabled
                  />
                )}
              />
              {errors.styleId && (
                <Typography color="error">{errors.styleId.message}</Typography>
              )}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            {isDirty && !editVehicle && (
              <Button
                variant="outlined"
                onClick={() => reset()}
                sx={{ marginRight: 2 }}
              >
                Reset
              </Button>
            )}
            <Button
              disabled={!isDirty || Object.keys(errors).length > 0}
              sx={{
                backgroundColor:
                  !isDirty || Object.keys(errors).length > 0
                    ? theme.actions.disabled
                    : theme.actions.confirm,
                '&:hover': {
                  backgroundColor:
                    !isDirty || Object.keys(errors).length > 0
                      ? theme.actions.disabled
                      : green[500],
                },
              }}
              type="submit"
              variant="contained"
            >
              {editVehicle ? 'Update' : 'Add'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default VehicleShowroomForm;

VehicleShowroomForm.propTypes = {
  // required
  showroomsNamesData: PropTypes.shape({
    showroom: PropTypes.shape({
      showrooms: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
  showroomsNamesDataLoading: PropTypes.bool.isRequired,
  // optional
  editVehicle: PropTypes.shape({
    vehicleId: PropTypes.number,
    showroomId: PropTypes.number,
    showroomName: PropTypes.string,
    category: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    year: PropTypes.number,
    make: PropTypes.string,
    model: PropTypes.string,
    style: PropTypes.shape({
      trim: PropTypes.string,
    }),
    freightPdi: PropTypes.number,
    styleId: PropTypes.number,
  }),
};

VehicleShowroomForm.defaultProps = {
  editVehicle: null,
};
