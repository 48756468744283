import React, { useEffect, useState } from 'react';

/* external */
import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { NetworkStatus } from 'apollo-boost';
import { Waypoint } from 'react-waypoint';

/* Material UI */
import { makeStyles } from '@mui/styles';
import {
  Box,
  Drawer,
  Fab,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { useMediaQuery } from '@mui/material';

/* internal */
import { defaultTZ } from 'utils';
import { filtersFromFacets } from '../utils';
import { OfferStatus } from 'modules/used_vehicles/const';
import { RoleGroup, Role } from 'constants.js';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { usePersistedState } from 'utils';
import { useUserContext } from 'components/MaterialUI/UserContext';
import DashboardCard from '../components/DashboardCard';
import EmptyMessage from '../components/EmptyMessage';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import FacetChips from '../components/FacetChips';
import FilterDropDown from './FilterDropDown';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import Title from '../components/Title';

const useStyles = makeStyles(theme => ({
  roomForFABs: {
    paddingBottom: `${48 + 10 + 10}px`,
  },
  FABs: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
  },
}));

const PAGE_SIZE = 20;

const queryFacets = [
  {
    model: 'Appraisal',
    field: 'year',
  },
  {
    model: 'Appraisal',
    field: 'isPaveSessionComplete',
  },
  {
    model: 'Appraisal',
    field: 'make',
  },
  {
    model: 'Appraisal',
    field: 'model',
  },
  {
    model: 'Appraisal',
    field: 'bodyType',
  },
  {
    model: 'Offer',
    field: 'facetStatus',
  },
];

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(
      dealer_ids: $dealerIds
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
        "${Role.GENERAL_MANAGER}"
      ]
    ) {
      display_name
      username
    }
  }
`;

const RECIPIENT_FRAGMENT = gql`
  fragment RecipientFragment on AppraisalNotificationsRecipient {
    id
    username
    user {
      display_name
    }
  }
`;

const RECIPIENTS_QUERY = gql`
  query RecipientsQuery(
    $filters: [QueryFilter]
    $sort: [QuerySortElement]
    $page: Int
    $pageSize: Int
  ) {
    appraisals {
      notificationsRecipients(
        filters: $filters
        sort: $sort
        page: $page
        pageSize: $pageSize
      ) {
        pagination {
          page
          pageSize
          total
        }
        results {
          id
          ...RecipientFragment
        }
      }
    }
  }
  ${RECIPIENT_FRAGMENT}
`;

const OFFERS_QUERY = gql`
  query OffersQuery(
    $filters: [QueryFilter]
    $page: Int!
    $queryFacets: [QueryFacet]!
    $sort: [QuerySortElement]
  ){
    appraisals {
      offers(filters: $filters, page: $page, pageSize: ${PAGE_SIZE}, sort: $sort) {
        results {
          id
          appraisal {
            id
            make
            model
            offeredToDealerId
            purchasingDealerId
            ...DashboardCardAppraisal
          }
          ...DashboardCardOffer
        }
        pagination {
          nextPage
          total
        }
      }
      offersFacets(filters: $filters, facets: $queryFacets)
      {
        data {
          count
          value
        }
        field
        model
      }
    }
  }
  ${DashboardCard.fragments.appraisal}
  ${DashboardCard.fragments.offer}
`;

const OFFERS_SUBSCRIPTION_FEED = gql`
  subscription offerFeed {
    offerFeed {
      type
      offer {
        id
        appraisal {
          id
          ...DashboardCardAppraisal
        }
        ...DashboardCardOffer
      }
    }
  }
  ${DashboardCard.fragments.appraisal}
  ${DashboardCard.fragments.offer}
`;

const DealerDashboard = () => {
  const classes = useStyles();
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const desktop = useMediaQuery(data => data.breakpoints.up('sm'));
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;
  const role = currentUser?.role;
  const [filterFacets, setFilterFacets] = usePersistedState('usedFilters', []);
  const [recently, setRecently] = useState();
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState('');

  // Only sets recently on first render (see WholesaleDashboard.jsx for further info)
  useEffect(
    () =>
      setRecently(
        moment().tz(timezone).utc().subtract(10, 'days').toISOString(),
      ),
    [setRecently, timezone],
  );

  const { data: usersForDealer, loading: usersLoading } = useQuery(
    USERS_FOR_DEALER,
    {
      variables: {
        dealerIds: [dealerId],
      },
    },
  );

  const recipientsFilters = [
    {
      model: 'NotificationsRecipient',
      field: 'dealerId',
      op: 'eq',
      value: dealerId,
    },
  ];

  const recipientsQuery = useQuery(RECIPIENTS_QUERY, {
    variables: {
      filters: recipientsFilters,
      page: 1,
      pageSize: 100, // For simplicity, going to make this a hard limit in the back end (per dealer)
    },
  });

  const users = usersForDealer?.users || [];
  const recipients =
    recipientsQuery.data?.appraisals.notificationsRecipients.results;
  const recipientUsernames = recipients?.map(({ username }) => username);

  const canClaimOrAssignOffer =
    recipientUsernames?.includes(currentUser.username) ||
    RoleGroup.MANAGERS.includes(currentUser.role);

  /*
    year: exact match,
    make: starts with keyword,
    model: starts with keyword,
    vin: contains keyword
  */
  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Appraisal',
            field: 'make',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'model',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'vin',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
        ],
      })),
    );

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notOrRecentlyDelivered = [
    {
      or: [
        {
          model: 'Appraisal',
          field: 'deliveredAt',
          op: '>=',
          value: recently,
        },
        {
          model: 'Appraisal',
          field: 'deliveredAt',
          op: 'is_null',
        },
      ],
    },
  ];

  const myOffersFilters = [
    {
      model: 'Offer',
      field: 'dealerId',
      op: '==',
      value: dealerId,
    },
    {
      model: 'Offer',
      field: 'offerStatus',
      op: 'in',
      value: [
        OfferStatus.ACCEPTED,
        OfferStatus.OFFERED,
        OfferStatus.AUCTIONED,
        OfferStatus.CUSTOMER_OFFERED,
      ],
    },
  ];

  const customerAppraisalFilters = [
    {
      model: 'Appraisal',
      field: 'isCustomerAppraisal',
      op: '==',
      value: true,
    },
  ];

  let filters = [
    ...myOffersFilters,
    ...filtersFromFacets(filterFacets),
    ...searchFilters,
  ];
  if (recently) {
    filters = filters.concat(notOrRecentlyDelivered);
  }

  if ([Role.SALES_REP, Role.INTERNET_SALES_REP].includes(role))
    filters = filters.concat(customerAppraisalFilters);

  const sort = [{ model: 'Appraisal', field: 'modifiedAt', direction: 'desc' }];

  const { data, error, loading, fetchMore, networkStatus, subscribeToMore } =
    useQuery(OFFERS_QUERY, {
      variables: {
        filters,
        page: 1,
        queryFacets,
        sort,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: !recently || !role,
    });

  const offers = data?.appraisals.offers.results || [];
  const offersFacets = data?.appraisals.offersFacets || [];
  const { nextPage, total } = data?.appraisals.offers.pagination || {};

  useEffect(() => {
    subscribeToMore({
      document: OFFERS_SUBSCRIPTION_FEED,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const { type, offer } = subscriptionData.data.offerFeed;
        if (type === 'created') {
          return Object.assign({}, prev, {
            appraisals: {
              __typename: prev.appraisals.__typename,
              offers: {
                __typename: prev.appraisals.offers.__typename,
                results: [offer, ...prev.appraisals.offers.results],
                pagination: prev.appraisals.offers.pagination,
              },
              offersFacets: prev.appraisals.offersFacets,
            },
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreHandler = () => {
    if (nextPage)
      fetchMore({
        variables: { page: nextPage },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.appraisals.offers.results) return prev;

          return Object.assign({}, prev, {
            appraisals: {
              __typename: prev.appraisals.__typename,
              offers: {
                __typename: prev.appraisals.offers.__typename,
                results: [
                  ...prev.appraisals.offers.results,
                  ...more.appraisals.offers.results,
                ],
                pagination: more.appraisals.offers.pagination,
              },
              offersFacets: more.appraisals.offersFacets, // just replace facets
            },
          });
        },
      });
  };

  const handleDeleteFacet = (_model, _field, _value) =>
    setFilterFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  if (error) return <ErrorDisplay error={error} />;

  if (loading && networkStatus !== NetworkStatus.fetchMore)
    return <LoadingBackdrop open={loading} />;

  return (
    <Box
      className={classes.roomForFABs}
      marginLeft={1}
      marginTop={1}
      marginRight={1}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Title title="Used Vehicles" paddingBottom={1} />
        <Box paddingLeft={1}></Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        paddingBottom="0.5rem"
        alignItems="center"
      >
        {desktop && (
          <Box>
            <Fab
              size="medium"
              color="primary"
              onClick={() => setShowFilterDrawer(true)}
            >
              <FilterListIcon />
            </Fab>
          </Box>
        )}
        {!isEmpty(filterFacets) && (
          <Box marginLeft={desktop && '12px'}>
            <FacetChips
              facets={filterFacets}
              onDeleteFacet={handleDeleteFacet}
            />
          </Box>
        )}
        <Box flexGrow={1} />
        <Box display="flex" flexDirection="column">
          <Box>
            Showing {offers.length} of {total}
          </Box>
          <Box>
            <TextField
              placeholder="Search ..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!searchKeywords}
                      onClick={() => {
                        setSearchKeywords('');
                        setSearchFilters([]);
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={searchKeywords}
              onChange={e => setSearchKeywords(e.target.value)}
              style={{ paddingRight: '5px', textAlign: 'right' }}
              onKeyPress={handleSearch}
            />
          </Box>
        </Box>
      </Box>

      {isEmpty(offers) && <EmptyMessage />}

      {offers.map(offer => (
        <DashboardCard
          appraisal={offer.appraisal}
          offer={offer}
          key={offer.id}
          users={users}
          usersLoading={usersLoading}
          canClaimOrAssignOffer={canClaimOrAssignOffer}
          dealer
        />
      ))}
      <Waypoint onEnter={fetchMoreHandler} />
      {networkStatus === NetworkStatus.fetchMore && (
        <Box>Loading more offers...</Box>
      )}
      <Drawer
        anchor="left"
        open={showFilterDrawer}
        onClose={() => setShowFilterDrawer(false)}
      >
        <Box display="flex">
          <IconButton
            style={{ marginLeft: 'auto', height: '50px', zIndex: '1000' }}
            onClick={() => setShowFilterDrawer(false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <FilterDropDown
          facetResults={offersFacets}
          selectedFacets={filterFacets}
          setSelectedFacets={setFilterFacets}
        />
      </Drawer>
      {!desktop && (
        <Box className={classes.FABs}>
          <Fab
            size="medium"
            color="primary"
            onClick={() => setShowFilterDrawer(true)}
          >
            <FilterListIcon />
          </Fab>
        </Box>
      )}
    </Box>
  );
};

export default DealerDashboard;
