/* external */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

/* Material UI */
import {
  Card,
  CardContent,
  Step,
  Stepper,
  StepButton,
  StepContent,
  StepLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

/* internal */
import { Loading, useDealerContext } from 'components/MaterialUI';
import PaymentStep from './PaymentStep';
import TradeInStep from './TradeInStep';
import VehicleDetailsStep from './VehicleDetailsStep';

const STEPS = [VehicleDetailsStep, TradeInStep, PaymentStep];

const DEFAULT_ACCESSORIES_QUERY = gql`
  query defaultAccessories($dealerId: Int!) {
    pitches {
      dealerSettings(dealerId: $dealerId) {
        id
        defaultAccessories {
          id
          price
          title
          type
          dealerSettingId
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  finishButton: {
    ...theme.actions.confirm,
    margin: theme.spacing(1),
    '& disabled': theme.actions.disabled,
  },
  nextButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.actions.add.backgroundColor,
    color: 'white',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
  cardContent: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingRight: theme.spacing(1),
    },
  },
}));

const useStepperStyles = makeStyles(theme => ({
  root: {
    '& .MuiStepIcon-root.Mui-active': {
      color: theme.actions.add.backgroundColor,
    },
    '& .MuiStepIcon-root.Mui-completed': {
      color: theme.actions.add.backgroundColor,
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const PitchForm = ({
  customerVehicles,
  pitch,
  onCancel,
  pitchEditState,
  setPitchEditState,
  refetch,
  opportunity,
}) => {
  /* hooks */
  const { dealerId } = useDealerContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const stepperClasses = useStepperStyles(theme);
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const [editAppraisal, setEditAppraisal] = useState(null);

  const [stepCompleted, setStepCompleted] = useState(
    pitch?.id ? { 0: true, 2: true, 1: pitch.appraisals?.length > 0 } : {},
  );
  const [showTradeIn, setShowTradeIn] = useState(false);

  const { loading, data } = useQuery(DEFAULT_ACCESSORIES_QUERY, {
    variables: { dealerId },
  });

  const defaults = data?.pitches?.dealerSettings?.defaultAccessories ?? [];
  const defaultAccessories = defaults.filter(x => x.type === 'accessory');
  const defaultDiscounts = defaults.filter(x => x.type === 'discount');

  const CurrentStep = STEPS[pitchEditState.pitchStep];

  const currentStepProps = {
    // A user could finish step 1 or two (which creates the pitch), then
    // click cancel on step two or three.  So we need to refetch the opp
    // in order to display the partially-completed pitch.
    onCancel: () => refetch().then(() => onCancel()),
    onCreate: (editPitchId, options = {}) => {
      snackIt('Pitch created successfully!', {
        variant: 'success',
      });
      setPitchEditState(p => ({
        ...p,
        editPitchId,
        pitchStep: options.noNext ? 0 : 1,
      }));
    },
    onFinish: () =>
      refetch().then(() =>
        setPitchEditState({
          pitchStep: 0,
          editPitchId: null,
          showPitchForm: false,
        }),
      ),
    onUpdate: () =>
      snackIt('Pitch updated successfully!', {
        variant: 'success',
      }),
    onNext: () => {
      setStepCompleted(prev => ({
        ...prev,
        [pitchEditState.pitchStep]: true,
      }));
      setPitchEditState(({ pitchStep, ...rest }) => ({
        pitchStep: pitchStep < STEPS.length - 1 ? pitchStep + 1 : pitchStep,
        ...rest,
      }));
    },
    onSkip: () =>
      setPitchEditState(({ pitchStep, ...rest }) => ({
        pitchStep: pitchStep < STEPS.length - 1 ? pitchStep + 1 : pitchStep,
        ...rest,
      })),
    onPrevious: () =>
      setPitchEditState(({ pitchStep, ...rest }) => ({
        pitchStep: pitchStep > 0 ? pitchStep - 1 : pitchStep,
        ...rest,
      })),
    pitch,
    setShowTradeIn,
    showTradeIn,
    customerVehicles,
    opportunity,
    editAppraisal,
    setEditAppraisal,
    defaultAccessories,
    defaultDiscounts,
  };

  if (loading) return <Loading />;

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        {showTradeIn ? 'Trade In' : 'Pitch a Vehicle'}
      </Typography>
      <Card>
        <CardContent className={classes.cardContent}>
          {!showTradeIn && (
            <Stepper
              activeStep={pitchEditState.pitchStep}
              classes={stepperClasses}
              orientation={mobile ? 'vertical' : 'horizontal'}
            >
              {STEPS.map(({ label, optional }, index) => (
                <Step key={label} completed={stepCompleted[index]}>
                  <StepButton
                    disabled={!pitch?.id}
                    onClick={() =>
                      setPitchEditState(prev => ({ ...prev, pitchStep: index }))
                    }
                  >
                    <StepLabel
                      optional={
                        optional ? (
                          <Typography variant="caption">Optional</Typography>
                        ) : null
                      }
                    >
                      {label}
                    </StepLabel>
                  </StepButton>
                  {mobile && (
                    <StepContent style={{ padding: 0 }}>
                      <CurrentStep {...currentStepProps} />
                    </StepContent>
                  )}
                </Step>
              ))}
            </Stepper>
          )}
          {(!mobile || showTradeIn) && <CurrentStep {...currentStepProps} />}
        </CardContent>
      </Card>
    </>
  );
};

PitchForm.fragments = {
  pitch: gql`
    fragment PitchFormPitch on Pitch {
      id
      appraisals {
        id
      }
      ...VehicleDetailsStepPitch
      ...PaymentStepPitch
      ...TradeInStepPitch
    }
    ${VehicleDetailsStep.fragments.pitch}
    ${PaymentStep.fragments.pitch}
    ${TradeInStep.fragments.pitch}
  `,
  opportunity: gql`
    fragment PitchFormOpportunity on Opportunity {
      ...TradeInStepOpportunity
      ...VehicleDetailsStepOpportunity
    }
    ${TradeInStep.fragments.opportunity}
    ${VehicleDetailsStep.fragments.opportunity}
  `,
};
export default PitchForm;
