/* external */
import React from 'react';

/* Material UI */
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { pitchStyles, parsePrice } from '../../utils';
import AccessoryLineItem from './AccessoryLineItem';
import PriceTotal from './PriceTotal';

const useStyles = makeStyles(theme => ({
  addButton: {
    color: theme.actions.create.color,
  },
  sectionSubTitle: pitchStyles.sectionSubTitle(theme),
}));

const AccessoryGroupBox = ({ itemGroup, itemGroupFields, type }) => {
  const classes = useStyles();

  const calculateTotal = fields =>
    fields.reduce((total, { price }) => total + parsePrice(price), 0);

  const handleClickAdd = () =>
    itemGroup.append({
      title: '',
      price: '',
    });

  const typeTitle = type === 'accessories' ? 'Accessories' : 'Discounts';

  return (
    <>
      <Box className={classes.sectionSubTitle}>
        {typeTitle}
        <IconButton onClick={handleClickAdd} size="large">
          <AddBoxIcon className={classes.addButton} />
        </IconButton>
      </Box>
      {itemGroup.fields.map((item, index) => (
        <AccessoryLineItem
          type={type}
          removeHandler={itemGroup.remove}
          item={item}
          index={index}
          key={item.key}
        />
      ))}
      <PriceTotal type={typeTitle} total={calculateTotal(itemGroupFields)} />
    </>
  );
};

export default AccessoryGroupBox;
