import React from 'react';

/* external */
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

/* internal */
import app from '../../app';
import VehicleShowroomListView from './VehicleShowroomListView/VehicleShowroomListView';
import AddShowroomVehicle from './VehicleDetails/AddShowroomVehicle';
import EditShowroomVehicle from './VehicleDetails/EditShowroomVehicle';

const VehicleShowroom = ({ match }) => (
  <app.components.Layout title="Vehicle Showroom">
    <Switch>
      <Route
        exact
        path={`${match.url}/view`}
        component={VehicleShowroomListView}
      />
      <Route
        exact
        path={`${match.url}/add-vehicle`}
        component={AddShowroomVehicle}
      />
      <Route
        exact
        path={`${match.url}/:id(\\d+)`}
        render={({ match: routeMatch }) => (
          <EditShowroomVehicle vehicleId={parseInt(routeMatch.params.id, 10)} />
        )}
      />
    </Switch>
  </app.components.Layout>
);

export default VehicleShowroom;

VehicleShowroom.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
