/* external */
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Waypoint } from 'react-waypoint';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { usePersistedState } from 'utils';

import OpportunitiesBulkAction from './OpportunitiesBulkAction';
import OpportunitiesChips from './OpportunitiesChips';
import OpportunitiesFilters from './OpportunitiesFilters';
import OpportunitiesList from './OpportunitiesList';
import OpportunitiesListMobile from './OpportunitiesListMobile';
import OpportunitiesSearchBar from './OpportunitiesSearchBar';
import { Role, Status } from 'constants.js';
import { sessionKeys } from '../constants';

const DEALER_QUERY = gql`
  query dealer($dealer_id: Int!) {
    dealer(dealer_id: $dealer_id) {
      dealer_name
      dealer_id
    }
  }
`;

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerId: Int!) {
    users: users(
      dealer_ids: [$dealerId]
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
        "${Role.BDC_REP}"
        "${Role.FINANCE_MANAGER}"
      ]
    ) {
      display_name
      username
      role
    }
  }
`;

export const opportunityFragment = gql`
  fragment OpportunityFields on Opportunity {
    _id
    appraisals {
      id
    }
    dealer {
      dealer_name
      dealer_id
    }
    bdc_reps {
      display_name
      username
    }
    customer_reps {
      display_name
      username
    }
    finance_managers {
      display_name
      username
    }
    sales_managers {
      display_name
      username
    }
    sales_reps {
      display_name
      username
    }
    credit_application_ids
    customer {
      _id
      fullname
      identities {
        identity_type
        identity_value
      }
    }
    appointments(start_date_until: "${moment().add(30, 'days').format()}") {
      start_date
      status
    }
    tasks(statuses: [0], due_until: "${moment().add(30, 'days').format()}") {
      _id
      status_name
    }
    status
    status_display
    lost_reason
    last_status_change
    sub_status
    created
    primary_new_pitch_id
    pitches {
      id
      vehicles {
        make
        model
        year
        vehicleName
        stockNumber
        trim
      }
    }
    dms_deal {
      make_name
      model_name
      year
      stock_number
      deal_number
      deal_type
      trades {
        make_name
        model_name
        year
        vin
      }
    }
    deal_type
    marketing {
      lead_channel
      lead_direction
      lead_source
    }
    permissions {
      can_read_details
    }
  }
`;

const OPPORTUNITIES_FOR_DEALER = gql`
  query OpportunitiesForUser(
    $dealerIds: [Int]!
    $statuses: [Int]!
    $page: Int
    $stock_type: String
    $deal_types: [String]
    $lead_dir_channel: String
    $lead_source: String
    $sales_assignees: [String]
    $manager_assignees: [String]
    $bdc_assignees: [String]
    $finance_assignees: [String]
    $sub_status: String
    $status_date: StatusDates
    $keywords: String
    $created: StatusDates
    $assignees: [String]
    $sort_by: [SortOpportunityBy]
    $dms_deal: DmsDealInput
  ) {
    dmsDealMakes(
      dealer_ids: $dealerIds
      statuses: $statuses
      stock_type: $stock_type
      lead_dir_channel: $lead_dir_channel
      lead_source: $lead_source
      sales_assignees: $sales_assignees
      manager_assignees: $manager_assignees
      bdc_assignees: $bdc_assignees
      finance_assignees: $finance_assignees
      assignees: $assignees
      sub_status: $sub_status
      status_date: $status_date
      keywords: $keywords
      created: $created
      dms_deal: $dms_deal
    )
    dmsDealModels(
      dealer_ids: $dealerIds
      statuses: $statuses
      stock_type: $stock_type
      lead_dir_channel: $lead_dir_channel
      lead_source: $lead_source
      sales_assignees: $sales_assignees
      manager_assignees: $manager_assignees
      bdc_assignees: $bdc_assignees
      finance_assignees: $finance_assignees
      assignees: $assignees
      sub_status: $sub_status
      status_date: $status_date
      keywords: $keywords
      created: $created
      dms_deal: $dms_deal
    )
    dmsDealYears(
      dealer_ids: $dealerIds
      statuses: $statuses
      stock_type: $stock_type
      lead_dir_channel: $lead_dir_channel
      lead_source: $lead_source
      sales_assignees: $sales_assignees
      manager_assignees: $manager_assignees
      bdc_assignees: $bdc_assignees
      finance_assignees: $finance_assignees
      assignees: $assignees
      sub_status: $sub_status
      status_date: $status_date
      keywords: $keywords
      created: $created
      dms_deal: $dms_deal
    )
    opportunitiesList(
      dealer_ids: $dealerIds
      statuses: $statuses
      page: $page
      deal_types: $deal_types
      lead_dir_channel: $lead_dir_channel
      lead_source: $lead_source
      sales_assignees: $sales_assignees
      manager_assignees: $manager_assignees
      bdc_assignees: $bdc_assignees
      finance_assignees: $finance_assignees
      assignees: $assignees
      sub_status: $sub_status
      status_date: $status_date
      keywords: $keywords
      page_size: 25
      created: $created
      sort_by: $sort_by
      dms_deal: $dms_deal
    ) {
      results {
        ...OpportunityFields
      }
      pagination {
        page
        total
      }
    }
    getStatusCounts(
      dealer_ids: $dealerIds
      statuses: $statuses
      deal_types: $deal_types
      lead_dir_channel: $lead_dir_channel
      lead_source: $lead_source
      sales_assignees: $sales_assignees
      manager_assignees: $manager_assignees
      bdc_assignees: $bdc_assignees
      finance_assignees: $finance_assignees
      sub_status: $sub_status
      status_date: $status_date
      keywords: $keywords
      created: $created
      assignees: $assignees
    ) {
      status_results {
        fresh_count
        desk_count
        fi_count
        posted_count
        delivered_count
        lost_count
        pending_count
        cash_count
        approved_count
        signed_count
        tubed_count
        carry_count
        preapp_count
        total_inbound_count
        total_outbound_count
        total_inbound_web
        total_inbound_phone
        total_inbound_walk
        total_inbound_chat
        total_inbound_sms
        total_inbound_email
        total_inbound_event
        total_inbound_social
        total_inbound_service
        total_outbound_phone
        total_outbound_sms
        total_outbound_email
        total_unassigned
        new_count
        used_count
        unknown_count
      }
      user_results {
        _id
        count
      }
      sub_results {
        _id
        count
      }
      lead_results {
        _id
        count
      }
    }
  }
  ${opportunityFragment}
`;

const LEAD_SOURCES = gql`
  query getLeadSources($dealerId: Int!) {
    getLeadSources(dealer_ids: [$dealerId]) {
      results
    }
  }
`;

const useStyles = makeStyles(theme => ({
  mainTitle: {
    flexGrow: 1,
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  details: {
    padding: '0px',
  },
  list: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  assignees: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  desktopSearch: {
    marginLeft: 'auto',
    marginRight: '8px',
  },
  mobileSearch: {
    marginRight: '8px',
    marginLeft: '8px',
  },
}));

const OpportunitiesSearch = () => {
  const { currentUser } = useUserContext();
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const [filters, setFilters] = usePersistedState(sessionKeys.oppFilters, {
    created: {
      date_from: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    },
    sort_by: [
      {
        key: 'created',
        direction: -1,
      },
    ],
  });

  const [keywords, setKeywords] = usePersistedState(
    sessionKeys.oppKeywords,
    '',
  );

  const [filterDates, setFilterDates] = useState({
    date_to: filters.created?.date_to ? moment(filters.created.date_to) : null,
    date_from: filters.created?.date_from
      ? moment(filters.created.date_from)
      : null,
  });
  const [openFilters, setOpenFilters] = useState(false);
  const { dealerId } = useDealerContext();
  const { data: dealerData, loading: dealerLoading } = useQuery(DEALER_QUERY, {
    variables: {
      dealer_id: dealerId,
    },
  });
  const { data: usersData } = useQuery(USERS_FOR_DEALER, {
    variables: { dealerId },
  });

  const { loading: sourceLoading, data: sourceData } = useQuery(LEAD_SOURCES, {
    variables: { dealerId },
  });

  const hasStatuses =
    filters.hasOwnProperty('statuses') && filters['statuses'] !== undefined;

  const generateFilters = () => {
    let variables = {
      ...filters,
      dms_deal: {
        year: filters.dms_deal_year,
        make_name: filters.dms_deal_make_name,
        model_name: filters.dms_deal_model_name,
      },
      dealerIds: [dealerId],
      after: null,
      statuses: hasStatuses ? filters['statuses'] : Status.ALL,
      sales_assignees:
        currentUser?.role === Role.SALES_REP
          ? [currentUser.username]
          : filters.sales_assignees,
    };
    // New opportunity queries use deal_types to get and search for deals
    if (variables['stock_type'] !== undefined) {
      variables['deal_types'] = [filters.stock_type];
    }
    return variables;
  };

  const { data, loading, fetchMore, networkStatus, refetch, error } = useQuery(
    OPPORTUNITIES_FOR_DEALER,
    {
      variables: generateFilters(),
      notifyOnNetworkStatusChange: true,
    },
  );

  const pagination = data?.opportunitiesList?.pagination || {};

  const loadMoreResults = useCallback(() => {
    if (pagination.page * 25 < pagination.total) {
      fetchMore({
        variables: { page: pagination.page + 1 },
        updateQuery: (prev, { fetchMoreResult }) => {
          const results = fetchMoreResult.opportunitiesList.results;
          const pagination = fetchMoreResult.opportunitiesList.pagination;
          return results.length
            ? {
                opportunitiesList: {
                  __typename: prev.opportunitiesList.__typename,
                  results: [...prev.opportunitiesList.results, ...results],
                  pagination,
                },
              }
            : prev;
        },
      });
    }
  }, [fetchMore, pagination]);

  if (
    dealerLoading ||
    (loading &&
      networkStatus !== NetworkStatus.fetchMore &&
      networkStatus !== NetworkStatus.setVariables) ||
    sourceLoading
  ) {
    return <Loading />;
  }

  if (error) return <ErrorPage error={error} action="Loading Opportunities" />;

  const hasMorePages =
    (data &&
      data.opportunitiesList.pagination.page * 25 <
        data.opportunitiesList.pagination.total) ||
    false;
  const showing = hasMorePages
    ? data.opportunitiesList.pagination.page * 25
    : data.opportunitiesList.pagination.total;

  const noOpps = data.opportunitiesList.results.length === 0;
  const filterCounts = data.getStatusCounts;

  return (
    <>
      <Box style={{ marginBottom: '2px' }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          style={{ width: '100%' }}
        >
          <OpportunitiesFilters
            isOpen={openFilters}
            closeFilters={() => setOpenFilters(false)}
            filters={filters}
            updateFilters={setFilters}
            filterDates={filterDates}
            updateDates={setFilterDates}
            leadSources={sourceData?.getLeadSources?.results ?? []}
            filterCounts={filterCounts}
            vehicleYears={data.dmsDealYears}
            vehicleMakes={data.dmsDealMakes}
            vehicleModels={data.dmsDealModels}
          />
          <Grid item xs={12} style={{ padding: '16px 16px 2px 16px' }}>
            <DealerPicker />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.mainTitle}>
              Opportunities for {dealerData.dealer.dealer_name}
            </Typography>
          </Grid>
        </Grid>
        <Box
          component="span"
          style={desktop ? { display: 'flex' } : { marginLeft: '0px' }}
        >
          {desktop && (
            <>
              <Box>
                <Tooltip title="Select Opportunities to use Bulk Actions">
                  <Fab
                    color="primary"
                    onClick={handleAnchorClick}
                    size="medium"
                    style={{ marginLeft: '12px' }}
                  >
                    <MoreVertIcon />
                  </Fab>
                </Tooltip>
                <OpportunitiesBulkAction
                  anchorEl={anchorEl}
                  handleAnchorClose={handleAnchorClose}
                  selected={selected}
                  opps={data.opportunitiesList.results}
                  loadMore={loadMoreResults}
                  hasMorePages={hasMorePages}
                  totalOpps={data.opportunitiesList.pagination.total}
                />
              </Box>
              <Box>
                <Tooltip title="Filter list">
                  <Fab
                    color="primary"
                    onClick={() => setOpenFilters(true)}
                    size="medium"
                    style={{ marginLeft: '12px' }}
                  >
                    <FilterListIcon />
                  </Fab>
                </Tooltip>
              </Box>
            </>
          )}
          {!desktop && (
            <Box position="fixed" bottom="10px" right="10px">
              <Fab
                size="medium"
                color="primary"
                onClick={() => setOpenFilters(true)}
              >
                <FilterListIcon />
              </Fab>
            </Box>
          )}
          <Box style={{ alignSelf: 'center', marginLeft: '10px' }}>
            <OpportunitiesChips
              filters={filters}
              updateFilters={setFilters}
              users={usersData?.users || []}
              refetch={refetch}
              updateDates={setFilterDates}
              updateKeywords={setKeywords}
            />
          </Box>
          <Box
            className={desktop ? classes.desktopSearch : classes.mobileSearch}
          >
            <OpportunitiesSearchBar
              keywords={keywords}
              setKeywords={setKeywords}
              filters={filters}
              updateFilters={setFilters}
              refetch={refetch}
              updateKeywords={setKeywords}
            />
          </Box>
        </Box>
      </Box>
      {noOpps && (
        <Box>
          <div
            style={{
              width: '100%',
              fontSize: '18px',
              padding: '16px',
            }}
          >
            <Typography variant="subtitle2">
              No Opportunities found. Modifying filters may produce more
              results.
            </Typography>
          </div>
        </Box>
      )}
      <Box marginLeft={1} marginRight={1}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Grid item xs={12}>
            <div
              style={{
                textAlign: 'right',
                width: '100%',
                fontSize: '18px',
                alignItems: 'flex-end',
                alignSelf: 'flex-end',
              }}
            >
              Showing {showing} of {data.opportunitiesList.pagination.total}
            </div>
          </Grid>
        </Grid>
      </Box>
      {networkStatus === NetworkStatus.setVariables && <Loading />}
      {desktop && !noOpps && (
        <OpportunitiesList
          opps={data.opportunitiesList.results}
          showing={showing}
          setSelected={setSelected}
          selected={selected}
          filters={filters}
          updateFilters={setFilters}
        />
      )}
      {!desktop && !noOpps && (
        <OpportunitiesListMobile
          opps={data.opportunitiesList.results}
          showing={showing}
        />
      )}
      {!loading &&
        !noOpps &&
        showing < data.opportunitiesList.pagination.total && (
          <Box style={{ height: 1 }}>
            <Waypoint onEnter={loadMoreResults} bottomOffset="-20%" />
          </Box>
        )}

      {loading && networkStatus === NetworkStatus.fetchMore && (
        <>
          <div style={{ padding: '2rem', fontSize: '20px' }}>
            Loading more opportunities...
          </div>
          <LoadingBackdrop open={true} />
        </>
      )}
    </>
  );
};

export default OpportunitiesSearch;
