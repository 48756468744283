/* external */
import React from 'react';

/* Material UI */
import Grid from '@mui/material/Grid';

import { formatPrice } from 'utils';

const PriceTotal = ({ type, total }) => (
  <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
    <Grid
      item
      xs={10}
      sx={{
        color: type === 'Discounts' ? 'red' : 'black',
        textAlign: 'right',
        paddingRight: '8px',
      }}
    >
      {type} Total: <b>{formatPrice(total, { cents: true })}</b>
    </Grid>
  </Grid>
);

export default PriceTotal;
