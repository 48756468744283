import React, { useEffect, useState } from 'react';

/* external */
import { isEmpty } from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import { useTheme } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import HelpIcon from '@mui/icons-material/Help';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/* internal */
import { OfferStatus, DECLINE_REASONS } from 'modules/used_vehicles/const';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';
import AcceptCustomerOfferDialog from './AcceptCustomerOfferDialog';
import AppraisalDetails from '../components/AppraisalDetails';
import DashboardCardStatus from '../components/DashboardCardStatus';
import DatePickerDialog from '../components/DatePickerDialog';
import DealerAccept from './DealerAccept';
import DealerDecline from './DealerDecline';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import DealerQuestion from './DealerQuestion';
import EditCustomerOfferedAppraisalDialog from './EditCustomerOfferedAppraisalDialog';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const APPRAISAL_FRAGMENT = gql`
  fragment OfferDetailsAppraisal on Appraisal {
    appraisalStatus
    billOfSaleUrl
    createdAt
    createdByUser {
      display_name
    }
    dealer {
      name
    }
    deliveredAt
    soldAt
  }
`;

const OFFER_FRAGMENT = gql`
  fragment OfferDetailsOffer on Offer {
    acceptedAt
    claimedBy
    dealerId
    declinedAt
    isQuestionsPending
    offerStatus
    questions {
      id
      createdAt
      fromDealer {
        name
      }
      toDealer {
        name
      }
      text
      answer {
        id
        createdAt
        fromDealer {
          name
        }
        toDealer {
          name
        }
        text
      }
    }
    appraisal {
      id
      ...OfferDetailsAppraisal
      ...EditCustomerOfferedAppraisalDialogAppraisal
    }
    ...DashboardCardStatusOffer
    ...AcceptCustomerOfferDialogOffer
  }
  ${APPRAISAL_FRAGMENT}
  ${DashboardCardStatus.fragments.offer}
  ${EditCustomerOfferedAppraisalDialog.fragments.appraisal}
  ${AcceptCustomerOfferDialog.fragments.offer}
`;

const CREATE_BILL_OF_SALE_MUTATION = gql`
  mutation createBillOfSale($id: Int!) {
    appraisals {
      createBillOfSale(id: $id) {
        id
        ...AppraisalDetailsAppraisal
        ...OfferDetailsAppraisal
      }
    }
  }
  ${AppraisalDetails.fragments.appraisal}
  ${APPRAISAL_FRAGMENT}
`;

const OFFER_QUERY = gql`
  query getOffer($id: Int!) {
    appraisals {
      offer(id: $id) {
        id
        appraisal {
          id
          ...AppraisalDetailsAppraisal
          ...DashboardCardStatusAppraisal
          ...DealerAcceptAppraisal
          ...DealerDeclineAppraisal
          ...DealerQuestionAppraisal
          ...EditCustomerOfferedAppraisalDialogAppraisal
        }
        ...OfferDetailsOffer
      }
    }
  }
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
  ${DealerAccept.fragments.appraisal}
  ${DealerDecline.fragments.appraisal}
  ${DealerQuestion.fragments.appraisal}
  ${EditCustomerOfferedAppraisalDialog.fragments.appraisal}
  ${OFFER_FRAGMENT}
`;

const ACCEPT_OFFER = gql`
  mutation acceptOffer($id: Int!) {
    appraisals {
      acceptOffer(id: $id) {
        id
        appraisal {
          id
          ...AppraisalDetailsAppraisal
          ...DashboardCardStatusAppraisal
          ...DealerAcceptAppraisal
          ...DealerDeclineAppraisal
          ...DealerQuestionAppraisal
        }
        ...OfferDetailsOffer
      }
    }
  }
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
  ${DealerAccept.fragments.appraisal}
  ${DealerDecline.fragments.appraisal}
  ${DealerQuestion.fragments.appraisal}
  ${OFFER_FRAGMENT}
`;

const ACCEPT_CUSTOMER_OFFER = gql`
  mutation acceptCustomerOffer($id: Int!, $price: Float!) {
    appraisals {
      acceptCustomerOffer(id: $id, data: { price: $price }) {
        id
        appraisal {
          id
          ...AppraisalDetailsAppraisal
          ...DashboardCardStatusAppraisal
          ...DealerAcceptAppraisal
          ...DealerDeclineAppraisal
          ...DealerQuestionAppraisal
        }
        ...OfferDetailsOffer
      }
    }
  }
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
  ${DealerAccept.fragments.appraisal}
  ${DealerDecline.fragments.appraisal}
  ${DealerQuestion.fragments.appraisal}
  ${OFFER_FRAGMENT}
`;

const DECLINE_CUSTOMER_OFFER = gql`
  mutation declineCustomerOffer($id: Int!, $data: CustomerDeclineInput!) {
    appraisals {
      declineCustomerOffer(id: $id, data: $data) {
        id
        appraisal {
          id
          ...AppraisalDetailsAppraisal
          ...DashboardCardStatusAppraisal
          ...DealerAcceptAppraisal
          ...DealerDeclineAppraisal
          ...DealerQuestionAppraisal
        }
        ...OfferDetailsOffer
      }
    }
  }
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
  ${DealerAccept.fragments.appraisal}
  ${DealerDecline.fragments.appraisal}
  ${DealerQuestion.fragments.appraisal}
  ${OFFER_FRAGMENT}
`;
const DECLINE_OFFER = gql`
  mutation declineOffer($id: Int!) {
    appraisals {
      declineOffer(id: $id) {
        id
        appraisal {
          id
          ...AppraisalDetailsAppraisal
          ...DashboardCardStatusAppraisal
          ...DealerAcceptAppraisal
          ...DealerDeclineAppraisal
          ...DealerQuestionAppraisal
        }
        ...OfferDetailsOffer
      }
    }
  }
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
  ${DealerAccept.fragments.appraisal}
  ${DealerDecline.fragments.appraisal}
  ${DealerQuestion.fragments.appraisal}
  ${OFFER_FRAGMENT}
`;

const RECEIVE_APPRAISAL = gql`
  mutation receiveAppraisal($appraisalId: Int!, $deliveredAt: String) {
    appraisals {
      receiveAppraisal(id: $appraisalId, deliveredAt: $deliveredAt) {
        id
        ...AppraisalDetailsAppraisal
        ...DashboardCardStatusAppraisal
        ...DealerAcceptAppraisal
        ...DealerDeclineAppraisal
        ...DealerQuestionAppraisal
        ...OfferDetailsAppraisal
      }
    }
  }
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
  ${DealerAccept.fragments.appraisal}
  ${DealerDecline.fragments.appraisal}
  ${DealerQuestion.fragments.appraisal}
  ${APPRAISAL_FRAGMENT}
`;

const EDIT_APPRAISAL = gql`
  mutation editAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
    appraisals {
      updateAppraisal(id: $id, data: $data) {
        id
        ...AppraisalDetailsAppraisal
        ...DashboardCardStatusAppraisal
        ...DealerAcceptAppraisal
        ...DealerDeclineAppraisal
        ...DealerQuestionAppraisal
        ...OfferDetailsAppraisal
        ...EditCustomerOfferedAppraisalDialogAppraisal
      }
    }
  }
  ${AppraisalDetails.fragments.appraisal}
  ${DashboardCardStatus.fragments.appraisal}
  ${DealerAccept.fragments.appraisal}
  ${DealerDecline.fragments.appraisal}
  ${DealerQuestion.fragments.appraisal}
  ${EditCustomerOfferedAppraisalDialog.fragments.appraisal}
  ${APPRAISAL_FRAGMENT}
`;

const OfferDetails = ({ id }) => {
  const theme = useTheme();
  const [question, setQuestion] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState();
  const [showDeliveryDatePicker, setShowDeliveryDatePicker] = useState(false);
  const [showAccepted, setShowAccepted] = useState(false);
  const [customerAcceptOpen, setcustomerAcceptOpen] = useState(false);
  const [showDeclined, setShowDeclined] = useState(false);
  const [declineReasonOpen, setDeclineReasonOpen] = useState(false);
  const [declineReasonState, setDeclineReasonState] = useState('');
  const [editAppraisal, setEditAppraisal] = useState();
  const { dealers, dealerId, setDealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const timezone =
    currentUser?.goUserProfile?.settings?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { enqueueSnackbar: snackIt } = useSnackbar();

  const { data, error, loading, refetch } = useQuery(OFFER_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const [createBillOfSaleUrl, { loading: generatingBOS, error: BOSError }] =
    useMutation(CREATE_BILL_OF_SALE_MUTATION);

  const [acceptOffer, { loading: accepting, error: acceptError }] =
    useMutation(ACCEPT_OFFER);

  const [declineOffer, { loading: declining, error: declineError }] =
    useMutation(DECLINE_OFFER);

  const [receiveAppraisal, { loading: receiving }] =
    useMutation(RECEIVE_APPRAISAL);

  const [updateAppraisal, { loading: editing, error: editError }] =
    useMutation(EDIT_APPRAISAL);

  const [
    acceptCustomerOffer,
    { loading: acceptingCustomerOffer, error: acceptCustomerOfferError },
  ] = useMutation(ACCEPT_CUSTOMER_OFFER);

  const [
    declineCustomerOffer,
    { loading: decliningCustomerOffer, error: declineCustomerOfferError },
  ] = useMutation(DECLINE_CUSTOMER_OFFER);

  const offer = data?.appraisals.offer || {};
  const appraisal = offer?.appraisal || {};
  const { createdAt, createdByUser = {}, deliveredAt } = appraisal;
  const { acceptedAt, claimedBy, offerStatus, questions = [] } = offer;

  useEffect(() => {
    if (
      dealerId &&
      offer?.dealerId &&
      dealerId !== offer?.dealerId &&
      dealers.map(x => x.dealer_id).includes(offer?.dealerId)
    ) {
      setDealerId(offer.dealerId);
      snackIt(
        `Set current dealer to: ${
          dealers.find(x => x.dealer_id === offer.dealerId)?.dealer_name
        } `,
        { variant: 'warning' },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerId, offer, dealers]);

  if (error) return <ErrorDisplay error={error} />;
  if (acceptError) return <ErrorDisplay error={acceptError} />;
  if (acceptCustomerOfferError)
    return <ErrorDisplay error={acceptCustomerOfferError} />;
  if (declineError) return <ErrorDisplay error={declineError} />;
  if (declineCustomerOfferError)
    return <ErrorDisplay error={declineCustomerOfferError} />;
  if (BOSError) return <ErrorDisplay error={BOSError} />;

  if (loading) return <Loading />;

  const handleMenuClick = e => setMenuAnchor(e.currentTarget);

  const handleMenuClose = () => setMenuAnchor(null);

  const handleCloseDialog = () => {
    setShowDeliveryDatePicker(false);
    handleMenuClose();
  };

  const handleCloseEditDialog = () => {
    setEditAppraisal(null);
    handleMenuClose();
  };

  const handleSaveAppraisal = ({ value, ...data }) => {
    updateAppraisal({
      variables: {
        id: appraisal.id,
        data: { value: parseFloat(value), ...data },
      },
    }).then(
      () => {
        setEditAppraisal(null);
        handleMenuClose();
      },
      () => {},
    );
  };

  const notifyComplete = (message, options) => {
    handleCloseDialog();
    snackIt(message, options);
  };

  const handleSetDeliveryDate = dateTime =>
    receiveAppraisal({
      variables: { appraisalId: appraisal.id, deliveredAt: dateTime },
    }).then(
      () =>
        notifyComplete('Updated delivery date successfully.', {
          variant: 'success',
        }),
      err =>
        notifyComplete(`There was an error ${JSON.stringify(err)}`, {
          variant: 'error',
        }),
    );

  const handleAcceptCustomerOffer = ({ price }) =>
    acceptCustomerOffer({ variables: { id, price } }).then(
      () => {
        setcustomerAcceptOpen(false);
        notifyComplete('Accepted Customer Offer', { variant: 'success' });
      },
      err =>
        notifyComplete(`There was an error ${JSON.stringify(err)}`, {
          variant: 'error',
        }),
    );
  const handleDeclineReason = event =>
    setDeclineReasonState(event.target.value);

  const handleDeclineAppraisal = () => {
    declineCustomerOffer({
      variables: { id, data: { declineReason: declineReasonState } },
    }).then(() => {
      setDeclineReasonOpen(false);
      enqueueSnackbar('Offer Declined', { variant: 'success' });
    });
  };

  return (
    <>
      <LoadingBackdrop open={accepting || acceptingCustomerOffer}>
        Accepting Offer
      </LoadingBackdrop>
      <LoadingBackdrop open={declining || decliningCustomerOffer}>
        Declining Offer
      </LoadingBackdrop>
      <LoadingBackdrop open={receiving}>Receiving Vehicle</LoadingBackdrop>
      <LoadingBackdrop open={generatingBOS}>
        Generating Bill of Sale
      </LoadingBackdrop>
      <LoadingBackdrop open={editing}>Saving Appraisal</LoadingBackdrop>
      <EditCustomerOfferedAppraisalDialog
        appraisal={editAppraisal}
        onClose={handleCloseEditDialog}
        onSave={handleSaveAppraisal}
        errors={editError}
        snackIt={snackIt}
      />
      <Box padding={1}>
        <DealerPicker disabled />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={1}
      >
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
          Summary for Item #{appraisal.id}
        </Typography>
        <DashboardCardStatus
          appraisal={appraisal}
          offer={offer}
          dealer={true}
          showIcon={false}
          showChip={true}
          paddingBottom={0}
        />
        {(acceptedAt || offerStatus === OfferStatus.CUSTOMER_OFFERED) && (
          <IconButton size="large" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>

      {(acceptedAt || offerStatus === OfferStatus.CUSTOMER_OFFERED) && (
        <Menu
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          {acceptedAt && (
            <MenuItem onClick={() => setShowDeliveryDatePicker(true)}>
              Set Delivery Date
            </MenuItem>
          )}
          {acceptedAt && (
            <MenuItem
              onClick={() =>
                createBillOfSaleUrl({ variables: { id: appraisal.id } }).then(
                  res => {
                    window.open(
                      res.data.appraisals.createBillOfSale.billOfSaleUrl,
                      '_blank',
                    );
                  },
                  () => {},
                )
              }
            >
              Create New Bill Of Sale
            </MenuItem>
          )}
          {acceptedAt && appraisal.billOfSaleUrl && (
            <MenuItem
              onClick={() => window.open(appraisal.billOfSaleUrl, '_blank')}
            >
              Download Bill Of Sale
            </MenuItem>
          )}
          {offerStatus === OfferStatus.CUSTOMER_OFFERED && (
            <MenuItem onClick={() => setEditAppraisal(appraisal)}>
              Edit Appraisal
            </MenuItem>
          )}
        </Menu>
      )}
      <Box margin={1} marginTop={0}>
        <Box>
          Created <FormatDateTZ paddingLeft={2} date={createdAt} format="LL" />
        </Box>
        <Box>Offered by: {createdByUser?.display_name}</Box>
      </Box>
      {!showDeclined && !showAccepted && !question && (
        <>
          <AppraisalDetails appraisal={appraisal} dealer refetch={refetch} />
          {(offerStatus === OfferStatus.OFFERED ||
            (offerStatus === OfferStatus.CUSTOMER_OFFERED &&
              claimedBy === currentUser.username)) && (
            <Box margin={1}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#BF0000', color: 'white' }}
                  onClick={() =>
                    offerStatus === OfferStatus.CUSTOMER_OFFERED
                      ? setDeclineReasonOpen(true)
                      : declineOffer({ variables: { id } }).then(() =>
                          setShowDeclined(true),
                        )
                  }
                >
                  <CloseIcon />
                  Decline
                </Button>
                <Dialog
                  maxWidth="sm"
                  open={declineReasonOpen}
                  onClose={() => setDeclineReasonOpen(false)}
                  fullWidth
                >
                  <DialogTitle>Set Decline Reason</DialogTitle>
                  <DialogContent>
                    <Select
                      value={declineReasonState}
                      onChange={handleDeclineReason}
                      style={{ width: '250px' }}
                      noNull
                    >
                      {Object.entries(DECLINE_REASONS).map(([key, value]) => {
                        return (
                          <MenuItem value={value} key={key}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <DialogActions>
                      <Button onClick={() => setDeclineReasonOpen(false)}>
                        Cancel
                      </Button>
                      <Button
                        disabled={!declineReasonState}
                        onClick={handleDeclineAppraisal}
                      >
                        Accept
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
                {/* Hide questions if its a customer offered appraisal */}
                {isEmpty(questions) &&
                  offerStatus !== OfferStatus.CUSTOMER_OFFERED && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setQuestion(true)}
                    >
                      <HelpIcon style={{ paddingRight: '0.25em' }} />
                      Question
                    </Button>
                  )}

                <Button
                  variant="contained"
                  style={theme.actions.confirm}
                  onClick={() =>
                    offerStatus === OfferStatus.CUSTOMER_OFFERED
                      ? setcustomerAcceptOpen(true)
                      : acceptOffer({ variables: { id } }).then(() =>
                          setShowAccepted(true),
                        )
                  }
                >
                  <DoneIcon />
                  Accept
                </Button>
              </div>
            </Box>
          )}
        </>
      )}
      {showDeclined && <DealerDecline appraisal={appraisal} />}
      {showAccepted && (
        <DealerAccept
          appraisal={appraisal}
          createBillOfSaleUrl={createBillOfSaleUrl}
        />
      )}
      {question && (
        <DealerQuestion
          offer={offer}
          setQuestion={setQuestion}
          refetch={refetch}
        />
      )}
      <DatePickerDialog
        open={showDeliveryDatePicker}
        value={deliveredAt}
        onClose={handleCloseDialog}
        onConfirm={handleSetDeliveryDate}
        timezone={timezone}
      />

      <AcceptCustomerOfferDialog
        offer={offer}
        open={customerAcceptOpen}
        onClose={() => setcustomerAcceptOpen(false)}
        onConfirm={handleAcceptCustomerOffer}
      />
    </>
  );
};

export default OfferDetails;
