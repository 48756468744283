import gql from 'graphql-tag';
import { vssVehicleFragment } from './fragments';

export const ADD_VEHICLE = gql`
  mutation addVehicleToShowroom(
    $showroomId: Int!
    $categoryId: Int!
    $year: Int!
    $make: String!
    $model: String!
    $freightPdi: Int
    $styleId: Int!
  ) {
    showroom {
      addVehicleToShowroom(
        showroom_id: $showroomId
        category_id: $categoryId
        year: $year
        make: $make
        model: $model
        freight_pdi: $freightPdi
        style_id: $styleId
      ) {
        ...VehicleFields
      }
    }
  }
  ${vssVehicleFragment}
`;

export const UPDATE_VEHICLE = gql`
  mutation updateVehicleToShowroom(
    $updateVehicleToShowroomId: ID!
    $input: UpdateVehicleInput!
  ) {
    showroom {
      updateVehicleToShowroom(id: $updateVehicleToShowroomId, input: $input) {
        ...VehicleFields
      }
    }
  }
  ${vssVehicleFragment}
`;

export const DELETE_VEHICLE = gql`
  mutation deleteVehicleFromShowroom($deleteVehicleFromShowroomId: ID!) {
    showroom {
      deleteVehicleFromShowroom(id: $deleteVehicleFromShowroomId)
    }
  }
`;

export const BULK_DELETE_VEHICLES = gql`
  mutation bulkDeleteVehiclesFromShowroom($vehicleIds: [ID!]!) {
    showroom {
      bulkDeleteVehiclesFromShowroom(vehicle_ids: $vehicleIds)
    }
  }
`;
