import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material UI
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function VehicleShowroomMobileTable(props) {
  const { cards, openDeleteModal } = props;
  const history = useHistory();

  return (
    <Box p={2} component="section">
      {cards.length > 0 ? (
        cards.map(card => (
          <Card key={card.id} elevation={4} sx={{ margin: '1rem' }}>
            <Grid
              container
              alignContent="center"
              justifyContent="space-between"
            >
              <Grid item xs={8}>
                <CardContent>
                  <Typography
                    sx={{ marginRight: '0.25rem' }}
                    variant="subtitle2"
                    component="span"
                  >
                    <strong>Category: </strong>
                    {card.category}
                  </Typography>{' '}
                  <Typography variant="subtitle2" component="span">
                    <strong>Showroom: </strong>
                    {card.showroom_name}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item>
                <CardActions>
                  <IconButton
                    onClick={() => history.push(`/vehicle-showroom/${card.id}`)}
                  >
                    <EditIcon color="info" />
                  </IconButton>
                  <IconButton onClick={() => openDeleteModal([card])}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </CardActions>
              </Grid>
            </Grid>
            <Divider
              variant="middle"
              sx={{
                marginBottom: '1rem',
                border: '1px solid black',
              }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="span">
                    <strong>Year: </strong>
                    {card.year}
                  </Typography>
                </Grid>{' '}
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="span">
                    <strong>Make: </strong>
                    {card.make}
                  </Typography>
                </Grid>{' '}
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="span">
                    <strong>Model: </strong>
                    {card.model}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="span">
                    <strong>Trim: </strong>
                    {card.trim}
                  </Typography>
                </Grid>{' '}
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="span">
                    <strong>Freight PDI: </strong>
                    {card.freight_pdi}
                  </Typography>
                </Grid>{' '}
                <Grid item sm={4}>
                  <Typography variant="subtitle2" component="span">
                    <strong>Style ID: </strong>
                    {card.style_id}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      ) : (
        <Paper elevation={4}>
          <Typography variant="subtitle1" align="center">
            There are currently no vehicles in the showroom.
          </Typography>
          <Divider variant="middle">
            <Typography variant="subtitle2" align="center">
              <strong>Use the + button to add a vehicle.</strong>
            </Typography>
          </Divider>
        </Paper>
      )}
    </Box>
  );
}

VehicleShowroomMobileTable.propTypes = {
  cards: PropTypes.array.isRequired,
};
