import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Grid, Toolbar, Typography, IconButton, Tooltip } from '@mui/material';
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EnhancedTableToolbar(props) {
  const { numSelected, onBulkDelete } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid item>
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} {numSelected === 1 ? 'vehicle' : 'vehicles'}{' '}
              selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="subtitle1"
              id="tableTitle"
              component="div"
            >
              No vehicles selected
            </Typography>
          )}
        </Grid>
        <Grid>
          {numSelected > 0 ? (
            <Tooltip title="Bulk delete">
              <IconButton onClick={onBulkDelete}>
                <DeleteIcon fontSize="large" color="error" />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
