import React, { useEffect } from 'react';
import gql from 'graphql-tag';

/* external */
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

/* Material UI */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  LoadingBackdrop,
  SelectControl,
  TextFieldControl,
} from 'components/MaterialUI';

/* internal */
import { errorHandler } from 'modules/pitches/utils';

const DEFAULT_ACCESSORIES_FRAGMENT = gql`
  fragment DefaultAccessoriesFields on PitchDefaultAccessories {
    id
    price
    title
    type
  }
`;
const CREATE_DEALER_SETTINGS = gql`
  mutation createDealerSettings($dealerSettings: PitchDealerSettingInput!) {
    pitches {
      createDealerSettings(dealerSettings: $dealerSettings) {
        id
        defaultAccessories {
          ...DefaultAccessoriesFields
        }
      }
    }
  }
  ${DEFAULT_ACCESSORIES_FRAGMENT}
`;

const ADD_DEFAULT_ACCESSORY = gql`
  mutation addDefaultAccessory(
    $dealerId: Int!
    $data: PitchDefaultAccessoryInput!
  ) {
    pitches {
      addDefaultAccessory(dealerId: $dealerId, data: $data) {
        defaultAccessories {
          ...DefaultAccessoriesFields
        }
      }
    }
  }
  ${DEFAULT_ACCESSORIES_FRAGMENT}
`;

const UPDATE_DEFAULT_ACCESSORY = gql`
  mutation updateDefaultAccessory(
    $dealerId: Int!
    $accessoryId: Int!
    $data: PitchDefaultAccessoryInput!
  ) {
    pitches {
      updateDefaultAccessory(
        dealerId: $dealerId
        accessoryId: $accessoryId
        data: $data
      ) {
        defaultAccessories {
          ...DefaultAccessoriesFields
        }
      }
    }
  }
  ${DEFAULT_ACCESSORIES_FRAGMENT}
`;

const useStyles = makeStyles(theme => ({
  saveButton: {
    color: 'white',
    backgroundColor: theme.actions.confirm.backgroundColor,
  },
}));

const AddEditDefaultAccessoryDialog = ({
  dealerId,
  dealerSettings,
  selectedAccessory = {},
  open,
  onClose,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, formState, handleSubmit, reset, setError } = useForm({
    defaultValues: {
      title: selectedAccessory.title || '',
      type: selectedAccessory.type || '',
      price: selectedAccessory.price || '',
    },
  });
  const classes = useStyles();

  const [createDealerSettings, { loading: createLoading }] = useMutation(
    CREATE_DEALER_SETTINGS,
  );
  const [addDefaultAccessory, { loading: addLoading }] = useMutation(
    ADD_DEFAULT_ACCESSORY,
    {
      onCompleted: () => {
        enqueueSnackbar('Default item added successfully!', {
          variant: 'success',
        });
        refetch();
      },
    },
  );
  const [updateDefaultAccessory, { loading: updateLoading }] = useMutation(
    UPDATE_DEFAULT_ACCESSORY,
    {
      onCompleted: () => {
        enqueueSnackbar('Default item updated successfully!', {
          variant: 'success',
        });
      },
    },
  );

  const TYPES = [
    { value: 'accessory', name: 'Accessory' },
    { value: 'discount', name: 'Discount' },
  ];

  const actionText = selectedAccessory.id ? 'Edit' : 'Add';

  const required = 'This field is required';

  useEffect(() => {
    if (open)
      reset({
        title: selectedAccessory.title || '',
        type: selectedAccessory.type || '',
        price: selectedAccessory.price || '',
      });
  }, [open, selectedAccessory, reset]);

  const onSubmit = async data => {
    try {
      if (dealerSettings?.id) {
        if (selectedAccessory.id) {
          await updateDefaultAccessory({
            variables: { dealerId, accessoryId: selectedAccessory.id, data },
          });
        } else {
          await addDefaultAccessory({ variables: { dealerId, data } });
        }
      } else {
        await createDealerSettings({
          variables: { dealerSettings: { dealerId } },
        });
        await addDefaultAccessory({ variables: { dealerId, data } });
      }
      onClose();
    } catch (error) {
      errorHandler(enqueueSnackbar, setError)(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <LoadingBackdrop open={createLoading || addLoading || updateLoading}>
        Loading...
      </LoadingBackdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{actionText} Default Accessory/Discount</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldControl
                control={control}
                fullWidth
                label="Title"
                muiVariant="outlined"
                name="title"
                rules={{ required }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectControl
                control={control}
                fullWidth
                label="Type"
                name="type"
                noNull
                options={TYPES}
                rules={{ required }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldControl
                control={control}
                fullWidth
                label="Price"
                muiVariant="outlined"
                name="price"
                rules={{ required }}
                variant="currency"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.saveButton}
            disabled={formState.isSubmitting || !formState.isDirty}
            variant="contained"
            type="submit"
          >
            {formState.isSubmitting ? 'Saving' : 'Save'}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEditDefaultAccessoryDialog;
