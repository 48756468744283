import gql from 'graphql-tag';
import React, { useState } from 'react';

import {
  Cancel as CancelIcon,
  Edit as EditIcon,
  Message as MessageIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { formatPhoneNumber, toIntOrUndefined } from 'utils';
import { useSnackMutation } from 'utils/useSnackMutation';

import { cadfmt, LogStatus, LogType, ROStatus } from './constants';
import { EditAmountDialog } from './EditAmountDialog';
import { LogBox } from './LogBox';

const useStyles = makeStyles({
  largeText: { padding: '1rem', fontSize: '18px' },
  sendPaymentGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 0,
  },
  sendPaymentMessageAndButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: '120px',
  },
  vehicleAndServiceAdvisorGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const SEND_PAYMENT_REQUEST = gql`
  mutation billingSendPaymentRequestSMS($ro_id: Int!) {
    rotracker {
      billingSendPaymentRequestSMS(ro_id: $ro_id) {
        success
      }
    }
  }
`;

const CANCEL_PAYMENT_REQUEST = gql`
  mutation billingCancelPaymentRequestSMS($ro_id: Int!) {
    rotracker {
      billingCancelPaymentRequestSMS(ro_id: $ro_id) {
        success
      }
    }
  }
`;

const UPDATE_RO = gql`
  mutation updateRO($dealerId: Int!, $roNumber: String!, $update: ROUpdate!) {
    rotracker {
      updateRO(dealer_id: $dealerId, ro_number: $roNumber, update: $update) {
        id
        customer_pay
      }
    }
  }
`;

const RODetailsPayments = ({ ro, isDesktop, refetch }) => {
  const classes = useStyles();
  const customerPhone = ro.sms_contact_number || ro.customer_phone;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const transactionsById = new Map(
    ro.transactions.map(i => [i.id.toString(), i]),
  );

  const [updateRO] = useSnackMutation(
    UPDATE_RO,
    'Successfully updated Customer Pay amount!',
    'Error updating Customer Pay amount, please try again later or contact support.',
    () => {
      setOpen(false);
      refetch();
    },
    () => refetch(),
  );
  const [sendPaymentRequest, { loading: isSending }] = useSnackMutation(
    SEND_PAYMENT_REQUEST,
    'Payment request SMS sent!',
    'Error sending SMS, please try again later or contact support.',
    () => refetch(),
    () => refetch(),
  );
  const [cancelPaymentRequest] = useSnackMutation(
    CANCEL_PAYMENT_REQUEST,
    'Payment request cancelled!',
    'Error cancelling payment request, please try again later or contact support.',
    () => refetch(),
    () => refetch(),
  );

  function handleSubmit(amount) {
    updateRO({
      variables: {
        dealerId: ro.dealer_id,
        roNumber: ro.ro_number,
        update: {
          customer_pay: parseFloat(amount),
        },
      },
    });
  }

  const smsLast = ro.billingLog.findLast(e => e.type === LogType.SMS);
  const paymentLast = ro.billingLog.findLast(
    e => e.type === LogType.PAYMENT && e.status !== LogStatus.FAILED,
  );

  const showSMSButton =
    ro.billingOwing.cents > 0 &&
    ro.status === ROStatus.PRE_INVOICED &&
    customerPhone &&
    !paymentLast &&
    (!smsLast || smsLast.status !== LogStatus.SUCCESS || !ro.share_key);

  const showCancelPaymentButton = ro.share_key && !paymentLast;

  return (
    <Paper>
      <Container maxWidth="xl">
        <Grid
          container
          direction={isDesktop ? 'row' : 'column'}
          className={classes.vehicleAndServiceAdvisorGrid}
          xs={12}
          item
        >
          <Grid item className={classes.largeText} xs={6}>
            <Box>
              <b>Vehicle: </b>
              {`${ro.vehicle_year} ${ro.vehicle_make} ${ro.vehicle_model}`}
            </Box>
            <Box>
              <b>VIN: </b>
              {ro.vin}
            </Box>
          </Grid>
          <Grid item className={classes.largeText} xs={6}>
            <b>Service Advisor: </b>
            {`${ro.serviceAdvisor?.display_name ?? 'N/A'}`}
          </Grid>
        </Grid>
        <Grid
          container
          direction={isDesktop ? 'row' : 'column'}
          style={{
            display: 'flex',
            flexDirection: `${isDesktop ? 'row' : 'column'}`,
          }}
          xs={12}
          item
        >
          <Grid item style={{ padding: '1rem' }} xs={6}>
            <Box>
              <b>Customer Pay Total: </b>
              {cadfmt.format(ro.billingOwing.cents / 100)}{' '}
              <Button
                disabled={!showSMSButton}
                size="small"
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={() => setOpen(true)}
              >
                Edit
              </Button>
            </Box>
            <Box>
              <b>Customer Pay Pending/Paid: </b>
              {cadfmt.format(
                (ro.billingOwing.cents - ro.billingBalance.with_pending) / 100,
              )}
            </Box>
            <Box>
              <b>Customer Pay Remaining: </b>
              {cadfmt.format(ro.billingBalance.with_pending / 100)}
            </Box>
            <Box>
              <b>CDK Customer Pay Total: </b>
              {cadfmt.format(ro.customer_pay)}
            </Box>
            <Box>
              <b>CDK Warranty Pay Total: </b>
              {cadfmt.format(ro.warranty_pay)}
            </Box>
            <Box>
              <b>CDK Internal Pay Total: </b>
              {cadfmt.format(ro.internal_pay)}
            </Box>
          </Grid>
          <Grid item style={{ padding: '1rem' }} xs={6} md={4}>
            <Grid container direction="column">
              <Grid item xs={12} className={classes.sendPaymentGrid}>
                <Box className={classes.sendPaymentMessageAndButton}>
                  {showSMSButton && (
                    <Button
                      variant="contained"
                      style={theme.actions.confirm}
                      startIcon={
                        isSending ? <CircularProgress /> : <MessageIcon />
                      }
                      disabled={isSending}
                      onClick={() =>
                        sendPaymentRequest({
                          variables: { ro_id: toIntOrUndefined(ro.id) },
                        })
                      }
                    >
                      {isSending ? (
                        <>Sending SMS&hellip;</>
                      ) : (
                        <>
                          Send Payment Request to Customer at{' '}
                          {formatPhoneNumber(customerPhone) || customerPhone}
                        </>
                      )}
                    </Button>
                  )}
                  {showCancelPaymentButton && (
                    <Button
                      variant="contained"
                      style={theme.actions.cancel}
                      startIcon={<CancelIcon />}
                      disabled={isSending}
                      onClick={() =>
                        cancelPaymentRequest({
                          variables: { ro_id: toIntOrUndefined(ro.id) },
                        })
                      }
                    >
                      Cancel Payment Request
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <LogBox
          transactionsById={transactionsById}
          entries={ro.billingLog}
          isPaymentRequestCancelled={
            !ro.share_key &&
            ro.billingLog.find(
              e => e.type === LogType.SMS && e.status === LogStatus.SUCCESS,
            )
          }
          hasPendingPayments={
            ro.billingBalance.without_pending > ro.billingBalance.with_pending
          }
        />
      </Container>
      <EditAmountDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleSubmit={handleSubmit}
        amount={(ro.billingOwing.cents / 100).toFixed(2)}
        maxAmount={ro.customer_pay}
      />
    </Paper>
  );
};

RODetailsPayments.fragments = {
  ro: gql`
    fragment RODetailsPaymentsRO on ROTrackerRO {
      sms_contact_number
      customer_phone
      ro_number
      status
      vehicle_year
      vehicle_make
      vehicle_model
      vin
      ro_number
      customer_pay
      share_key
      billingBalance {
        with_pending
        without_pending
      }
      billingOwing {
        cents
      }
      billingLog {
        id
        created_at
        type
        ref_type
        ref_id
        status
        cents
        username
      }
      warranty_pay
      internal_pay
      last_transaction {
        id
        share_key
        payment_form_opened
        successful_sms
        amount
        created_at
        status
      }
      last_transaction_status
      last_transaction_settling_status
      serviceAdvisor {
        display_name
      }
    }
  `,
};

export default RODetailsPayments;
