import React, { useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';

const amountRegex = /^\d+(?:\.\d{0,2})?$/;

export function EditAmountDialog({
  open,
  handleClose,
  handleSubmit,
  amount: initAmount,
  maxAmount,
}) {
  const [amount, setAmount] = useState(initAmount);
  const isBelowMin = amount <= 1.0;
  const isAboveMax = amount > maxAmount;
  const isValid = amountRegex.test(amount);
  const handleChange = event => {
    setAmount(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Customer Pay Amount</DialogTitle>
      <DialogContent>
        {isBelowMin && (
          <Box
            style={{
              marginBottom: '1em',
            }}
          >
            <Alert severity="error">
              <AlertTitle>
                Payment amount must be <strong>greater</strong> than $1.
              </AlertTitle>
            </Alert>
          </Box>
        )}
        {isAboveMax && (
          <Box
            style={{
              marginBottom: '1em',
            }}
          >
            <Alert severity="error">
              <AlertTitle>
                Payment amount must be <strong>less than or equal to</strong>{' '}
                the total amount owing.
              </AlertTitle>
            </Alert>
          </Box>
        )}
        {!isValid && (
          <Box
            style={{
              marginBottom: '1em',
            }}
          >
            <Alert severity="error">
              <AlertTitle>
                Payment amount is not a properly formatted number.
              </AlertTitle>
            </Alert>
          </Box>
        )}
        <TextField
          error={isBelowMin || isAboveMax || !isValid}
          label="Customer Pay Amount"
          value={amount}
          onChange={handleChange}
          onKeyDown={e => {
            if (isBelowMin || isAboveMax || !isValid) {
              return;
            }

            if (e.key === 'Enter') {
              handleSubmit(amount);
            }
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={isBelowMin || isAboveMax || !isValid}
          onClick={() => handleSubmit(amount)}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
