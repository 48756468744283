import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

// Material UI
import { Box, Divider, Paper, Typography } from '@mui/material';

import { useDealerContext } from 'components/MaterialUI/DealerContext';

// Internal
import VehicleShowroomForm from '../../shared_components/VehicleShowroomForm';
import EditCurrentVehicleDetails from './EditCurrentVehicleDetails';

import {
  getShowroomIdsForDealer,
  createEditVehicleFormInitialValues,
} from '../../utils';

import {
  GET_ALL_SHOWROOMS_NAMES_BULK,
  GET_VEHICLE,
} from '../../graphql/queries';

const EditShowroomVehicleHeader = ({ children }) => (
  <Box component="section" p={2} sx={{ maxWidth: 1200 }}>
    <Paper elevation={4} p={2}>
      <Typography variant="h6" component="h2" p={2}>
        <strong>Edit Vehicle</strong>
      </Typography>
      <Divider
        variant="middle"
        sx={{
          margin: '0 1rem',
          border: '1px solid black',
        }}
      />
      {children}
    </Paper>
  </Box>
);

EditShowroomVehicleHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

const EditShowroomVehicle = props => {
  const { vehicleId } = props;
  if (!vehicleId) {
    return (
      <EditShowroomVehicleHeader>
        <Typography variant="body1" p={2}>
          No vehicle selected.
        </Typography>
      </EditShowroomVehicleHeader>
    );
  }

  const { data: vehicleData } = useQuery(GET_VEHICLE, {
    fetchPolicy: 'network-only',
    variables: {
      vehicleId,
    },
  });

  const vehicle = createEditVehicleFormInitialValues(
    vehicleData?.showroom?.vehicle || null,
  );

  const { dealerId } = useDealerContext();
  const { data: showroomsNamesData, loading: showroomsNamesDataLoading } =
    useQuery(GET_ALL_SHOWROOMS_NAMES_BULK, {
      fetchPolicy: 'network-only',
      variables: {
        ids: getShowroomIdsForDealer(dealerId),
      },
    });

  if (!vehicle) {
    return (
      <EditShowroomVehicleHeader>
        <Typography variant="body1" p={2}>
          Vehicle not found. Please contact Market Platform Support.
        </Typography>
      </EditShowroomVehicleHeader>
    );
  }

  return (
    <>
      <EditCurrentVehicleDetails vehicle={vehicle} />
      {showroomsNamesData?.showroom?.showrooms?.length > 0 ? (
        <VehicleShowroomForm
          editVehicle={vehicle}
          showroomsNamesData={showroomsNamesData}
          showroomsNamesDataLoading={showroomsNamesDataLoading}
        />
      ) : (
        <EditShowroomVehicleHeader>
          <Typography variant="body1" p={2}>
            No showrooms available for this dealer.
          </Typography>
        </EditShowroomVehicleHeader>
      )}
    </>
  );
};

EditShowroomVehicle.defaultProps = {
  vehicleId: null,
};

EditShowroomVehicle.propTypes = {
  vehicleId: PropTypes.string,
};

export default EditShowroomVehicle;
