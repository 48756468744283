import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// need to import the following from specific paths instead of
// from index.js to avoid circular dependencies
import RequiresRoles from './RequiresRoles';
import RequiresDealerIds from './RequiresDealerIds';

import RequiresFeatureFlag from './RequiresFeatureFlag';
import { Role, RoleGroup, RV_DEALERS } from 'constants.js';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItemText: {
    fontSize: '13px',
  },
}));

function MainNav() {
  const classes = useStyles();
  const routeMatch = useRouteMatch('/:rootDir/');
  const [selectedSubmenu, selectSubmenu] = React.useState(
    routeMatch?.params.rootDir,
  );

  const togglesMenu = name => () =>
    selectSubmenu(name === selectedSubmenu ? null : name);
  const isMenuOpen = name => name === selectedSubmenu;
  return (
    <List className={classes.root}>
      <ListItem button component={Link} to="/">
        <ListItemIcon className="fas fa-home" />
        <ListItemText primary="Home" />
      </ListItem>
      <RequiresRoles
        requiredRoles={[...RoleGroup.SALES_PEOPLE]}
        renderOnSuccess={() => (
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon className="fas fa-home" />
            <ListItemText primary="Dashboard" />
          </ListItem>
        )}
        renderOnFailure={() => null}
      />
      <RequiresRoles
        requiredRoles={[...RoleGroup.MANAGERS]}
        renderOnFailure={() => null}
        renderOnSuccess={() => (
          <>
            <ListItem button onClick={togglesMenu('dashboard')}>
              <ListItemIcon className="fas fa-table" />
              <ListItemText primary="Dashboard" />
              {isMenuOpen('dashboard') ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isMenuOpen('dashboard')} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={Link}
                  to="/dashboard"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-chart-line" />
                  <ListItemText primary="Manager Dashboard" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/overview"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-chart-bar" />
                  <ListItemText primary="Staff Overview" />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
      />
      <RequiresRoles
        requiredRoles={[...RoleGroup.AUCTION_ROLE]}
        renderOnFailure={() => null}
        renderOnSuccess={() => (
          <ListItem button component={Link} to="/auctions">
            <ListItemIcon className="fas fa-gavel" />
            <ListItemText primary="Auctions" />
          </ListItem>
        )}
      />
      <ListItem button onClick={togglesMenu('appointments')}>
        <ListItemIcon className="fas fa-calendar-alt" />
        <ListItemText primary="Appointments" />
        {isMenuOpen('appointments') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isMenuOpen('appointments')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            to="/appointments"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-car-side" />
            <ListItemText
              primary="Sales Appointments"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/appointments/delivery"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-car-side" />
            <ListItemText
              primary="Deliveries"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/appointments/service"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-wrench" />
            <ListItemText
              primary="Service Appointments"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button component={Link} to="/appraisals">
        <ListItemIcon className="fas fa-balance-scale-left" />
        <ListItemText primary="Appraisals" />
      </ListItem>
      <ListItem button onClick={togglesMenu('customers')}>
        <ListItemIcon className="fas fa-user" />
        <ListItemText primary="Customers" />
        {isMenuOpen('customers') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isMenuOpen('customers')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            to="/customers/search"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-search" />
            <ListItemText
              primary="Search"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          {/* The below are temporarily commented out while we roll out the
          first phase of Customers. */}
          {/*
          <ListItem
            disabled
            button
            component={Link}
            to="/customers/call-lists"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-phone" />
            <ListItemText
              primary="Call Lists"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            disabled
            button
            component={Link}
            to="/customers/exports"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-file-export" />
            <ListItemText
              primary="Exports"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem> */}
        </List>
      </Collapse>
      <ListItem button component={Link} to="/rv-adventure-club">
        <ListItemIcon className="fas fa-compass" />
        <ListItemText primary="RV Adventure Club" />
      </ListItem>
      <ListItem button component={Link} to="/opportunities/search">
        <ListItemIcon className="fas fa-thumbs-up" />
        <ListItemText primary="Opportunities" />
      </ListItem>
      <ListItem button component={Link} to="/deallog">
        <ListItemIcon className="fas fa-book" />
        <ListItemText primary="Deal Log" />
      </ListItem>
      <RequiresFeatureFlag flag="customersBeta">
        <ListItem button onClick={togglesMenu('notes')}>
          <ListItemIcon className="fas fa-pen-fancy" />
          <ListItemText primary="Notes" />
          {isMenuOpen('notes') ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isMenuOpen('notes')} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/notes/my-notes"
              className={classes.nested}
            >
              <ListItemIcon className="fas fa-user" />
              <ListItemText
                primary="My Notes"
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/notes/dealer-notes/"
              className={classes.nested}
            >
              <ListItemIcon className="fas fa-users" />
              <ListItemText
                primary="Store Notes"
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </List>
        </Collapse>
      </RequiresFeatureFlag>
      <ListItem button onClick={togglesMenu('inventory')}>
        <ListItemIcon className="fas fa-car" />
        <ListItemText primary="Inventory" />
        {isMenuOpen('inventory') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isMenuOpen('inventory')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            to="/inventory/dashboard"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-chart-area" />
            <ListItemText
              primary="Dashboard"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/inventory"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-list-ul" />
            <ListItemText
              primary="View"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/inventory/decode-wizard"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-magic" />
            <ListItemText
              primary="VIN Decode Wizard"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/inventory/cbb-wizard"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-bolt" />
            <ListItemText
              primary="Book Out Wizard"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/inventory/syndication"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-random" />
            <ListItemText
              primary="Syndication"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <RequiresRoles
            requiredRoles={RoleGroup.GROUP_INVENTORY_ROLE}
            renderOnSuccess={() => (
              <ListItem
                button
                component={Link}
                to="/inventory/group"
                className={classes.nested}
              >
                <ListItemIcon className="fas fa-layer-group" />
                <ListItemText
                  primary="Group Inventory"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            )}
            renderOnFailure={() => null}
          />
        </List>
      </Collapse>

      <ListItem button onClick={togglesMenu('leads')}>
        <ListItemIcon className="fas fa-briefcase" />
        <ListItemText primary="Leads" />
        {isMenuOpen('leads') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isMenuOpen('leads')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            to="/leads/dashboard"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-chart-area" />
            <ListItemText
              primary="Dashboard"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/leads"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-list-ul" />
            <ListItemText
              primary="View"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </Collapse>
      <RequiresRoles
        requiredRoles={RoleGroup.VIEW_PARTS_ROLE}
        renderOnSuccess={() => (
          <ListItem button component={Link} to="/parts">
            <ListItemIcon className="fas fa-tools" />
            <ListItemText primary="Parts" />
          </ListItem>
        )}
        renderOnFailure={() => null}
      />

      <ListItem button onClick={togglesMenu('service')}>
        <ListItemIcon className="fas fa-wrench" />
        <ListItemText primary="Service" />
        {isMenuOpen('service') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isMenuOpen('service')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <RequiresRoles
            requiredRoles={[
              ...RoleGroup.VIEW_SERVICE_APPOINTMENT_SETTINGS_ROLE,
            ]}
            renderOnSuccess={() => (
              <ListItem
                button
                component={Link}
                to="/rotracker/settings"
                className={classes.nested}
              >
                <ListItemIcon className="fas fa-calendar-alt" />
                <ListItemText
                  primary="Service Appointment Settings"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            )}
            renderOnFailure={() => null}
          />
          <ListItem
            button
            component={Link}
            to="/rotracker"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-car-crash" />
            <ListItemText
              primary="ROs"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={togglesMenu('sms')}>
        <ListItemIcon className="fas fa-comments" />
        <ListItemText primary="SMS" />
        {isMenuOpen('sms') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isMenuOpen('sms')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            to="/sms/gateways"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-comments" />
            <ListItemText
              primary="Gateways"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/sms/calls"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-voicemail" />
            <ListItemText
              primary="Call Recordings"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/sms/message-search"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-envelope" />
            <ListItemText
              primary="Message Search"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button onClick={togglesMenu('used-vehicles')}>
        <ListItemIcon className="fas fa-cars" />
        <ListItemText primary="Used Vehicles" />
        {isMenuOpen('used-vehicles') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isMenuOpen('used-vehicles')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            to="/used-vehicles"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-cars" />
            <ListItemText
              primary="Dashboard"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/used-vehicles/buy-now"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-sack-dollar" />
            <ListItemText
              primary="Buy Now"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button onClick={togglesMenu('tasks')}>
        <ListItemIcon className="fas fa-tasks" />
        <ListItemText primary="Tasks" />
        {isMenuOpen('tasks') ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={isMenuOpen('tasks')} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            to="/tasks"
            className={classes.nested}
          >
            <ListItemIcon className="fas fa-search" />
            <ListItemText
              primary="Search"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
          <RequiresRoles
            requiredRoles={[Role.CSR, ...RoleGroup.MANAGERS]}
            renderOnSuccess={() => (
              <ListItem
                button
                component={Link}
                to="/tasks/templates"
                className={classes.nested}
              >
                <ListItemIcon className="fas fa-tasks" />
                <ListItemText
                  primary="Task Templates"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            )}
            renderOnFailure={() => null}
          />
        </List>
      </Collapse>

      <RequiresDealerIds
        requiredDealerIds={RV_DEALERS}
        renderOnSuccess={() => (
          <>
            <ListItem button onClick={togglesMenu('npv')}>
              <ListItemIcon className="fas fa-rv" />
              <ListItemText primary="RV Inventory" />
              {isMenuOpen('npv') ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isMenuOpen('npv')} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem
                  button
                  component={Link}
                  to="/npv-inventory/dashboard"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-chart-area" />
                  <ListItemText
                    primary="Dashboard"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/npv-inventory/inventory"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-list-ul" />
                  <ListItemText
                    primary="View"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/npv-inventory/create-unit"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-plus" />
                  <ListItemText
                    primary="Create Unit"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/npv-inventory/syndication"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-random" />
                  <ListItemText
                    primary="Syndication"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        renderOnFailure={() => null}
      />

      <RequiresRoles
        requiredRoles={[Role.ORGANIZATION_ADMIN]}
        renderOnSuccess={() => (
          <>
            <ListItem button onClick={togglesMenu('vehicle-showroom')}>
              <ListItemIcon className="fas fa-car" />
              <ListItemText primary="Vehicle Showroom" />
              {isMenuOpen('vehicle-showroom') ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={isMenuOpen('vehicle-showroom')}
              timeout="auto"
              unmountOnExit
            >
              <List component="div">
                <ListItem
                  button
                  component={Link}
                  to="/vehicle-showroom/view"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-list-ul" />
                  <ListItemText
                    primary="View"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/vehicle-showroom/add-vehicle"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-plus" />
                  <ListItemText
                    primary="Add Vehicle"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        renderOnFailure={() => null}
      />

      <RequiresRoles
        requiredRoles={[Role.ORGANIZATION_ADMIN]}
        renderOnSuccess={() => (
          <>
            <ListItem button onClick={togglesMenu('admin')}>
              <ListItemIcon className="fas fa-gear" />
              <ListItemText primary="Admin" />
              {isMenuOpen('admin') ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isMenuOpen('admin')} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={Link}
                  to="/admin/pitch-settings"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-desktop" />
                  <ListItemText
                    primary="Pitch Sheets"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/admin/kiosks"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-desktop" />
                  <ListItemText
                    primary="Kiosks"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/admin/opportunities"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-thumbs-up" />
                  <ListItemText
                    primary="Opportunities"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/admin/used-vehicles"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-desktop" />
                  <ListItemText
                    primary="Used Vehicles"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/debug"
                  className={classes.nested}
                >
                  <ListItemIcon className="fas fa-desktop" />
                  <ListItemText
                    primary="Debug"
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        renderOnFailure={() => null}
      />
    </List>
  );
}

export default MainNav;
