import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { makeStyles, styled } from '@mui/styles';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';

import OptionalPitchSheets from './OptionalPitchSheets';
import DefaultAccessories from './DefaultAccessories';

const DEALER_SETTINGS_QUERY = gql`
  query dealerSettingsQuery($dealerId: Int!) {
    pitches {
      dealerSettings(dealerId: $dealerId) {
        id
        ...DefaultAccessoriesDealerSettings
        ...OptionalPitchSheetsDealerSettings
      }
    }
  }
  ${DefaultAccessories.fragments.dealerSettings}
  ${OptionalPitchSheets.fragments.dealerSettings}
`;

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft: '10px',
  },
  mobileTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  card: {
    marginTop: '30px',
  },
  cardContent: {
    marginLeft: '20px',
  },
  mobileCardContent: {
    marginLeft: '10px',
  },
}));

const PitchSheets = () => {
  const { dealerId } = useDealerContext();
  const classes = useStyles();
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));

  //   Shamelessly borrowed expand/collapse from MaterialUI!
  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} size="large" />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [expanded, setExpanded] = useState({
    expanded1: false,
    expanded2: false,
  });

  const handleExpandClick = panel =>
    setExpanded(prev => ({ ...prev, [panel]: !prev[panel] }));

  const {
    loading,
    data: dealerSettingsData,
    refetch,
  } = useQuery(DEALER_SETTINGS_QUERY, {
    variables: { dealerId },
  });

  return (
    <Box height="100%">
      <Box padding={1}>
        <DealerPicker />
      </Box>
      <Box
        padding={2}
        sx={
          mobile
            ? { minWidth: '600px', maxWidth: '80%' }
            : { minWidth: '450px', maxWidth: '90%' }
        }
      >
        <Typography className={classes.pageTitle}>Pitch Sheets</Typography>
        {/* **** Optional Pitch Sheets **** */}
        <Card className={classes.card}>
          <CardHeader
            title="Optional Pitch Sheet Settings"
            style={{ display: 'none' }}
          />
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded.expanded1}
              onClick={() => handleExpandClick('expanded1')}
              aria-expanded={expanded.expanded1}
              aria-label="show more"
              style={{ margin: 0 }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <Typography
              className={mobile ? classes.title : classes.mobileTitle}
            >
              Optional Pitch Sheet Settings
            </Typography>
          </CardActions>
          <Collapse in={expanded.expanded1} timeout="auto" unmountOnExit>
            <CardContent
              className={
                mobile ? classes.cardContent : classes.mobileCardContent
              }
            >
              <OptionalPitchSheets
                dealerId={dealerId}
                dealerSettingsData={dealerSettingsData}
                loading={loading}
              />
            </CardContent>
          </Collapse>
        </Card>
        {/* **** Pitch Sheet Accessories/Discounts **** */}
        <Card className={classes.card}>
          <CardHeader
            title="Default Pitch Accessories/Discounts"
            style={{ display: 'none' }}
          />
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded.expanded2}
              onClick={() => handleExpandClick('expanded2')}
              aria-expanded={expanded.expanded2}
              aria-label="show more"
              style={{ margin: 0 }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <Typography
              className={mobile ? classes.title : classes.mobileTitle}
            >
              Default Pitch Accessories/Discounts
            </Typography>
          </CardActions>
          <Collapse in={expanded.expanded2} timeout="auto" unmountOnExit>
            <CardContent
              className={
                mobile ? classes.cardContent : classes.mobileCardContent
              }
            >
              <DefaultAccessories
                dealerId={dealerId}
                dealerSettingsData={dealerSettingsData}
                loading={loading}
                refetch={refetch}
              />
            </CardContent>
          </Collapse>
        </Card>
      </Box>
    </Box>
  );
};

export default PitchSheets;
