import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import {
  Box,
  Grid,
  List,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';

const EditCurrentVehicleDetails = ({ vehicle }) => {
  const {
    year,
    make,
    model,
    showroomName,
    category,
    trim,
    freightPdi,
    styleId,
  } = vehicle;

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const typographyVariant = isDesktop ? 'body1' : 'body2';

  return (
    <Box component="section" m={4} sx={{ maxWidth: 1200 }}>
      <Typography variant="h6" component="h2" p={2}>
        <strong>{`Editing ${year} ${make} ${model}`}</strong>
      </Typography>
      <Paper elevation={4} p={2}>
        <List
          id="vehicle-details-list"
          sx={{
            maxWidth: 360,
            bgcolor: 'background.paper',
            margin: 'auto',
          }}
          component="div"
          subheader={
            <ListSubheader
              sx={{ textAlign: 'center' }}
              component="div"
              id="vehicle-details-list"
            >
              Current vehicle details:
            </ListSubheader>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <ListItemText primary="Showroom" secondary={showroomName} />
            </Grid>
            <Grid item xs={6} md={4}>
              <ListItemText primary="Category" secondary={category.name} />
            </Grid>
            <Grid item xs={6} md={4}>
              <ListItemText primary="Year" secondary={year} />
            </Grid>
            <Grid item xs={6} md={4}>
              <ListItemText primary="Make" secondary={make} />
            </Grid>
            <Grid item xs={6} md={4}>
              <ListItemText primary="Model" secondary={model} />
            </Grid>
            <Grid item xs={6} md={4}>
              <ListItemText primary="Trim" secondary={trim} />
            </Grid>
            <Grid item xs={6} md={4}>
              <ListItemText primary="Freight PDI" secondary={freightPdi} />
            </Grid>
            <Grid item xs={6} md={4}>
              <ListItemText primary="Style ID" secondary={styleId} />
            </Grid>
          </Grid>
        </List>
        <Typography
          align="center"
          variant={typographyVariant}
          p={isDesktop ? 0 : 2}
          gutterBottom={isDesktop}
        >
          <strong>Note</strong>: Ensure make, model, and trim match above
          details unless being updated.
        </Typography>
        <Typography
          align="center"
          variant={typographyVariant}
          p={isDesktop ? 0 : 2}
          gutterBottom={isDesktop}
        >
          Selecting a new year will require new make, model, and trim
          selections.
        </Typography>
      </Paper>
    </Box>
  );
};

export default EditCurrentVehicleDetails;

EditCurrentVehicleDetails.propTypes = {
  vehicle: PropTypes.shape({
    year: PropTypes.string.isRequired,
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    showroomName: PropTypes.string.isRequired,
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    trim: PropTypes.string.isRequired,
    freightPdi: PropTypes.string.isRequired,
    styleId: PropTypes.string.isRequired,
  }).isRequired,
};
