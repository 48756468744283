// TODO: this file is getting pretty big, time to split it into pieces?  Maybe?
export const isProduction = process.env.NODE_ENV === 'production';

export const URL = {
  appraisals: process.env.REACT_APP_APPRAISALS_URL,
  auctionsTime: process.env.REACT_APP_AUCTIONS_TIME_URL,
  auth_logout: process.env.REACT_APP_AUTH_LOGOUT_URL,
  auth: process.env.REACT_APP_AUTH_URL,
  cbb: process.env.REACT_APP_CBB_URL,
  dealers: process.env.REACT_APP_DEALERS_URL,
  marketGraph: process.env.REACT_APP_MARKET_GRAPH_URL,
  marketGraphLeadClaim: process.env.REACT_APP_MARKET_GRAPH_CLAIM_LEAD_URL,
  marketGraphWS: process.env.REACT_APP_MARKET_GRAPH_WS_URL,
  mobile: process.env.REACT_APP_SELF_URL,
  npvBrochure: process.env.REACT_APP_NPV_BROCHURE_URL,
  paveBase: process.env.REACT_APP_PAVE_BASE_URL,
  paveReports: process.env.REACT_APP_PAVE_REPORTS_URL,
  photos: process.env.REACT_APP_APPRAISAL_PHOTOS_URL,
  photoService: process.env.REACT_APP_PHOTO_SVC_URL,
  sms: process.env.REACT_APP_SMS_URL,
  smsWs: process.env.REACT_APP_SMS_WS_URL,
  twilioFlex: process.env.REACT_APP_FLEX_URL,
  vehicles: process.env.REACT_APP_VEHICLES_URL,
};

export const RESET_PASSWORD_URL = `${URL.auth}/request-password-reset`;
export const AUTHENTICATE_URL = `${process.env.REACT_APP_AUTH_URL}/authenticate`;
export const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
export const AUTH_CALLBACK_URL = `${document.location.origin}/login`;

export const CANADA_POST_API_KEY = process.env.REACT_APP_CANADA_POST_API_KEY;

export const SENTRY_DSN =
  'https://af9b5dee0e00434690550b4525d54a64@sentry.io/1372320';

export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;

// TODO: Doesn't seem like anything is using this, remove?
export const PhoneSize = {
  IPHONE5: {
    width: 320,
    height: 568,
  },
  IPHONE6: {
    width: 375,
    height: 667,
  },
};

export const DATE_FORMAT = 'YYYY-MMM-DD h:mm:ss a';
export const DATE_NO_TIME_FORMAT = 'MMM DD, YYYY';

export const AppointmentStatus = {
  ATTENDED: 'attended',
  CANCELLED: 'cancelled',
  NO_SHOW: 'no-show',
  UNKNOWN: 'unknown',
};

export const AppointmentTypes = {
  CONSULTATION: 1,
  DELIVERY: 4,
  FINANCE: 3,
  OTHER: 5,
  TEST_DRIVE: 2,
};

export const AppointmentTypesDisplay = {
  [AppointmentTypes.CONSULTATION]: 'Consultation',
  [AppointmentTypes.DELIVERY]: 'Delivery',
  [AppointmentTypes.FINANCE]: 'Finance',
  [AppointmentTypes.OTHER]: 'Other',
  [AppointmentTypes.TEST_DRIVE]: 'Test Drive',
};

export const TaskStatus = {
  CANCELLED: 2,
  COMPLETE: 1,
  TODO: 0,
};

const S = {
  APPROVED: 7,
  CARRYOVER: 11,
  CASH: 12,
  DELIVERED: 4,
  DESK: 1,
  FI: 2,
  FRESH: 0,
  LOST: 5,
  PENDING: 6,
  POSTED: 3,
  PRE_APP: 10,
  SIGNED: 8,
  TUBED: 9,
};

export const Status = {
  ...S,
  ALL: Object.values(S),
  CLOSED: [S.DELIVERED, S.TUBED, S.POSTED],
  COMPLETED: [S.DELIVERED, S.POSTED],
  DEALLOG_OPPS: [
    S.APPROVED,
    S.CARRYOVER,
    S.CASH,
    S.DELIVERED,
    S.PENDING,
    S.POSTED,
    S.PRE_APP,
    S.SIGNED,
    S.TUBED,
  ],
  DEALLOG_LOCKED: [S.DELIVERED, S.LOST, S.TUBED, S.POSTED],
  LOCKED: [S.TUBED, S.POSTED],
  //* Note: the order of the following is important!
  LOST_TIMELINE: [
    S.FRESH,
    S.LOST,
    S.PENDING,
    S.APPROVED,
    S.SIGNED,
    S.CARRYOVER,
    S.DELIVERED,
    S.POSTED,
  ],
  OPEN: [S.APPROVED, S.CARRYOVER, S.PRE_APP, S.SIGNED, S.PENDING, S.CASH],
  OPEN_OPPS: [
    S.APPROVED,
    S.CARRYOVER,
    S.CASH,
    S.DESK,
    S.FI,
    S.FRESH,
    S.PENDING,
    S.PRE_APP,
    S.SIGNED,
  ],
  SAVE_DEAL_STATUSES: [S.APPROVED, S.PENDING, S.SIGNED, S.CASH],
  //* Note: the order of the following is important!
  TIMELINE: [
    S.FRESH,
    S.DESK,
    S.PENDING,
    S.CASH,
    S.APPROVED,
    S.SIGNED,
    S.DELIVERED,
    S.POSTED,
  ],
};

export const StatusDisplay = {
  [Status.APPROVED]: 'Approved',
  [Status.CARRYOVER]: 'Carryover',
  [Status.CASH]: 'Cash',
  [Status.DELIVERED]: 'Delivered',
  [Status.DESK]: 'Sent to Desk',
  [Status.FI]: 'Sent to FI',
  [Status.FRESH]: 'Fresh up',
  [Status.LOST]: 'Lost',
  [Status.PENDING]: 'Pending',
  [Status.POSTED]: 'Posted',
  [Status.PRE_APP]: 'Pre App',
  [Status.SIGNED]: 'Signed',
  [Status.TUBED]: 'Tubed',
};

export const StatusSortingOrder = [
  S.FRESH,
  S.DESK,
  S.PRE_APP,
  S.PENDING,
  S.CASH,
  S.APPROVED,
  S.SIGNED,
  S.DELIVERED,
  S.TUBED,
  S.POSTED,
  S.CARRYOVER,
  S.LOST,
];

const RB = {
  ADMINISTRATION: 'administration',
  BDC_MANAGER: 'bdc_manager',
  BDC_REP: 'bdc_rep',
  CONTROLLER: 'controller',
  CSR: 'csr',
  FINANCE_DIRECTOR: 'finance_director',
  FINANCE_MANAGER: 'finance_manager',
  GAF_ANALYST: 'gaf_analyst',
  GENERAL_MANAGER: 'general_manager',
  INTERNET_SALES_REP: 'internet_sales_rep',
  INVENTORY_EDITOR: 'inventory_editor',
  ORGANIZATION_ADMIN: 'organization_admin',
  PARTS: 'parts',
  SALES_MANAGER: 'sales_manager',
  SALES_REP: 'sales_rep',
  SERVICE_ADVISOR: 'service_advisor',
  SERVICE_MANAGER: 'service_manager',
  SERVICE_TECHNICIAN: 'service_technician',
};

export const Role = {
  ...RB,
  ALL_BDC: [RB.BDC_MANAGER, RB.BDC_REP],
  ALL_FINANCE: [RB.FINANCE_DIRECTOR, RB.FINANCE_MANAGER],
  ALL_SALES_MANAGERS: [RB.GENERAL_MANAGER, RB.SALES_MANAGER], // GENERAL_MANAGER is like a super sales manager and therefore included
  ALL_SALES_REPS: [RB.INTERNET_SALES_REP, RB.SALES_REP],
  ALL_SALES: [RB.SALES_MANAGER, RB.SALES_REP, RB.INTERNET_SALES_REP],
  ALL_SERVICE: [RB.SERVICE_ADVISOR, RB.SERVICE_MANAGER, RB.SERVICE_TECHNICIAN],
};

export const RoleGroup = {
  ATTACH_GOCARD_ROLE: [
    ...Role.ALL_BDC,
    ...Role.ALL_SALES,
    Role.GENERAL_MANAGER,
    Role.ORGANIZATION_ADMIN,
    Role.SERVICE_ADVISOR,
    Role.SERVICE_MANAGER,
  ],
  AUCTION_ROLE: [
    Role.CONTROLLER,
    Role.GENERAL_MANAGER,
    Role.ORGANIZATION_ADMIN,
    Role.SALES_MANAGER,
    Role.FINANCE_DIRECTOR,
  ],
  DEAL_LOG_ROLE: [
    ...Role.ALL_BDC,
    ...Role.ALL_FINANCE,
    ...Role.ALL_SALES,
    Role.CONTROLLER,
    Role.GENERAL_MANAGER,
    Role.ORGANIZATION_ADMIN,
    Role.SERVICE_ADVISOR,
    Role.SERVICE_MANAGER,
  ],
  EDIT_LEAD_CONFIG_ROLE: [Role.ORGANIZATION_ADMIN, Role.GENERAL_MANAGER],
  GROUP_INVENTORY_ROLE: [
    ...Role.ALL_BDC,
    ...Role.ALL_FINANCE,
    ...Role.ALL_SALES,
    Role.CONTROLLER,
    Role.CSR,
    Role.GAF_ANALYST,
    Role.GENERAL_MANAGER,
    Role.INVENTORY_EDITOR,
    Role.ORGANIZATION_ADMIN,
  ],
  MANAGERS: [
    Role.BDC_MANAGER,
    Role.CONTROLLER,
    Role.FINANCE_DIRECTOR,
    Role.FINANCE_MANAGER,
    Role.GENERAL_MANAGER,
    Role.ORGANIZATION_ADMIN,
    Role.SALES_MANAGER,
  ],
  MANUALLY_CREATE_VEHICLE: [Role.GENERAL_MANAGER, Role.ORGANIZATION_ADMIN],
  SALES_PEOPLE: Role.ALL_SALES_REPS,
  SERVICE_PAYMENTS: [
    Role.GENERAL_MANAGER,
    Role.ORGANIZATION_ADMIN,
    Role.SALES_MANAGER,
    Role.SERVICE_ADVISOR,
    Role.SERVICE_MANAGER,
  ],
  SERVICE_PEOPLE: [Role.SERVICE_ADVISOR, Role.SERVICE_MANAGER],
  VIEW_LEAD_CONFIG_ROLE: [
    ...Role.ALL_BDC,
    ...Role.ALL_FINANCE,
    Role.CONTROLLER,
    Role.CSR,
    Role.GENERAL_MANAGER,
    Role.ORGANIZATION_ADMIN,
    Role.PARTS,
    Role.SALES_MANAGER,
    Role.SERVICE_ADVISOR,
    Role.SERVICE_MANAGER,
  ],
  VIEW_SERVICE_APPOINTMENT_SETTINGS_ROLE: [
    Role.GENERAL_MANAGER,
    Role.ORGANIZATION_ADMIN,
    Role.SERVICE_MANAGER,
  ],
  VIEW_PARTS_ROLE: [Role.GENERAL_MANAGER, Role.ORGANIZATION_ADMIN, Role.PARTS],
  RESET_VEHICLE_RATES: [Role.ORGANIZATION_ADMIN, Role.SALES_MANAGER],
  VIEW_OPPORTUNITY_ATTACHMENTS: [
    ...Role.ALL_FINANCE,
    Role.SALES_MANAGER,
    Role.GENERAL_MANAGER,
    Role.ORGANIZATION_ADMIN,
    Role.SERVICE_ADVISOR,
    Role.SERVICE_MANAGER,
  ],
  DELETE_OPPORTUNITY_ATTACHMENTS: [
    ...Role.ALL_FINANCE,
    Role.SALES_MANAGER,
    Role.GENERAL_MANAGER,
    Role.ORGANIZATION_ADMIN,
  ],
  DIGITAL_PITCH_MESSAGING: [Role.SALES_MANAGER, Role.ORGANIZATION_ADMIN],
};

export const RoleBadgeDisplay = {
  [Role.SALES_REP]: 'SP',
  [Role.SALES_MANAGER]: 'SM',
  [Role.FINANCE_MANAGER]: 'FP',
  [Role.INTERNET_SALES_REP]: 'IS',
  [Role.BDC_REP]: 'BDC',
  [Role.BDC_MANAGER]: 'BDCM',
  [Role.CSR]: 'CSR',
  [Role.FINANCE_DIRECTOR]: 'FD',
  [Role.GENERAL_MANAGER]: 'GM',
  [Role.ORGANIZATION_ADMIN]: 'OA',
};

export const STATUS_ICON = {
  [Status.APPROVED]: {
    color: '#6fa8dc',
    icon: 'fa fa-check',
  },
  [Status.CARRYOVER]: {
    color: '#7fbfbf',
    icon: 'fa fa-flag',
  },
  [Status.CASH]: {
    color: 'green',
    icon: 'fa fa-dollar-sign',
  },
  [Status.DELIVERED]: {
    color: '#999999',
    icon: 'fa fa-flag-checkered',
  },
  [Status.DESK]: {
    color: '#f89406',
    icon: 'fa fa-clipboard-check',
  },
  [Status.FI]: {
    color: '#f89406',
    icon: 'fa fa-credit-card',
  },
  [Status.FRESH]: {
    color: '#8ba22c',
    icon: 'fa fa-asterisk',
  },
  [Status.LOST]: {
    color: '#b48484',
    icon: 'fa fa-thumbs-down',
  },
  [Status.PENDING]: {
    color: '#71a8dc',
    icon: 'fa fa-clock',
  },

  [Status.POSTED]: {
    color: '#9585bf',
    icon: 'fa fa-thumbs-up',
  },
  [Status.PRE_APP]: {
    color: 'black',
    icon: 'fa fa-copy',
  },
  [Status.SIGNED]: {
    color: '#6fa8dc',
    icon: 'fa fa-pencil',
  },
  [Status.TUBED]: {
    color: '#b48484',
    icon: 'fa fa-thumbs-down',
  },
};

export const DealType = {
  New: 'New',
  Unknown: 'Unknown',
  Used: 'Used',
};

export const DealTypes = Object.values(DealType);

export const FilterType = {
  DEAL_TYPE: 'dealType',
  FINANCE_MANAGER: 'financeManager',
  HIDE_CARRYOVER: 'hideCarryover',
  HIDE_TUBED: 'hideTubed',
  RDR_PUNCHED: 'rdrPunched',
  SALES_MANAGER: 'salesManager',
  SALES_PERSON: 'salesPerson',
  SEARCH: 'search',
  STATUS: 'status',
  SUB_STATUS: 'subStatus',
  WITH_VARIANCE: 'withVariance',
};

export const NoteVisibility = {
  MANAGERS_ONLY: 'managers_only',
  ORG_WIDE: 'organization_wide',
  STORE_ONLY: 'store_only',
};

export const NoteVisibilityDisplay = {
  [NoteVisibility.MANAGERS_ONLY]: 'Managers Only',
  [NoteVisibility.ORG_WIDE]: 'Organization Wide',
  [NoteVisibility.STORE_ONLY]: 'Store Only',
};

export const notificationTimeout = 10000;

export const cloudinaryConfig = {
  api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
  api_secret: process.env.REACT_APP_CLOUDINARY_API_SECRET,
  cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
};

export const tinyMCEApiUrl = process.env.REACT_APP_TINYMCE_API_KEY;
export const snackBarSettings = {
  autoHideDuration: 1200,
};
// 2484 = -Troy's TEST Profile (test org), 5765 = 2213931 Alberta Ltd. (goauto org)
export const WHOLESALER_DEALER_IDS = [2484, 5765];

// Tags for attachments - Used in Inventory & Appraisals
export const TAGS = [
  ['UVI', 'Used Vehicle Inspection'],
  ['VHR', 'Vehicle History Report'],
  ['CFAX', 'Carfax'],
  ['OoTI', 'Out of Town Inspection'],
  ['AMVIC', 'AMVIC'],
  ['OTHER', 'Other'],
];

// Go Card Hash Key to determine Referral Code given ID
export const REFCODE_KEY = '5GPUS83LEWJ4DX7KCARV2OMN9YFQB6Z1HTI';

// Organization ids
export const GOAUTO_ORG_ID = 'goauto';
export const TEST_ORG_ID = 'test';

// Grease dealer group ids
export const GOAUTO_GROUP_ID = 1;
export const TEST_DEALERS_GROUP_ID = 4;

// Photo params for RO photos
export const cloudinaryRoParams = {
  width: 640,
  height: 480,
  resource_type: 'image',
  crop: 'scale',
};

// Inspection forms - dealer ids by make (4125 included for test)
export const AUDI_DEALER_IDS = [5946, 4125];
export const AURORA_DODGE_DEALER_IDS = [318, 4125];
export const BC_DEALER_IDS = [400, 3893, 5941, 2244, 2250, 5946, 5950]; // For exclusion
export const BMW_DEALER_IDS = [5949, 4125];
export const DODGE_DEALER_IDS = [70, 3893, 5385, 4125];
export const FORD_DEALER_IDS = [56, 71, 192, 272, 986, 2457, 5375, 5464, 4125];
export const GCC_DEALER_IDS = [400, 4125];
export const GDS_DEALER_IDS = [3893, 4125];
export const GNSGM_DEALER_IDS = [5941, 4125];
export const HONDA_DEALER_IDS = [18, 253, 270, 5956, 5957, 4125];
export const HYUNDAI_DEALER_IDS = [22, 57, 5341, 4125];
export const INFINITI_DEALER_IDS = [4101, 4125];
export const JLR_DEALER_IDS = [133, 331, 2250, 2811, 4125];
export const KELOWNA_DEALER_ID = [5950, 4125];
export const KIA_DEALER_IDS = [2991, 4125];
export const NISSAN_DEALER_IDS = [66, 2993, 5728, 4125];
export const RV_DEALER_IDS = [
  281, 4125, 4304, 4467, 4468, 5189, 5333, 5502, 5961, 5964,
];
export const RVRD_DEALER_IDS = [4467, 4125];
export const TOYOTA_ON_THE_TRAIL_DEALER_ID = [52, 4125];
export const VW_DEALER_IDS = [36, 2244, 5725, 4125];

// Richmond Chrysler, Audi Richmond, Volkswagen of Richmond, JLR Richmond, 4125 for testing
export const RICHMOND_AUTO_MALL_DEALER_IDS = [5960, 5946, 2244, 2250, 4125];

export const GSP_DEALER_ID = 5953;

// Deal Cover Sheet dealer ids (Strathcom Test, JLR Calgary, Airdrie Honda, T&T Honda, MGM FL, Richmond Chrysler, Audi Royal Oak)
export const DEAL_COVER_SHEET_DEALERS = [777, 133, 5957, 5956, 272, 5960, 5965];

export const MGM_FORD_LINCOLN_DEALER_ID = 272;

export const H2H_DEALER_ID = 4175; // Happy to Help
export const PCE_DEALER_ID = 2987;

// Know Your Customer PDF types are assigned a number so we can determine which PDF to generate in CRM
export const KNOW_YOUR_CUSTOMER_PDF_TYPES = {
  ford_individual: 0,
  ford_business: 1,
  lincoln_individual: 2,
  lincoln_business: 3,
};

export const LOST_REASONS = [
  'Advance too high on unit',
  'Bad Contact Information',
  'Bought Elsewhere',
  'Bought Private',
  'Credit - Limited',
  'Credit - Poor',
  'Duplicate Entry',
  'Do Not Follow Up',
  'No Longer Interested',
  'Out of Market',
  'Sales Manager',
  'Salesman',
  'Service Only',
  'Trade Value',
  'Unable to Finance',
  'Unable to Make Contact',
  'Vehicle - not available',
  'Vehicle - Purchased',
  'Vehicle - Too Much Unit',
  'Other',
];

// Constants for Tab Headers throughout Market Mobile
const OPPORTUNITY_MAP = {
  details: 'Opportunities - Details',
};

const LEAD_MAP = {
  details: 'Leads - Details',
};

const ROTRACKER_MAP = {
  details: 'Service - Details',
};

const SMS_MAP = {
  calls: 'SMS - Calls',
  gateways: 'SMS - Gateways',
};

const USED_VEHICLES_MAP = {
  appraisals: 'Used Vehicles - Details',
  'buy-now': 'Used Vehicles - Buy Now',
};

const APPOINTMENTS_MAP = {
  delivery: 'Appointments - Deliveries',
  service: 'Appointments - Service',
};

const INVENTORY_MAP = {
  cbb: 'Inventory - CBB Wizard',
  dashboard: 'Inventory - Dashboard',
  decode: 'Inventory - Decode',
  group: 'Inventory - Group',
  syndication: 'Inventory - Syndication',
};

const CUSTOMERS_MAP = {
  details: 'Customers - Details',
  search: 'Customers - Search',
};

export const pathways = {
  'used-vehicles': USED_VEHICLES_MAP,
  appointments: APPOINTMENTS_MAP,
  auctions: {},
  customers: CUSTOMERS_MAP,
  dashboard: {},
  deallog: {},
  inventory: INVENTORY_MAP,
  leads: LEAD_MAP,
  opportunities: OPPORTUNITY_MAP,
  rotracker: ROTRACKER_MAP,
  sms: SMS_MAP,
  tasks: {},
};

export const pathwayDefaults = {
  'used-vehicles': 'Used Vehicles - Dashboard',
  appointments: 'Appointments',
  auctions: 'Auctions',
  customers: 'Customers',
  dashboard: 'Dashboard',
  deallog: 'Deal Log',
  inventory: 'Inventory',
  leads: 'Leads',
  opportunities: 'Opportunities',
  rotracker: 'Service',
  sms: 'SMS',
  tasks: 'Tasks',
};

export const RV_DEALERS = [
  281, 4125, 4304, 4467, 4468, 5189, 5333, 5502, 5961, 5964,
];

export const RV_ADVENTURE_CLUB_CHOICES = [
  { code: 'RV_FIREW', text: 'Wood' },
  { code: 'RV_PROPA', text: 'Propane' },
  { code: 'RV_BATHR', text: 'Bathroom Essentials' },
];

export const GOCARD_GIFT_CODES = {
  gift: { code: 'GIFT', text: 'GIFT' },
  rvCash: { code: 'RV_CASH', text: 'RV DOLLARS' },
};

export const GOCARD_REDEEM_CODES = {
  redeemParts: { code: 'REDEEM_P', text: 'Parts & Service' },
  redeemVehicle: { code: 'REDEEM_V', text: 'Vehicle' },
  redeemRV: { code: 'PREPAYRV', text: 'RV Prepaid' },
};

export const CONFIRMED_STATUSES = ['EXTERNAL_PROVIDER', 'CONFIRMED'];

// SMS Message Directions
export const MESSAGE_DIRECTIONS = {
  inbound: 'inbound',
  outbound: 'outbound',
};

// SMS Message Statuses
export const MESSAGE_STATUSES = {
  delivered: 'Delivered',
  queued: 'Sending...',
  failed: 'Undelivered',
};

export const Regexps = {
  PhoneFormat: {
    /**
     * @constant {RegExp}
     * For NANP phone numbers. Matches in the following order:
     * - `+1` or `1` prefix (optional)
     * - `-`, `.`, or whitespace (optional)
     * - `(` (optional)
     * - A digit from 2 to 9, followed by 2 digits (area code, captured)
     * - `)` (optional)
     * - `-`, `.`, or whitespace (optional)
     * - A digit from 2 to 9, followed by 2 digits except for `11` (exchange code, captured)
     * - `-`, `.`, or whitespace (optional)
     * - 4 digits (station code, captured)
     */
    NANP: /^(?:\+?1)?[-\s.]?\(?([2-9]\d{2})\)?[-\s.]?([2-9](?!11)\d{2})[-\s.]?(\d{4})$/,
    /**
     * @constant {RegExp}
     * Simplified version of E164. Excludes NANP numbers. Matches in the following order:
     * - `011` or `+` prefix
     * - A digit from 2 to 9, followed by 1 to 14 digits (captured)
     */
    E164: /^(?:\+|011)([2-9]\d{1,14})$/,
    // This seems stupid, can't the computer combine these two? Lame.
    ALL: /^(?:(?:\+?1)?[-\s.]?\(?([2-9]\d{2})\)?[-\s.]?([2-9](?!11)\d{2})[-\s.]?(\d{4})|(?:\+|011)([2-9]\d{1,14}))$/,
  },
  PostalCodeFormat: {
    /**
     * @constant {RegExp}
     * Case sensitive strict match for a Canadian Postal Code.  Can not have leading spaces,
     * trailing spaces, must have one space in between FSA (first three) and LDU (last three).
     * Matches to [FSA, LDU].
     */
    STRICT:
      /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) (\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
    /**
     * @constant {RegExp}
     * Case insensitive match for a Canadian Postal Code.  Can not have leading spaces,
     * trailing spaces, but can have multiple spaces (or a dash - ew!) in between FSA
     * (first three) and LDU (last three).  Matches to [FSA, LDU].
     */
    RELAXED:
      /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])[ -]*(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i,
  },
  EmailFormat: /^(?:.+@.+\..+|)$/,
  Vin: {
    /**
     * @constant {RegExp}
     * Case-sensitive match for a VIN if it's the only thing on a line.
     * `1G1AF1F57A7192174` would match, but `some 1G1AF1F57A7192174 thing` would not.
     */
    STRICT: /^[(A-H|J-N|P|R-Z|0-9)]{17}$/,
    /**
     * @constant {RegExp}
     * Case-insensitive match for a VIN as a standalone word in a line.
     * Both `1G1AF1F57A7192174` and `some 1G1AF1F57A7192174 thing` would match.
     */
    RELAXED: /\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/i,
  },
};
