import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import DealerPicker from 'components/MaterialUI/DealerPicker';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import Loading from 'components/MaterialUI/Loading';
import { ConfirmDialog } from 'components/MaterialUI';

// Material UI
import {
  Box,
  Fab,
  Grid,
  List,
  ListItem,
  Typography,
  Tooltip,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

// Internal
import VehicleShowroomDesktopTable from './VehicleShowroomDesktopTable';
import VehicleShowroomMobileTable from './VehicleShowroomMobileTable';
import NoShowroomsView from './NoShowroomsView';

import {
  createShowroomVehicleRowData,
  getShowroomIdsForDealer,
} from '../../utils';
import { ROWS_PER_PAGE_OPTIONS, DEFAULT_ROWS_PER_PAGE } from '../../constants';

import { GET_ALL_SHOWROOMS_VEHICLES_BULK_QUERY } from '../../graphql/queries';
import { DELETE_VEHICLE, BULK_DELETE_VEHICLES } from '../../graphql/mutations';

const useStyles = makeStyles(() => ({
  title: { fontSize: '24px', fontWeight: '700' },
  mobileFabButton1: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
  },
  mobileFabButton2: {
    position: 'fixed',
    bottom: '80px',
    right: '10px',
  },
}));

const VehicleShowroomListView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { dealerId, dealers } = useDealerContext();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [vehiclesToDelete, setVehiclesToDelete] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const openDeleteModal = vehicles => {
    setVehiclesToDelete(vehicles);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setVehiclesToDelete([]);
    setIsModalOpen(false);
  };

  const [deleteVehicleMutation] = useMutation(DELETE_VEHICLE);
  const [bulkDeleteVehiclesMutation] = useMutation(BULK_DELETE_VEHICLES);

  const dealerName = dealers.find(x => x.dealer_id === dealerId)?.dealer_name;

  const [
    getAllShowroomsVehicles,
    {
      data: showroomsVehiclesData,
      loading: showroomsVehiclesDataLoading,
      error: showroomsVehiclesDataError,
    },
  ] = useLazyQuery(GET_ALL_SHOWROOMS_VEHICLES_BULK_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      ids: getShowroomIdsForDealer(dealerId),
    },
  });

  useEffect(() => {
    getAllShowroomsVehicles();
  }, []);

  const allShowroomsVehiclesDataResults =
    showroomsVehiclesData?.showroom?.showrooms || [];

  if (allShowroomsVehiclesDataResults.length === 0) {
    if (showroomsVehiclesDataLoading) return <Loading />;
    return <NoShowroomsView dealerName={dealerName} />;
  }

  const allShowroomsVehiclesDataRows = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < allShowroomsVehiclesDataResults.length; i++) {
    const showroom = allShowroomsVehiclesDataResults[i];
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < showroom.vehicles.length; j++) {
      const {
        id,
        category,
        year,
        make,
        model,
        style,
        style_id,
        freight_pdi,
        showroom_name,
      } = showroom.vehicles[j];
      allShowroomsVehiclesDataRows.push(
        createShowroomVehicleRowData(
          id,
          category.name,
          year,
          make,
          model,
          style.trim,
          style_id,
          freight_pdi,
          showroom_name,
        ),
      );
    }
  }

  const handleConfirmDelete = async () => {
    try {
      if (vehiclesToDelete.length === 1) {
        await deleteVehicleMutation({
          variables: { deleteVehicleFromShowroomId: vehiclesToDelete[0].id },
        }).then(() => {
          enqueueSnackbar('Vehicle deleted successfully', {
            variant: 'success',
          });
        });
      } else {
        await bulkDeleteVehiclesMutation({
          variables: { vehicleIds: vehiclesToDelete.map(v => v.id) },
        }).then(() => {
          enqueueSnackbar('Vehicles deleted successfully', {
            variant: 'success',
          });
        });
      }
      closeDeleteModal();
      // Refresh or update the UI
      getAllShowroomsVehicles();
    } catch (error) {
      enqueueSnackbar('Error deleting vehicle(s)', { variant: 'error' });
      // eslint-disable-next-line no-console
      console.error('Error deleting vehicle(s):', error);
    }
  };

  if (showroomsVehiclesDataLoading) return <Loading />;
  if (showroomsVehiclesDataError)
    return <div>Error: {showroomsVehiclesDataError.message}</div>;

  return (
    <>
      <Box component="section" p={2}>
        <DealerPicker />
      </Box>
      <Box component="section" p={2} mb={2}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item>
            <Typography mt={2} className={clsx(classes.title)}>
              {dealerName} - Showroom Vehicles
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Add a vehicle to a showroom">
              <Fab
                className={clsx({
                  [classes.mobileFabButton1]: !isDesktop,
                })}
                color="primary"
                onClick={() => history.push('add-vehicle')}
                style={{ backgroundColor: green[500] }}
                size="medium"
              >
                <AddIcon fontSize="large" />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      {isDesktop ? (
        <VehicleShowroomDesktopTable
          rowsPerPageOptions={Object.values(ROWS_PER_PAGE_OPTIONS)}
          defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
          rows={allShowroomsVehiclesDataRows}
          openDeleteModal={openDeleteModal}
        />
      ) : (
        <VehicleShowroomMobileTable
          cards={allShowroomsVehiclesDataRows}
          openDeleteModal={openDeleteModal}
        />
      )}
      <ConfirmDialog
        isOpen={isModalOpen}
        onConfirm={handleConfirmDelete}
        onClose={closeDeleteModal}
        abortText="Cancel"
        abortButtonProps={{ color: 'primary' }}
        confirmText="Delete"
        confirmButtonProps={{ color: 'error', variant: 'contained' }}
        titleText={
          vehiclesToDelete.length > 1
            ? 'Confirm Bulk Deletion'
            : 'Confirm Deletion'
        }
        text={
          vehiclesToDelete.length > 1
            ? `Are you sure you want to delete the following ${vehiclesToDelete.length} vehicles?`
            : 'Are you sure you want to delete this vehicle?'
        }
        content={
          <List>
            {vehiclesToDelete.map(({ id, year, make, model }) => (
              <ListItem key={id}>
                {year} {make} {model} (ID: {id})
              </ListItem>
            ))}
          </List>
        }
      />
    </>
  );
};

export default VehicleShowroomListView;
