import React from 'react';

import { Alert, AlertTitle, Box } from '@mui/material';

import { LogType } from './constants';
import { LogEntryAlert } from './LogEntryAlert';

export function LogBox({
  entries: origEntries,
  isPaymentRequestCancelled,
  hasPendingPayments,
  transactionsById,
}) {
  // TODO: do we want to show more than just SMS/payment entries?
  const entries = origEntries
    .filter(
      entry => entry.type === LogType.SMS || entry.type === LogType.PAYMENT,
    )
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  return (
    <Box>
      {isPaymentRequestCancelled && (
        <Box
          style={{
            paddingBottom: '1rem',
          }}
        >
          <Alert severity="warning">
            <AlertTitle>Payment Request Cancelled</AlertTitle>
          </Alert>
        </Box>
      )}
      {hasPendingPayments && (
        <Box
          style={{
            paddingBottom: '1rem',
          }}
        >
          <Alert severity="warning">
            <AlertTitle>RO has pending payment(s)</AlertTitle>
          </Alert>
        </Box>
      )}
      {entries.map(entry => (
        <Box
          key={entry.id}
          style={{
            paddingBottom: '1rem',
          }}
        >
          <LogEntryAlert entry={entry} transactionsById={transactionsById} />
        </Box>
      ))}
    </Box>
  );
}
