/* external */
import React from 'react';
import { useFormContext } from 'react-hook-form';

/* Material UI */
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

const AccessoryLineItem = ({ type, removeHandler, item, index }) => {
  const formContext = useFormContext();
  const {
    register,
    formState: { errors },
  } = formContext;

  return (
    <Box>
      <input type="hidden" defaultValue={item.id} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} md={8}>
          <TextField
            {...register(`vehicles.0.${type}.${index}.title`)}
            label={type === 'accessories' ? 'Accessory' : 'Discount'}
            error={Boolean(errors?.vehicles?.[0]?.[type]?.[index]?.title)}
            helperText={errors?.vehicles?.[0]?.[type]?.[index]?.title?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={5} md={2}>
          <TextField
            {...register(`vehicles.0.${type}.${index}.price`)}
            label="Price"
            type="number"
            inputProps={{ step: 0.01 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            error={Boolean(errors?.vehicles?.[0]?.[type]?.[index]?.price)}
            helperText={errors?.vehicles?.[0]?.[type]?.[index]?.price?.message}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            size="small"
            onClick={() => removeHandler(index)}
            style={{ backgroundColor: 'red', color: 'white' }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccessoryLineItem;
