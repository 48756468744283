import gql from 'graphql-tag';

export const vssVehicleFragment = gql`
  fragment VehicleFields on ShowroomVehicle {
    id
    showroom_id
    showroom_name
    category {
      id
      name
    }
    year
    make
    model
    style {
      trim
    }
    freight_pdi
    style_id
  }
`;
