import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import PitchSheetFormDialog from './PitchSheetFormDialog';

const DELETE_OPTIONAL_PITCH_SHEET = gql`
  mutation deleteOptionalPitchSheet($dealerId: Int!, $pitchSheetId: Int!) {
    pitches {
      deleteOptionalPitchSheet(
        dealerId: $dealerId
        pitchSheetId: $pitchSheetId
      ) {
        id
        optionalPitchSheets {
          id
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  addButton: {
    color: 'white',
    backgroundColor: theme.actions.confirm.backgroundColor,
  },
}));

const OptionalPitchSheets = ({ dealerId, dealerSettingsData, loading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [selectedPitchId, setSelectedPitchId] = useState(null);
  const [addPitchSheets, setAddPitchSheets] = useState(false);

  const [deletePitchSheet, { loading: deleteLoading }] = useMutation(
    DELETE_OPTIONAL_PITCH_SHEET,
    {
      onCompleted: data =>
        enqueueSnackbar(`Successfully deleted optional pitch sheet!`, {
          variant: 'success',
        }),
    },
  );

  const dealerSettings = dealerSettingsData?.pitches?.dealerSettings ?? {};
  const optionalPitchSheets = dealerSettings?.optionalPitchSheets ?? [];
  return (
    <Box height="100%">
      <LoadingBackdrop open={loading}>Loading...</LoadingBackdrop>
      <LoadingBackdrop open={deleteLoading}>
        Deleting pitch sheet...
      </LoadingBackdrop>
      <ConfirmDialog
        isOpen={confirmDelete}
        onConfirm={() =>
          deletePitchSheet({
            variables: { dealerId, pitchSheetId: selectedPitchId },
          })
        }
        text="Are you sure you want to delete this additional pitch sheet"
        onClose={() => setConfirmDelete(null)}
      />
      <PitchSheetFormDialog
        dealerSettings={dealerSettings ?? {}}
        open={addPitchSheets}
        onClose={() => setAddPitchSheets(false)}
      />
      <Grid
        container
        direction="row"
        style={{ padding: theme.spacing(2), display: 'inline-flex' }}
        spacing={1}
      >
        <Grid item xs={12}>
          <IconButton
            onClick={() => setAddPitchSheets(true)}
            size="large"
            className={classes.addButton}
          >
            <AddIcon />
          </IconButton>
        </Grid>
        {optionalPitchSheets.map(pitchSheet => (
          <Grid
            item
            sm={12}
            md={6}
            lg={4}
            xl={3}
            key={pitchSheet.id}
            style={{ height: '100%', width: '100%' }}
          >
            <Card variant="outlined" key={pitchSheet.id}>
              <CardContent style={{ paddingBottom: '0px' }}>
                <Typography variant="body1">
                  {pitchSheet?.pitchSheetDealer?.name ?? ''} Pitch Sheet
                </Typography>
                <Checkbox checked={pitchSheet.allowNewVehicles} disabled />{' '}
                Allow for New Vehicles
                <br />
                <Checkbox
                  checked={pitchSheet.allowUsedVehicles}
                  disabled
                />{' '}
                Allow for Used Vehicles
              </CardContent>
              <CardActions
                style={{ paddingTop: '0px', justifyContent: 'right' }}
              >
                <IconButton
                  onClick={() => {
                    setSelectedPitchId(pitchSheet.id);
                    setConfirmDelete(true);
                  }}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

OptionalPitchSheets.fragments = {
  dealerSettings: gql`
    fragment OptionalPitchSheetsDealerSettings on PitchDealerSetting {
      id
      dealerId
      dealer {
        name
      }
      optionalPitchSheets {
        id
        allowNewVehicles
        allowUsedVehicles
        pitchSheetDealerId
        pitchSheetDealer {
          name
        }
      }
    }
  `,
};

export default OptionalPitchSheets;
