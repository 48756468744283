import React from 'react';

import DealerPicker from 'components/MaterialUI/DealerPicker';

import { Box, Typography } from '@mui/material';

export default function NoShowroomsView({ dealerName }) {
  return (
    <>
      <Box component="section" p={2}>
        <DealerPicker />
      </Box>
      <Box component="section" p={2} mb={2}>
        <Typography mt={2} style={{ fontSize: '24px', fontWeight: '700' }}>
          {dealerName} - Showroom Vehicles
        </Typography>
        <Typography mt={2} variant="body1">
          <strong>
            Oops! You don't have a showroom created for your dealer.
          </strong>
        </Typography>
        <Typography mt={2} variant="body1">
          Please ask Market Support if you require a showroom.
        </Typography>
      </Box>
    </>
  );
}
