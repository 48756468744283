import React, { useState } from 'react';

/* external */
import { isEmpty } from 'lodash';

/* Material UI */
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const ascSorter = ({ value: a }, { value: b }) =>
  a ? (b || '').localeCompare(a) : 1;

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  section: {
    paddingBottom: '10px',
    outlineColor: 'black',
  },
  subPanel: {
    width: '100%',
  },
}));

const FilterDrawer = ({
  facetResults,
  openedAtFromFilter,
  openedAtToFilter,
  selectedFacets,
  setOpenedAtFromFilter,
  setOpenedAtToFilter,
  setSelectedFacets,
  loading,
  type,
  paymentsConfigured,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const handleExpansion = () => {
    setExpanded(!expanded);
  };

  const RO_FACET_OPTIONS = [
    {
      field: 'status',
      model: 'ServiceRO',
      options: {
        sort: ascSorter,
      },
      title: 'Status',
    },
    {
      field: 'cdk_service_advisor_display_name',
      model: 'ServiceRO',
      options: {
        sort: ascSorter,
      },
      title: 'Service Advisor',
    },
  ];

  if (paymentsConfigured)
    RO_FACET_OPTIONS.push({
      field: 'last_transaction_payment_status',
      model: 'ServiceRO',
      options: {
        sort: ascSorter,
      },
      title: 'Payment Status',
    });

  const VIDEO_FACET_OPTIONS = [
    {
      field: 'created_by_display_name',
      model: 'Video',
      options: {
        sort: ascSorter,
      },
      title: 'Added by',
    },
  ];

  const PHOTO_FACET_OPTIONS = [
    {
      field: 'created_by_display_name',
      model: 'Photo',
      options: {
        sort: ascSorter,
      },
      title: 'Added by',
    },
  ];

  const APPOINTMENT_FACET_OPTIONS = [
    {
      field: 'cdk_service_advisor_display_name',
      model: 'ServiceRO',
      options: {
        sort: ascSorter,
      },
      title: 'Service Advisor',
    },
  ];
  const isAppointment = type === 'appointments';

  const FACET_OPTIONS =
    type === 'ros'
      ? RO_FACET_OPTIONS
      : type === 'videos'
      ? VIDEO_FACET_OPTIONS
      : type === 'photos'
      ? PHOTO_FACET_OPTIONS
      : APPOINTMENT_FACET_OPTIONS;

  const deleteAllFacets = () => {
    if (!isAppointment) {
      setSelectedFacets([
        {
          model: 'ServiceRO',
          field: 'is_open',
          value: 'True',
          options: { label: 'Open ROs' },
        },
      ]);
      setOpenedAtFromFilter(null);
      setOpenedAtToFilter(null);
    }
  };

  const getFacetResults = (model, field, options = {}) =>
    facetResults
      .find(x => x.field === field && (!model || x.model === model))
      ?.data.sort(options.sort || (_ => 0))
      .map(({ value, ...rest }) => ({
        value: (options.format || (x => x))(value),
        ...rest,
      })) || [];

  const isSelected = (_model, _field, _value) =>
    selectedFacets.findIndex(
      ({ model, field, value }) =>
        model === _model && field === _field && value === _value,
    ) !== -1;

  const deleteFacet = (_model, _field, _value) => {
    setSelectedFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );
  };

  const setFacet = (model, field, value, options) => {
    setSelectedFacets(prev => [...prev, { model, field, value, options }]);
  };

  const toggleFacet = (model, field, value, options) => {
    if (isSelected(model, field, value)) deleteFacet(model, field, value);
    else setFacet(model, field, value, options);
  };

  const swapFacet = (model, field, value, options) => {
    // Delete opposite facet if it exists
    deleteFacet(model, field, value === 'True' ? 'False' : 'True');
    // Toggle current facet
    toggleFacet(model, field, value, options);
  };

  const isFacetInResults = (model, field, value) =>
    getFacetResults(model, field).findIndex(x => x.value === value) !== -1;

  const showReset =
    !isEmpty(selectedFacets) || openedAtFromFilter || openedAtToFilter;
  return (
    <List className={classes.panel}>
      <Box className={classes.title}>Filters</Box>
      {showReset && (
        <Box style={{ paddingBottom: '10px' }}>
          <Button style={{ color: 'red' }} onClick={deleteAllFacets}>
            <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
          </Button>
        </Box>
      )}
      {FACET_OPTIONS.map(({ field, model, title, options = {} }) => (
        <Box style={{ paddingBottom: '10px' }} key={`${field}${model}${title}`}>
          <Accordion key={title}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {title}
            </AccordionSummary>
            <AccordionDetails>
              <List component="div" disablePadding className={classes.subPanel}>
                {getFacetResults(model, field, options).map(
                  ({ count, value }) => (
                    <ListItem
                      disabled={loading}
                      dense
                      button
                      onClick={() => toggleFacet(model, field, value, options)}
                      key={`${model}${field}${value}`}
                      selected={isSelected(model, field, value)}
                    >
                      {isSelected(model, field, value) ? (
                        <DoneIcon
                          style={{
                            fontSize: 'small',
                            paddingRight: '10px',
                            width: '25px',
                          }}
                        />
                      ) : (
                        <div style={{ paddingRight: '10px', width: '25px' }}>
                          {' '}
                        </div>
                      )}
                      <ListItemText
                        primary={`${value || 'Unknown'} (${count})`}
                      />
                    </ListItem>
                  ),
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
      {type !== 'appointments' && (
        <>
          <Box style={{ paddingBottom: '10px' }}>
            <Accordion
              key="Other Filters"
              expanded={expanded}
              onClick={handleExpansion}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Other Filters
              </AccordionSummary>
              <AccordionDetails>
                <List
                  component="div"
                  disablePadding
                  className={classes.subPanel}
                  style={{ width: '100%' }}
                >
                  {isFacetInResults('ServiceRO', 'has_video', 'True') && (
                    <ListItem dense disabled={loading}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected(
                              'ServiceRO',
                              'has_video',
                              'True',
                            )}
                            onClick={() =>
                              toggleFacet('ServiceRO', 'has_video', 'True', {
                                label: 'Has Video',
                              })
                            }
                          />
                        }
                        label={`Has a Video (${
                          getFacetResults('ServiceRO', 'has_video').find(
                            x => x.value === 'True',
                          ).count
                        })`}
                      />
                    </ListItem>
                  )}
                  {isFacetInResults('ServiceRO', 'has_photo', 'True') && (
                    <ListItem dense disabled={loading}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected(
                              'ServiceRO',
                              'has_photo',
                              'True',
                            )}
                            onClick={() =>
                              toggleFacet('ServiceRO', 'has_photo', 'True', {
                                label: 'Has Photo',
                              })
                            }
                          />
                        }
                        label={`Has a Photo (${
                          getFacetResults('ServiceRO', 'has_photo').find(
                            x => x.value === 'True',
                          ).count
                        })`}
                      />
                    </ListItem>
                  )}
                  {isFacetInResults('ServiceRO', 'is_open', 'False') && (
                    <ListItem dense disabled={loading}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              isSelected('ServiceRO', 'is_open', 'False') &&
                              !isSelected('ServiceRO', 'is_open', 'True')
                            }
                            onClick={() => {
                              if (
                                isSelected('ServiceRO', 'is_open', 'False') &&
                                !isSelected('ServiceRO', 'is_open', 'True')
                              ) {
                                deleteFacet('ServiceRO', 'is_open', 'False');
                                setFacet('ServiceRO', 'is_open', 'True', {
                                  label: 'Open ROs',
                                });
                              } else {
                                deleteFacet('ServiceRO', 'is_open', 'True');
                                deleteFacet('ServiceRO', 'is_open', 'False');
                                setFacet('ServiceRO', 'is_open', 'False', {
                                  label: 'Closed ROs',
                                });
                              }
                            }}
                          />
                        }
                        label={`Closed ROs (${
                          getFacetResults('ServiceRO', 'is_open').find(
                            x => x.value === 'False',
                          ).count
                        })`}
                      />
                    </ListItem>
                  )}
                  {
                    <ListItem dense disabled={loading}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              !isSelected('ServiceRO', 'is_open', 'False') &&
                              !isSelected('ServiceRO', 'is_open', 'True')
                            }
                            onClick={() => {
                              if (
                                !isSelected('ServiceRO', 'is_open', 'False') &&
                                !isSelected('ServiceRO', 'is_open', 'True')
                              ) {
                                deleteFacet('ServiceRO', 'is_open', 'False');
                                setFacet('ServiceRO', 'is_open', 'True', {
                                  label: 'Open ROs',
                                });
                              } else {
                                deleteFacet('ServiceRO', 'is_open', 'True');
                                deleteFacet('ServiceRO', 'is_open', 'False');
                              }
                            }}
                          />
                        }
                        label={`All ROs (${
                          getFacetResults('ServiceRO', 'is_open').find(
                            x => x.value === 'False',
                          )?.count ??
                          0 +
                            getFacetResults('ServiceRO', 'is_open').find(
                              x => x.value === 'True',
                            )?.count ??
                          0
                        })`}
                      />
                    </ListItem>
                  }
                </List>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box style={{ paddingBottom: '10px', maxWidth: '100%' }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Opened Date
              </AccordionSummary>
              <AccordionDetails>
                <Box flexDirection="column" alignItems="stretch">
                  <Box>From</Box>
                  <Box>
                    <DatePicker
                      autoOk
                      clearable
                      emptyLabel=""
                      format="YYYY-MM-DD"
                      onChange={date => setOpenedAtFromFilter(date)}
                      value={openedAtFromFilter}
                      variant="dialog"
                    />
                  </Box>
                  <Box>To</Box>
                  <Box>
                    <DatePicker
                      autoOk
                      clearable
                      emptyLabel=""
                      format="YYYY-MM-DD"
                      onChange={date => setOpenedAtToFilter(date)}
                      value={openedAtToFilter}
                      variant="dialog"
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box style={{ paddingBottom: '10px', maxWidth: '100%' }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                RO Types
              </AccordionSummary>
              <AccordionDetails>
                <List
                  component="div"
                  disablePadding
                  className={classes.subPanel}
                  style={{ width: '100%' }}
                >
                  {isFacetInResults('ServiceRO', 'is_open', 'True') && (
                    <ListItem dense disabled={loading}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected('ServiceRO', 'is_open', 'True')}
                            onClick={() =>
                              swapFacet('ServiceRO', 'is_open', 'True', {
                                label: 'Open ROs',
                              })
                            }
                          />
                        }
                        label={`Open ROs (${
                          getFacetResults('ServiceRO', 'is_open').find(
                            x => x.value === 'True',
                          )?.count ?? 0
                        })`}
                      />
                    </ListItem>
                  )}
                  {isFacetInResults('ServiceRO', 'is_open', 'False') && (
                    <ListItem dense disabled={loading}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected(
                              'ServiceRO',
                              'is_open',
                              'False',
                            )}
                            onClick={() =>
                              swapFacet('ServiceRO', 'is_open', 'False', {
                                label: 'Closed ROs',
                              })
                            }
                          />
                        }
                        label={`Closed ROs (${
                          getFacetResults('ServiceRO', 'is_open').find(
                            x => x.value === 'False',
                          )?.count ?? 0
                        })`}
                      />
                    </ListItem>
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      )}
    </List>
  );
};

export default FilterDrawer;
